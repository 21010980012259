import React from "react";

export default function ViewUploadedImageBox({
  uploadItem,

  deleteDocumentCallback,
  index,
  disabled,
  loading,
}) {
  var imageUrl = "";
  if (uploadItem.name) {
    imageUrl = URL.createObjectURL(uploadItem);
  } else {
    imageUrl = uploadItem;
  }
  console.log(imageUrl);

  return (
    <div className="relative flex-shrink-0">
      <img
        className="object-cover w-36 h-24 rounded-lg"
        src={imageUrl}
        alt={"Preview " + index}
      />
      {!disabled && (
        <button
          onClick={(e) => {
            e.preventDefault();
            deleteDocumentCallback(index);
          }}
          disabled={loading}
          className={`${
            loading ? "bg-gray-400 animate-spin" : "hover:bg-red-600 bg-red-500"
          } absolute right-1 top-1 p-1 w-min  rounded-full transition-colors focus:outline-none focus:ring-1`}
        >
          <svg
            className="fill-current text-white h-4"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
          </svg>
        </button>
      )}
    </div>
  );
}
