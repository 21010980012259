import React from "react";

export default function CheckButton({ loading, btnFunction, type }) {
  return (
    <button
      type={type && type === "button" ? "button" : "submit"}
      disabled={loading}
      onClick={(e) => {
        console.log(222);
        if (type && type === "button") {
          e.preventDefault();
          btnFunction(e);
        }
      }}
      className="p-3 w-min hover:bg-gray-300 rounded-full bg-white transition-colors focus:outline-none focus:ring-1 shadow-lg"
    >
      {loading ? (
        <svg
          className="h-8 sm:h-6 animate-spin"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
            stroke="black"
            strokeWidth="2"
          />
        </svg>
      ) : (
        <svg
          viewBox="0 0 30 30"
          className="fill-current text-blue-600 md:text-black h-8 sm:h-6"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.9937 19.8438L5.78124 14.6313L4.00624 16.3938L10.9937 23.3813L25.9937 8.38127L24.2312 6.61877L10.9937 19.8438Z" />
        </svg>
      )}
    </button>
  );
}
