import React from "react";
import { useDropzone } from "react-dropzone";

export default function MultipleDocumentsUploadBox({
  setDocumentsCallback,
  filesToAccept,
}) {
  const onDrop = async (files) => {
    console.log(files);
    if (files && files.length > 0) {
      setDocumentsCallback(files);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: filesToAccept,
    maxSize: 10485760, //10 MB
    multiple: true,
  });

  return (
    <div
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
      className="overflow-hidden w-full mt-2 focus:outline-none focus:ring rounded-lg"
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p
          className={`text-white bg-blue-600 hover:bg-blue-500 font-semibold w-full text-center cursor-pointer p-3 rounded-lg animate-pulse focus:outline-none focus:ring-1`}
        >
          +
        </p>
      ) : (
        <p
          className={`text-white bg-blue-600 hover:bg-blue-500 font-semibold w-full text-center cursor-pointer p-3 rounded-lg focus:outline-none focus:ring-1`}
        >
          Drop or click to add
        </p>
      )}
    </div>
  );
}
