import React from "react";
import FeaturePreviewBox from "./FeaturePreviewBox";

export default function PartnerWithUsBox({
  props,
  invertTitleColours,
  isAPartner,
}) {
  return (
    <div
      className={`${
        invertTitleColours
          ? "bg-white "
          : "bg-gray-100 md:bg-gray-200 bg-opacity-20"
      } rounded-xl w-full shadow-xl`}
    >
      <div
        className={`p-2 md:pb-0 ${
          invertTitleColours ? "text-black" : "text-white"
        }  md:text-black text-center`}
      >
        <p className="font-semibold md:mb-0 md:text-lg">
          {isAPartner ? "Partner with us again!" : "Partner with us!"}
        </p>

        <p className="font-light">
          Provide {isAPartner ? "another" : "your"} company’s service(s) on
          Softwair.
        </p>
      </div>

      <div
        className="flex items-center justify-center w-full flex-col overflow-y-auto relative space-y-4 md:space-y-2 bg-gray-100 md:bg-transparent rounded-xl"
        style={{ backdropFilter: "blur(40px)" }}
      >
        <img
          src="https://media4.giphy.com/media/3oxHQpJKupQXsmU1JS/giphy.gif?cid=ecf05e47db6hbrxxlwex93ht27d1bwqalwshmyr41f5umhjg&rid=giphy.gif"
          alt="Team up"
          className="rounded-xl object-cover h-32 w-full px-2 mt-2 "
        />
        <FeaturePreviewBox title="Long-term customer relationships" />
        <FeaturePreviewBox title="Milestone-based payments" />
        <FeaturePreviewBox title="Access to other partners" />
        <FeaturePreviewBox title="Better processes across the board" />
        <FeaturePreviewBox title="Awesome for the planet and people" />
        <div className="flex items-center justify-center md:justify-between bg-white px-4 py-2 w-full font-semibold text-3xl md:text-lg rounded-xl text-center">
          <p>CA$200/mo.</p>
          <button
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/registerPartner");
            }}
            className="border border-blue-600 px-4 py-1 hover:bg-blue-600 text-blue-600 hover:text-white rounded-full bg-white md:block hidden focus:outline-none focus:ring-1"
            type="button"
          >
            Join
          </button>
        </div>
      </div>
    </div>
  );
}
