import { useQuery } from "@apollo/client";
import moment from "moment";
import React from "react";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import { FETCH_QUOTES_BY_QUOTE_REQUEST_QUERY } from "./QuoteBox";
import QuoteQuickDisplayBox from "./QuoteQuickDisplayBox";
import TextAndValue from "./TextAndValue";

function QuoteRequestBox({ quoteRequest, props, invertTitleColours }) {
  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: quoteRequest.projectId },
    }
  );

  var quotationReceivedAtTime;
  var quotationReceivedAtDay;
  var quoteRequestExprDay;
  var quoteRequestExprTime;

  quotationReceivedAtTime = moment(quoteRequest.createdAt)
    .format("h:mma")
    .slice(0, -1);
  quotationReceivedAtDay = moment(quoteRequest.createdAt).format("D MMM, y");

  quoteRequestExprTime = moment(quoteRequest.expiredAt)
    .format("h:mma")
    .slice(0, -1);
  quoteRequestExprDay = moment(quoteRequest.expiredAt).format("D MMM, y");

  const { data: { getQuotesByQuoteRequest: fetchedQuotes } = {} } = useQuery(
    FETCH_QUOTES_BY_QUOTE_REQUEST_QUERY,
    {
      variables: { quoteRequestId: quoteRequest.id },
    }
  );
  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return fetchedProject ? (
    <div
      className={`${
        invertTitleColours
          ? "bg-white md:mb-0 md:bg-gray-200 md:bg-opacity-20"
          : "bg-gray-100 md:bg-gray-200 bg-opacity-20"
      } rounded-xl w-full shadow-xl`}
    >
      <div
        className={`p-2 ${
          invertTitleColours ? "text-black md:text-white" : "text-white"
        }  md:text-black text-center text-sm`}
      >
        <button
          className="font-semibold text-base text-blue-700 hover:text-blue-500 md:text-blue-200 md:hover:text-blue-300"
          onClick={(e) => {
            e.preventDefault();
            props.history.push(
              "/quoteRequestDetails/" +
                quoteRequest.id +
                "/" +
                quoteRequest.projectId
            );
          }}
        >
          {fetchedProject.title} v{fetchedProject.versionNumber}
        </button>
        <p className="font-medium">{quoteRequest.type} request sent</p>
        {!fetchedQuotes ||
          (fetchedQuotes.length === 0 && (
            <p className="font-light">You'll receive quotes very soon!</p>
          ))}
      </div>

      <div
        className="rounded-t-xl bg-gray-100 md:bg-opacity-50 py-4 flex flex-col items-center justify-center space-y-2"
        style={{ backdropFilter: "blur(40px)" }}
      >
        <div className="w-full flex items-center justify-between space-x-4 px-4 overflow-hidden">
          <div className="flex flex-col items-start justify-start text-sm">
            <p className="font-medium text-blue-500 md:text-blue-100">
              Received at
            </p>
            <p className="text-black md:text-white text-3xl">
              {quotationReceivedAtTime}
            </p>
            <p className="text-blue-600 md:text-gray-200 font-medium">
              {quotationReceivedAtDay}
            </p>
          </div>

          <svg
            width="10"
            height="18"
            viewBox="0 0 10 18"
            className="text-black md:text-white fill-current flex-shrink-0"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.94006 0.5L0.0600586 2.4975L6.16672 9L0.0600586 15.5025L1.94006 17.5L9.94006 9L1.94006 0.5Z" />
          </svg>

          <div className="flex flex-col items-end justify-end text-sm">
            <p className="font-medium text-blue-500 md:text-blue-100">
              Expires at
            </p>
            <p className="text-black md:text-white text-3xl">
              {quoteRequestExprTime}
            </p>
            <p className="text-blue-600 md:text-gray-200 font-medium">
              {quoteRequestExprDay}
            </p>
          </div>
        </div>

        {mainImageUrl && (
          <button
            className="mx-4 rounded-xl focus:outline-none focus:ring"
            onClick={(e) => {
              e.preventDefault();
              props.history.push(
                "/quoteRequestDetails/" +
                  quoteRequest.id +
                  "/" +
                  quoteRequest.projectId
              );
            }}
          >
            <img
              src={mainImageUrl}
              alt={fetchedProject.title}
              className="w-full h-full max-h-24 rounded-xl shadow-lg object-cover hover:opacity-90"
            />
          </button>
        )}
      </div>

      <div
        className={`${
          fetchedQuotes && fetchedQuotes.length > 0 ? "" : "rounded-b-xl"
        } bg-white p-2 overflow-hidden grid grid-flow-col`}
      >
        <TextAndValue text="Quotes" value={quoteRequest.numOfQuotesReceived} />

        <TextAndValue text="Views" value={quoteRequest.numOfViews} />

        <TextAndValue text="Rejections" value={quoteRequest.numOfRejections} />
      </div>

      <div className="flex items-center justify-center w-full flex-col overflow-hidden relative bg-white h-full rounded-b-xl">
        {fetchedQuotes &&
          fetchedQuotes.length > 0 &&
          fetchedQuotes.map((quote, index) => (
            <QuoteQuickDisplayBox quote={quote} key={index} props={props} />
          ))}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default QuoteRequestBox;
