import React from "react";

export default function FeaturePreviewBox({ title, hideIcon }) {
  return (
    <div className="w-full px-4 md:px-6">
      <div className="flex items-center justify-between text-left space-x-2">
        <p className="text-sm leading-snug">{title}</p>
        {!hideIcon && (
          <svg
            width="22"
            height="18"
            viewBox="0 0 22 18"
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.99385 13.8437L1.78135 8.63115L0.00634766 10.3937L6.99385 17.3812L21.9938 2.38115L20.2313 0.618652L6.99385 13.8437Z" />
          </svg>
        )}
      </div>
    </div>
  );
}
