import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
// import { FETCH_CHATS_BY_CUSTOMER_QUERY } from "../../pages/customer/Messages";
// import ModalMessageTitleButton from "./ModalMessageTitleButton";
import OrderMilestoneMessageBox from "./OrderMilestoneMessageBox";
import OrderMilestoneMessageTitleButton from "./OrderMilestoneMessageTitleButton";
import QuoteMessageBox from "./QuoteMessageBox";
import QuoteMessageTitleButton from "./QuoteMessageTitleButton";

function ChatSummaryCard({ chat, props, isLast, isFirst, staffCustomerId }) {
  const {
    data: { getRecentModalMessagesByChat: recentModalMessagesByChat } = {},
    // loading,
  } = useQuery(FETCH_RECENT_MODAL_MESSAGES_BY_CHAT_QUERY, {
    variables: { chatId: chat.id },
  });

  const [activeModelMessageId, setActiveModelMessageId] = useState(null);

  const toggleModelMessageToOpen = (e, messageId) => {
    e.preventDefault();

    if (messageId === activeModelMessageId) {
      setActiveModelMessageId(null);
    } else {
      setActiveModelMessageId(messageId);
    }
  };

  return (
    <div
      className={`${
        chat.customerIds.includes("VYP6T425B1")
          ? "bg-blue-800 hover:bg-blue-700"
          : "bg-white hover:bg-gray-200"
      } flex-shrink-0 shadow-xl rounded-xl overflow-hidden flex flex-col items-center justify-center w-full ${
        isFirst ? "" : "mt-8 md:mt-0"
      }`}
    >
      <button
        className={`${
          chat.customerIds.includes("VYP6T425B1")
            ? "bg-blue-800 text-white hover:bg-blue-700"
            : "bg-white hover:bg-gray-200"
        } p-3 flex flex-col items-start justify-start w-full h-full space-y-1`}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          props.history.push("/chat/" + chat.id);
        }}
      >
        <p className="font-semibold text-2xl truncate w-full px-2 text-left">
          {chat.title}
        </p>
      </button>
      <div className="bg-gray-100 w-full h-full flex items-center justify-center flex-col  rounded-xl">
        {recentModalMessagesByChat && recentModalMessagesByChat.length > 0 && (
          <div className="flex items-center justify-start space-x-2 overflow-x-auto p-2 w-full">
            {recentModalMessagesByChat.map((modalMessage, index) =>
              modalMessage.quoteId ? (
                <QuoteMessageTitleButton
                  message={modalMessage}
                  key={index}
                  activeModelMessageId={activeModelMessageId}
                  toggleModelMessageToOpen={toggleModelMessageToOpen}
                  quoteId={modalMessage.quoteId}
                />
              ) : (
                <OrderMilestoneMessageTitleButton
                  message={modalMessage}
                  key={index}
                  activeModelMessageId={activeModelMessageId}
                  toggleModelMessageToOpen={toggleModelMessageToOpen}
                  orderMilestoneId={modalMessage.orderMilestoneId}
                />
              )
            )}
          </div>
        )}

        {activeModelMessageId &&
          recentModalMessagesByChat
            .filter((modalMessage) => {
              return modalMessage.id === activeModelMessageId;
            })
            .map((modalMessage, index) =>
              modalMessage.quoteId ? (
                <QuoteMessageBox
                  quoteId={modalMessage.quoteId}
                  key={index}
                  message={modalMessage}
                  staffCustomerId={staffCustomerId}
                  props={props}
                />
              ) : (
                <OrderMilestoneMessageBox
                  orderMilestoneId={modalMessage.orderMilestoneId}
                  key={index}
                  message={modalMessage}
                  staffCustomerId={staffCustomerId}
                  props={props}
                />
              )
            )}
      </div>
    </div>
  );
}

const FETCH_RECENT_MODAL_MESSAGES_BY_CHAT_QUERY = gql`
  query getRecentModalMessagesByChat($chatId: String!) {
    getRecentModalMessagesByChat(chatId: $chatId) {
      id
      quoteId
      orderMilestoneId
      status
    }
  }
`;

export default ChatSummaryCard;
