import { gql, useQuery } from "@apollo/client";
import React, { useCallback, useEffect } from "react";
import CheckboxInputField from "./CheckboxInputField";
import DateInputField from "./DateInputField";
import DocumentArrayInputField from "./DocumentArrayInputField";
// import InputField from "./InputField";
import NumberInputField from "./NumberInputField";
import RadioInputField from "./RadioInputField";
// import RadioInputsWithTitle from "./RadioInputsWithTitle";
import StringInputField from "./StringInputField";

function InputFieldsForSelectedTopLevelField({
  errors,
  values,
  setValues,
  topLvlInputFieldId,
  disabled,
}) {
  const {
    data: { getInputFieldsByParentInputFieldId: fetchedInputFields } = {},
    // error,
    // loading: loadingFetchedInputFields,
  } = useQuery(FETCH_INPUT_FIELDS_BY_TOP_LVL_ID_QUERY, {
    variables: { parentInputFieldId: topLvlInputFieldId },
  });

  const valuesContainsThisInputField = useCallback(
    (fetchedInputField) => {
      if (fetchedInputField.actualType === "int") {
        if (values.inputIdsToInts && values.inputIdsToInts.length > 0) {
          for (let i = 0; i < values.inputIdsToInts.length; ++i) {
            if (
              values.inputIdsToInts[i].inputFieldId === fetchedInputField.id
            ) {
              return true;
            }
          }

          return false;
        } else {
          return false;
        }
      } else if (fetchedInputField.actualType === "float") {
        if (values.inputIdsToFloats && values.inputIdsToFloats.length > 0) {
          for (let i = 0; i < values.inputIdsToFloats.length; ++i) {
            if (
              values.inputIdsToFloats[i].inputFieldId === fetchedInputField.id
            ) {
              return true;
            }
          }
          console.log("doesn't contain float after array");

          return false;
        } else {
          console.log("doesn't contain float");

          return false;
        }
      } else if (fetchedInputField.actualType === "string") {
        if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
          for (let i = 0; i < values.inputIdsToStrings.length; ++i) {
            if (
              values.inputIdsToStrings[i].inputFieldId === fetchedInputField.id
            ) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      }
    },
    [values]
  );

  const setDefaultStringInValues = useCallback(
    (fetchedInputField) => {
      if (fetchedInputField.inputBoxType === "radio") {
        if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
          for (let i = 0; i < values.inputIdsToStrings.length; ++i) {
            if (
              values.inputIdsToStrings[i].inputFieldId === fetchedInputField.id
            ) {
              var copiedValues = { ...values };

              copiedValues.inputIdsToStrings[i].stringVal =
                fetchedInputField.stringPlaceHolder;
              setValues(copiedValues);
              return;
            }
          }

          const copiedValues2 = {
            ...values,
            inputIdsToStrings: [
              ...values.inputIdsToStrings,
              {
                inputFieldId: fetchedInputField.id,
                stringVal: fetchedInputField.stringPlaceHolder,
              },
            ],
          };

          setValues(copiedValues2);
          return;
        } else {
          console.log("yoyo");
          const copiedValues = {
            ...values,
            inputIdsToStrings: [
              {
                inputFieldId: fetchedInputField.id,
                stringVal: fetchedInputField.stringPlaceHolder,
              },
            ],
          };
          console.log(copiedValues.inputIdsToStrings);

          setValues(copiedValues);
        }
      }
    },
    [setValues, values]
  );

  // const setDefaultStringArrayInValues = useCallback(
  //   (fetchedInputField) => {
  //     if (

  //       fetchedInputField.inputBoxType === "checkbox"
  //     ) {
  //       if (values.inputIdsToStringArrays && values.inputIdsToStringArrays.length > 0) {
  //         for (let i = 0; i < values.inputIdsToStringArrays.length; ++i) {
  //           if (
  //             values.inputIdsToStringArrays[i].inputFieldId === fetchedInputField.id
  //           ) {
  //             var copiedValues = { ...values };

  //             copiedValues.inputIdsToStringArrays[i].stringArrayVal =
  //               fetchedInputField.stringPlaceHolder;
  //             setValues(copiedValues);
  //             return;
  //           }
  //         }

  //         const copiedValues2 = {
  //           ...values,
  //           inputIdsToStringArrays: [
  //             ...values.inputIdsToStringArrays,
  //             {
  //               inputFieldId: fetchedInputField.id,
  //               stringVal: fetchedInputField.stringPlaceHolder,
  //             },
  //           ],
  //         };

  //         setValues(copiedValues2);
  //         return;
  //       } else {
  //         console.log("yoyo");
  //         const copiedValues = {
  //           ...values,
  //           inputIdsToStringArrays: [
  //             {
  //               inputFieldId: fetchedInputField.id,
  //               stringVal: fetchedInputField.stringPlaceHolder,
  //             },
  //           ],
  //         };
  //         console.log(copiedValues.inputIdsToStringArrays);

  //         setValues(copiedValues);
  //       }
  //     }
  //   },
  //   [setValues, values]
  // );

  const setDefaultIntInValues = useCallback(
    (fetchedInputField) => {
      console.log("setting default int val");

      if (
        fetchedInputField.inputBoxType === "radio" ||
        fetchedInputField.inputBoxType === "checkbox" ||
        fetchedInputField.inputBoxType === "range"
      ) {
        if (values.inputIdsToInts && values.inputIdsToInts.length > 0) {
          for (let i = 0; i < values.inputIdsToInts.length; ++i) {
            if (
              values.inputIdsToInts[i].inputFieldId === fetchedInputField.id
            ) {
              console.log(values.inputIdsToInts[i].inputFieldId);
              console.log(values.inputIdsToInts[i]);
              var copiedValues = { ...values };

              copiedValues.inputIdsToInts[i].intVal =
                fetchedInputField.intPlaceHolder;

              console.log(copiedValues.inputIdsToInts[i]);
              setValues(copiedValues);
              return;
            }
          }
          console.log(393920);
          const copiedValues2 = {
            ...values,
            inputIdsToInts: [
              ...values.inputIdsToInts,
              {
                inputFieldId: fetchedInputField.id,
                intVal: fetchedInputField.intPlaceHolder,
              },
            ],
          };

          setValues(copiedValues2);
          return;
        } else {
          console.log("set the val");
          const copiedValues = {
            ...values,
            inputIdsToInts: [
              {
                inputFieldId: fetchedInputField.id,
                intVal: fetchedInputField.intPlaceHolder,
              },
            ],
          };

          setValues(copiedValues);
        }
      }
    },
    [setValues, values]
  );
  const setDefaultFloatInValues = useCallback(
    (fetchedInputField) => {
      if (
        fetchedInputField.inputBoxType === "radio" ||
        fetchedInputField.inputBoxType === "checkbox" ||
        fetchedInputField.inputBoxType === "range"
      ) {
        if (values.inputIdsToFloats && values.inputIdsToFloats.length > 0) {
          for (let i = 0; i < values.inputIdsToFloats.length; ++i) {
            if (
              values.inputIdsToFloats[i].inputFieldId === fetchedInputField.id
            ) {
              var copiedValues = { ...values };

              copiedValues.inputIdsToFloats[i].floatVal =
                fetchedInputField.intPlaceHolder;
              setValues(copiedValues);
              return;
            }
          }

          const copiedValues2 = {
            ...values,
            inputIdsToFloats: [
              ...values.inputIdsToFloats,
              {
                inputFieldId: fetchedInputField.id,
                floatVal: fetchedInputField.floatPlaceHolder,
              },
            ],
          };

          setValues(copiedValues2);
          return;
        } else {
          const copiedValues = {
            ...values,
            inputIdsToFloats: [
              {
                inputFieldId: fetchedInputField.id,
                floatVal: fetchedInputField.floatPlaceHolder,
              },
            ],
          };

          setValues(copiedValues);
        }
      }
    },
    [setValues, values]
  );

  const prereqsAreMet = (
    childInputField,
    inputIdsToStrings,
    inputIdsToInts,
    inputIdsToFloats
  ) => {
    if (
      childInputField.prereqInputFieldIdsToRequiredStringVal &&
      childInputField.prereqInputFieldIdsToRequiredStringVal.length > 0
    ) {
      for (
        let i = 0;
        i < childInputField.prereqInputFieldIdsToRequiredStringVal.length;
        ++i
      ) {
        const prereqInputFieldId =
          childInputField.prereqInputFieldIdsToRequiredStringVal[i]
            .inputFieldId;
        const prereqStringVal =
          childInputField.prereqInputFieldIdsToRequiredStringVal[i].stringVal;

        if (inputIdsToStrings && inputIdsToStrings.length > 0) {
          for (let j = 0; j < inputIdsToStrings.length; ++j) {
            if (inputIdsToStrings[j].inputFieldId === prereqInputFieldId) {
              if (inputIdsToStrings[j].stringVal === prereqStringVal) {
                return true;
              } else {
                return false;
              }
            }
          }
          return false;
        } else {
          return false;
        }
      }
    }

    if (
      childInputField.prereqInputFieldIdsToRequiredIntVal &&
      childInputField.prereqInputFieldIdsToRequiredIntVal.length > 0
    ) {
      for (
        let i = 0;
        i < childInputField.prereqInputFieldIdsToRequiredIntVal.length;
        ++i
      ) {
        const prereqInputFieldId =
          childInputField.prereqInputFieldIdsToRequiredIntVal[i].inputFieldId;
        const prereqIntVal =
          childInputField.prereqInputFieldIdsToRequiredIntVal[i].intVal;

        if (inputIdsToInts && inputIdsToInts.length > 0) {
          for (let j = 0; j < inputIdsToInts.length; ++j) {
            if (inputIdsToInts[j].inputFieldId === prereqInputFieldId) {
              if (inputIdsToInts[j].intVal === prereqIntVal) {
                return true;
              } else {
                return false;
              }
            }
          }
          return false;
        } else {
          return false;
        }
      }
    }

    if (
      childInputField.prereqInputFieldIdsToRequiredFloatVal &&
      childInputField.prereqInputFieldIdsToRequiredFloatVal.length > 0
    ) {
      for (
        let i = 0;
        i < childInputField.prereqInputFieldIdsToRequiredFloatVal.length;
        ++i
      ) {
        const prereqInputFieldId =
          childInputField.prereqInputFieldIdsToRequiredFloatVal[i].inputFieldId;
        const prereqFloatVal =
          childInputField.prereqInputFieldIdsToRequiredFloatVal[i].floatVal;

        if (inputIdsToFloats && inputIdsToFloats.length > 0) {
          for (let j = 0; j < inputIdsToFloats.length; ++j) {
            if (inputIdsToFloats[j].inputFieldId === prereqInputFieldId) {
              if (inputIdsToFloats[j].floatVal === prereqFloatVal) {
                return true;
              } else {
                return false;
              }
            }
          }
          return false;
        } else {
          return false;
        }
      }
    }

    return true;
  };

  useEffect(() => {
    if (fetchedInputFields && fetchedInputFields.length > 0) {
      for (let i = 0; i < fetchedInputFields.length; ++i) {
        if (
          (fetchedInputFields[i].inputBoxType === "radio" ||
            fetchedInputFields[i].inputBoxType === "checkbox" ||
            fetchedInputFields[i].inputBoxType === "range") &&
          !valuesContainsThisInputField(fetchedInputFields[i]) &&
          prereqsAreMet(
            fetchedInputFields[i],
            values.inputIdsToStrings,
            values.inputIdsToInts,
            values.inputIdsToFloats
          )
        ) {
          if (fetchedInputFields[i].actualType === "int") {
            setDefaultIntInValues(fetchedInputFields[i]);
          } else if (fetchedInputFields[i].actualType === "float") {
            setDefaultFloatInValues(fetchedInputFields[i]);
          } else if (fetchedInputFields[i].actualType === "string") {
            setDefaultStringInValues(fetchedInputFields[i]);
          } else if (fetchedInputFields[i].actualType === "string-array") {
            // setDefaultStringArrayInValues(fetchedInputFields[i]);
          }
          break;
        } else {
        }
      }
    }
  }, [
    values,

    valuesContainsThisInputField,
    fetchedInputFields,
    setDefaultIntInValues,
    setDefaultFloatInValues,
    setDefaultStringInValues,
  ]);

  return fetchedInputFields && fetchedInputFields.length > 0 ? (
    fetchedInputFields.map((fetchedInputField, index) =>
      fetchedInputField.inputBoxType === "checkbox" ? (
        <CheckboxInputField
          key={index}
          values={values}
          errors={errors}
          setValues={setValues}
          topLvlInputFieldId={topLvlInputFieldId}
          fetchedInputField={fetchedInputField}
          disabled={disabled}
        />
      ) : fetchedInputField.inputBoxType === "radio" ? (
        <RadioInputField
          key={index}
          values={values}
          errors={errors}
          setValues={setValues}
          topLvlInputFieldId={topLvlInputFieldId}
          fetchedInputField={fetchedInputField}
          disabled={disabled}
        />
      ) : fetchedInputField.actualType === "string" ? (
        <StringInputField
          key={index}
          values={values}
          errors={errors}
          setValues={setValues}
          topLvlInputFieldId={topLvlInputFieldId}
          fetchedInputField={fetchedInputField}
          disabled={disabled}
        />
      ) : fetchedInputField.actualType === "int" ||
        fetchedInputField.actualType === "float" ? (
        <NumberInputField
          key={index}
          values={values}
          errors={errors}
          setValues={setValues}
          topLvlInputFieldId={topLvlInputFieldId}
          fetchedInputField={fetchedInputField}
          disabled={disabled}
        />
      ) : fetchedInputField.actualType === "date" ? (
        <DateInputField
          key={index}
          values={values}
          errors={errors}
          setValues={setValues}
          topLvlInputFieldId={topLvlInputFieldId}
          fetchedInputField={fetchedInputField}
          disabled={disabled}
        />
      ) : fetchedInputField.actualType === "document-array" ? (
        <DocumentArrayInputField
          key={index}
          values={values}
          errors={errors}
          setValues={setValues}
          topLvlInputFieldId={topLvlInputFieldId}
          fetchedInputField={fetchedInputField}
          disabled={disabled}
        />
      ) : (
        <></>
      )
    )
  ) : (
    <></>
  );
}

const FETCH_INPUT_FIELDS_BY_TOP_LVL_ID_QUERY = gql`
  query getInputFieldsByParentInputFieldId($parentInputFieldId: String!) {
    getInputFieldsByParentInputFieldId(
      parentInputFieldId: $parentInputFieldId
    ) {
      id
      title
      description
      stringPlaceHolder
      intPlaceHolder
      floatPlaceHolder
      tags
      parentInputFieldId
      inputBoxType
      actualType
      rows
      maxLength
      isMoney
      rangeMin
      rangeMax
      rangeStep
      acceptedFileFormats
      stringOptions
      intOptions
      floatOptions
      prereqInputFieldIdsToRequiredStringVal {
        inputFieldId
        stringVal
      }
      prereqInputFieldIdsToRequiredIntVal {
        inputFieldId
        intVal
      }
      prereqInputFieldIdsToRequiredFloatVal {
        inputFieldId
        floatVal
      }
      inputFieldIdsToDisabledStrings {
        inputFieldId
        disabledStrings
      }
      inputFieldIdsToDisabledInts {
        inputFieldId
        disabledInts
      }
      inputFieldIdsToDisabledFloats {
        inputFieldId
        disabledFloats
      }
      inputFieldIdsToDefaultStringVal {
        inputFieldId
        defaultString
      }
      inputFieldIdsToDefaultStringArrayVal {
        inputFieldId
        defaultStringArray
      }
      inputFieldIdsToDefaultIntVal {
        inputFieldId
        defaultInt
      }
      inputFieldIdsToDefaultIntArrayVal {
        inputFieldId
        defaultIntArray
      }
      inputFieldIdsToDefaultFloatVal {
        inputFieldId
        defaultFloat
      }
      inputFieldIdsToDefaultFloatArrayVal {
        inputFieldId
        defaultFloat
      }
      timesUsed
      active
      createdAt
    }
  }
`;

export default InputFieldsForSelectedTopLevelField;
