import { gql, useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "../../util/hooks";

function DeleteProjectMedia({ url, project, isAbsolute }) {
  // const [errors, setErrors] = useState({});
  // if (errors) {
  //   console.log(errors);
  // }
  const { values } = useForm(deleteProjectMediaCallback, {
    projectId: project.id,
    url: url,
  });
  const [deleteProjectMedia, { loading }] = useMutation(DELETE_PROJECT_MEDIA, {
    refetchQueries: [
      {
        query: FETCH_PROJECTS_BY_CUSTOMER_QUERY,
        variables: { searchTerm: "" },
      },

      {
        query: FETCH_PROJECT_BY_ID_QUERY,
        variables: { projectId: project.id },
      },
    ],

    update(_, { data: { deleteProjectMedia: projectData } }) {},
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      // setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function deleteProjectMediaCallback() {
    deleteProjectMedia();
  }

  return (
    <button
      type="button"
      onClick={deleteProjectMediaCallback}
      className={`${
        isAbsolute ? "absolute right-1 top-1 " : "ml-20"
      } bg-red-500 p-1 rounded-full w-min transition-colors focus:outline-none focus:ring-1`}
      disabled={loading}
    >
      <svg
        className="fill-current text-white h-4"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
      </svg>
    </button>
  );
}

const DELETE_PROJECT_MEDIA = gql`
  mutation deleteProjectMedia($projectId: String!, $url: String!) {
    deleteProjectMedia(projectId: $projectId, url: $url)
  }
`;
export const FETCH_PROJECTS_BY_CUSTOMER_QUERY = gql`
  query getProjectsByCustomer($customerId: String, $searchTerm: String) {
    getProjectsByCustomer(customerId: $customerId, searchTerm: $searchTerm) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export const FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY = gql`
  query getProjectSubPartsByCustomerId(
    $customerId: String
    $searchTerm: String
    $parentProjectId: String!
  ) {
    getProjectSubPartsByCustomerId(
      customerId: $customerId
      searchTerm: $searchTerm
      parentProjectId: $parentProjectId
    ) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export const FETCH_PROJECT_BY_ID_QUERY = gql`
  query getProjectById($projectId: String!) {
    getProjectById(projectId: $projectId) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;
export default DeleteProjectMedia;
