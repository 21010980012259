/**
 * Use the CSS tab above to style your Element's container.
 */
import { CardElement } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";
import { useWindowSize } from "../../util/hooks";
// import "./NewCardInputSectionStyles.css";

var CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#93C5FD",
      color: "#60A5FA",
      fontWeight: 500,
      fontFamily: "inherit",
      fontSize: "1.5rem",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#9CA3AF" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

function NewCardInputSection() {
  const size = useWindowSize();

  useEffect(() => {
    console.log("hihih");
    if (size.width >= 768) {
      console.log("oooooo");
      CARD_ELEMENT_OPTIONS.style.base.color = "#fff";
    } else {
      console.log("yoioo");
      CARD_ELEMENT_OPTIONS.style.base.color = "#000";
    }
  }, [size]);

  return (
    <div className="focus:outline-none cursor-text w-full h-full">
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
}

export default NewCardInputSection;
