import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import { FETCH_PROJECT_BY_ID_QUERY } from "../../components/customer/DeleteProjectMedia";
import { FETCH_ORDER_MILESTONE_BY_ID_QUERY } from "../../components/customer/EditMilestoneImages";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import InputFieldsForSelectedTopLevelField from "../../components/customer/InputFieldsForSelectedTopLevelField";
import InputTitle from "../../components/customer/InputTitle";
import NextButton from "../../components/customer/NextButton";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "../../components/customer/OrderMilestoneMessageBox";
import PageHeader from "../../components/customer/PageHeader";
import { FETCH_QUOTE_BY_ID_QUERY } from "../../components/customer/QuoteMessageBox";
import { FETCH_PARTNER_SERVICE_BY_ID_QUERY } from "../../components/customer/QuoteQuickDisplayBox";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { getMoneyVersion, useForm } from "../../util/hooks";
import { FETCH_CUSTOMER_QUERY } from "./Settings";
import { FETCH_QUOTES_BY_PROJECT_QUERY } from "./TrackProject";

function MilestoneDetails(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }
  const orderMilestoneId = props && props.match.params.orderMilestoneId;

  const {
    data: { getOrderMilestoneById: fetchedOrderMilestone } = {},
    loading,
  } = useQuery(FETCH_ORDER_MILESTONE_BY_ID_QUERY, {
    variables: { orderMilestoneId },
  });

  var projectId = "";
  var quoteId = "";
  var partnerServiceId = "";
  if (fetchedOrderMilestone) {
    projectId = fetchedOrderMilestone.projectId;
    quoteId = fetchedOrderMilestone.quoteId;
    partnerServiceId = fetchedOrderMilestone.doneByPartnerServiceId;
  }

  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: projectId },
    }
  );
  const {
    data: { getQuoteById: fetchedQuote } = {},
    loading: { loadingQuote },
  } = useQuery(FETCH_QUOTE_BY_ID_QUERY, {
    variables: { quoteId },
  });

  const { data: { getPartnerServiceById: fetchedPartnerService } = {} } =
    useQuery(FETCH_PARTNER_SERVICE_BY_ID_QUERY, {
      variables: { partnerServiceId },
    });

  // const [errors, setErrors] = useState({});

  const [
    acceptAndReleaseOrderMilestonePayment,
    { loading: loadingMilestonePayment },
  ] = useMutation(ACCEPT_ORDER_MILESTONE_REQUEST, {
    refetchQueries: [
      {
        query: FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY,
        variables: { quoteId },
      },
      {
        query: FETCH_QUOTES_BY_PROJECT_QUERY,
        variables: { projectId },
      },
    ],
    update(
      _,
      { data: { acceptAndReleaseOrderMilestonePayment: quoteRequestData } }
    ) {
      props.history.push("/trackProject/" + projectId + "/" + quoteId);
    },
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      // setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: { orderMilestoneId },
  });

  function acceptAndReleaseOrderMilestonePaymentCallback(e) {
    e.preventDefault();
    acceptAndReleaseOrderMilestonePayment();
  }

  // if (
  //   !loading &&
  //   !loadingCustomer &&
  //   !loadingMilestonePayment &&
  //   !loadingQuote &&
  //   !fetchedQuote
  // ) {
  //   props.history.push("/projects");
  // }
  const [errors] = useState({});

  const { values, onArrayChange, setValues } = useForm(emptyCallback, {
    editProjectId: projectId,
    // parentProjectId: project ? project : null,
    title: "",
    shortDescription: "",
    selectedTopLvlInputFieldIds: [],

    subParts: [],
  });
  function emptyCallback() {}

  const {
    data: { getInputFieldsByMilestoneId: milestoneInputFields } = {},
    // error,
    loading: loadingMilestoneInputFields,
  } = useQuery(FETCH_INPUT_FIELDS_BY_MILESTONE_ID_QUERY, {
    variables: {
      isTopLevel: true,
      orderMilestoneId,
    },
  });

  const [finishedSettingInitialVals, setFinishedSettingInitialVals] =
    useState(false);
  useEffect(() => {
    if (!finishedSettingInitialVals && fetchedOrderMilestone) {
      setValues({
        ...values,

        ...fetchedOrderMilestone,
      });
      setFinishedSettingInitialVals(true);
    }
  }, [
    fetchedOrderMilestone,
    setValues,
    values,
    finishedSettingInitialVals,
    setFinishedSettingInitialVals,
  ]);

  useEffect(() => {
    // if (fetchedOrderMilestone && fetchedOrderMilestone.status === 2) {
    //   document.title = "Review Milestone | Softwair";
    // } else {
    document.title = "Milestone Details | Softwair";
    // }
  }, []);

  if (fetchedProject && fetchedProject.customerId !== customer.id) {
    props.history.push("/projects");
  }
  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={false}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 py-6 md:mb-2 md:p-0 w-full">
          <PageHeader
            props={props}
            // titleBold={`${
            //   fetchedOrderMilestone ? fetchedOrderMilestone.title : "Review"
            // }`}
            titleBold={
              fetchedOrderMilestone && fetchedOrderMilestone.status === 2
                ? "Review task"
                : "Track task"
            }
            // caption={
            //   fetchedOrderMilestone &&
            //   (fetchedOrderMilestone.status === 0
            //     ? "Not started yet."
            //     : fetchedOrderMilestone.status === 1
            //     ? "Work has started on this milestone."
            //     : fetchedOrderMilestone.status === 2
            //     ? "Review and release the payment for this milestone (you won't be charged anything extra)."
            //     : fetchedOrderMilestone.status === 3
            //     ? "Payment complete"
            //     : fetchedOrderMilestone.status === 4
            //     ? "Refunded"
            //     : "Cancelled")
            // }
            linkMsg={
              fetchedProject &&
              `For ${fetchedProject.title} v${fetchedProject.versionNumber}`
            }
            link={"/trackProject/" + projectId + "/" + quoteId}
            hideHomeButton={true}
          />
        </div>
        {fetchedProject &&
          fetchedOrderMilestone &&
          fetchedQuote &&
          fetchedPartnerService && (
            <div className="flex flex-col space-y-4 items-start justify-start w-full overflow-y-auto px-6 md:p-0 flex-1 overflow-x-hidden">
              <InputField
                fieldTitle={`Title`}
                value={fetchedOrderMilestone.title}
                type="text"
                disabled={true}
              />
              <InputField
                fieldTitle={`Short description`}
                value={fetchedOrderMilestone.shortDescription}
                type="textarea"
                rows={"2"}
                disabled={true}
              />
              <InputField
                fieldTitle="Status"
                value={
                  fetchedOrderMilestone.status === 0
                    ? "Not started yet"
                    : fetchedOrderMilestone.status === 1
                    ? "Work started"
                    : fetchedOrderMilestone.status === 2
                    ? "Completed and waiting for payment release"
                    : fetchedOrderMilestone.status === 3
                    ? "Payment released"
                    : fetchedOrderMilestone.status === 4
                    ? "Refunded"
                    : "Cancelled"
                }
                type="text"
                disabled={true}
              />
              <InputField
                fieldTitle={`Done by`}
                value={fetchedPartnerService.businessName}
                type="text"
                disabled={true}
              />
              {/* <InputField
                fieldTitle={`Person in charge`}
                value={fetchedPartnerService.businessName}
                type="text"
                disabled={true}
              /> */}
              <div className="flex flex-col items-start justify-start w-full">
                <InputField
                  fieldTitle={`Cost (CA$)`}
                  value={getMoneyVersion(fetchedOrderMilestone.finalNetAmount)}
                  type={"text"}
                  disabled={true}
                />
              </div>
              {fetchedOrderMilestone.status === 3 ? (
                <InputField
                  fieldTitle="Completed on"
                  value={moment(fetchedOrderMilestone.completedAt).format(
                    "D MMM, YYYY"
                  )}
                  type="text"
                  disabled={true}
                />
              ) : (
                <InputField
                  fieldTitle="Deadline"
                  value={moment(fetchedOrderMilestone.deadline).format(
                    "D MMM, YYYY"
                  )}
                  type="text"
                  disabled={true}
                />
              )}

              {milestoneInputFields && milestoneInputFields.length > 0 && (
                <div className="flex flex-col items-start justify-start w-full space-y-2">
                  <InputTitle fieldTitle={"Details (optional)"} />

                  <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                    {milestoneInputFields &&
                      milestoneInputFields.length > 0 &&
                      milestoneInputFields.map(
                        (topLvlSuggestedDetail, index) => (
                          <InputField
                            name={
                              fetchedOrderMilestone.status === 3
                                ? "finalSelectedTopLvlInputFieldIds"
                                : "selectedTopLvlInputFieldIds"
                            }
                            onChangeFunction={onArrayChange}
                            checkboxValue={topLvlSuggestedDetail.id}
                            type="checkbox"
                            value={
                              fetchedOrderMilestone.status === 3
                                ? values.finalSelectedTopLvlInputFieldIds
                                : values.selectedTopLvlInputFieldIds
                            }
                            checkboxTitle={topLvlSuggestedDetail.title}
                            key={index}
                          />
                        )
                      )}
                  </div>
                </div>
              )}

              {fetchedOrderMilestone.status === 3
                ? values.finalSelectedTopLvlInputFieldIds &&
                  values.finalSelectedTopLvlInputFieldIds.length > 0 &&
                  values.finalSelectedTopLvlInputFieldIds.map(
                    (topLvlInputFieldId, index) => (
                      <InputFieldsForSelectedTopLevelField
                        key={index}
                        topLvlInputFieldId={topLvlInputFieldId}
                        errors={errors}
                        disabled={true}
                        values={values}
                        setValues={setValues}
                      />
                    )
                  )
                : values.selectedTopLvlInputFieldIds &&
                  values.selectedTopLvlInputFieldIds.length > 0 &&
                  values.selectedTopLvlInputFieldIds.map(
                    (topLvlInputFieldId, index) => (
                      <InputFieldsForSelectedTopLevelField
                        key={index}
                        topLvlInputFieldId={topLvlInputFieldId}
                        errors={errors}
                        disabled={true}
                        values={values}
                        setValues={setValues}
                      />
                    )
                  )}
            </div>
          )}

        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          {fetchedOrderMilestone && fetchedOrderMilestone.status === 2 ? (
            <NextButton
              title="Accept & Release Payment"
              goToNextStep={acceptAndReleaseOrderMilestonePaymentCallback}
              loading={
                loading ||
                loadingCustomer ||
                loadingMilestonePayment ||
                loadingQuote ||
                loadingMilestoneInputFields
              }
              type={"button"}
              hideArrow={true}
            />
          ) : (
            <NextButton
              title="Back to track project"
              goToNextStep={(e) => {
                e.preventDefault();

                props.history.push(
                  "/trackProject/" + projectId + "/" + quoteId
                );
              }}
              loading={
                loading ||
                loadingCustomer ||
                loadingMilestonePayment ||
                loadingQuote ||
                loadingMilestoneInputFields
              }
              type={"button"}
              hideArrow={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const FETCH_INPUT_FIELDS_BY_MILESTONE_ID_QUERY = gql`
  query getInputFieldsByMilestoneId(
    $orderMilestoneId: String!
    $isTopLevel: Boolean
  ) {
    getInputFieldsByMilestoneId(
      orderMilestoneId: $orderMilestoneId
      isTopLevel: $isTopLevel
    ) {
      id
      title
      description
      stringPlaceHolder
      intPlaceHolder
      floatPlaceHolder
      tags
      parentInputFieldId
      inputBoxType
      actualType
      rows
      maxLength
      isMoney
      rangeMin
      rangeMax
      rangeStep
      acceptedFileFormats
      stringOptions
      intOptions
      floatOptions
      prereqInputFieldIdsToRequiredStringVal {
        inputFieldId
        stringVal
      }
      prereqInputFieldIdsToRequiredIntVal {
        inputFieldId
        intVal
      }
      prereqInputFieldIdsToRequiredFloatVal {
        inputFieldId
        floatVal
      }
      inputFieldIdsToDisabledStrings {
        inputFieldId
        disabledStrings
      }
      inputFieldIdsToDisabledInts {
        inputFieldId
        disabledInts
      }
      inputFieldIdsToDisabledFloats {
        inputFieldId
        disabledFloats
      }
      inputFieldIdsToDefaultStringVal {
        inputFieldId
        defaultString
      }
      inputFieldIdsToDefaultStringArrayVal {
        inputFieldId
        defaultStringArray
      }
      inputFieldIdsToDefaultIntVal {
        inputFieldId
        defaultInt
      }
      inputFieldIdsToDefaultIntArrayVal {
        inputFieldId
        defaultIntArray
      }
      inputFieldIdsToDefaultFloatVal {
        inputFieldId
        defaultFloat
      }
      inputFieldIdsToDefaultFloatArrayVal {
        inputFieldId
        defaultFloat
      }
      timesUsed
      active
      createdAt
    }
  }
`;

const ACCEPT_ORDER_MILESTONE_REQUEST = gql`
  mutation acceptAndReleaseOrderMilestonePayment($orderMilestoneId: String!) {
    acceptAndReleaseOrderMilestonePayment(orderMilestoneId: $orderMilestoneId) {
      id
    }
  }
`;

export const FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS = gql`
  query getCustomerPaymentMethodDetails {
    getCustomerPaymentMethodDetails {
      stripePaymentMethodId
      brand
      expMonth
      expYear
      last4
      name
      isPrimaryPaymentMethod
    }
  }
`;

export default MilestoneDetails;
