import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../context/auth";

function AuthRoute({ component: Component, ...rest }) {
  const { customer } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        customer ? <Redirect to="/projects" /> : <Component {...props} />
      }
    />
  );
}

export default AuthRoute;
