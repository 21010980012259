import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import RegisterPartnerServiceElement from "../../components/customer/RegisterPartnerServiceElement";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_NlZLhehUO2NfdIIDpOww0Qoo001nXLnzWh"
    : "pk_test_ITst5V2FKIZmXZhib3REV0Pb00cN3nJ5cf"
);
export default function RegisterPartnerService(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Register Partner | Softwair";
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <RegisterPartnerServiceElement props={props} />
    </Elements>
  );
}
