import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
// import CheckboxInputsWithTitle from "../../components/customer/CheckboxInputsWithTitle";
import CheckButton from "../../components/customer/CheckButton";
import { FETCH_PROJECT_BY_ID_QUERY } from "../../components/customer/DeleteProjectMedia";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import InputFieldsForSelectedTopLevelField from "../../components/customer/InputFieldsForSelectedTopLevelField";
import InputTitle from "../../components/customer/InputTitle";
import PageHeader from "../../components/customer/PageHeader";
// import RadioInputsWithTitle from "../../components/customer/RadioInputsWithTitle";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";
import { FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY } from "./ProjectDetails";
import { FETCH_PROJECTS_BY_CUSTOMER_QUERY } from "./Projects";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function CreateProject(props) {
  const location = useLocation();

  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const parentProjectId = props.match.params.parentProjectId;
  useEffect(() => {
    if (parentProjectId) {
      document.title = "Create Sub Part | Softwair";
    } else {
      document.title = "Create Project | Softwair";
    }
  }, [parentProjectId]);
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues, onArrayChange } = useForm(
    createProjectCallback,
    {
      editProjectId: null,
      parentProjectId: parentProjectId ? parentProjectId : null,
      title: "",
      shortDescription: "",
      selectedTopLvlInputFieldIds: [],

      subParts: [],
    }
  );

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    refetchQueries: parentProjectId
      ? [
          {
            query: FETCH_PROJECTS_BY_CUSTOMER_QUERY,
            variables: { searchTerm: "" },
          },
          {
            query: FETCH_PROJECT_BY_ID_QUERY,
            variables: { projectId: parentProjectId },
          },
          {
            query: FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY,
            variables: { parentProjectId, searchTerm: "" },
          },
        ]
      : [
          {
            query: FETCH_PROJECTS_BY_CUSTOMER_QUERY,
            variables: { searchTerm: "" },
          },
        ],

    update(_, { data: { createProject: projectData } }) {
      // refetch projects

      if (parentProjectId) {
        props.history.push("/projectDetails/" + parentProjectId);
      } else {
        props.history.push("/projects");
      }
    },
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log(values);
    },
    variables: values,
  });

  function createProjectCallback() {
    createProject();
  }

  const {
    data: { getSuggestedSubPartsByDesc: suggestedSubParts } = {},
    // error,
    loading: loadingSuggestedSubparts,
  } = useQuery(FETCH_SUGGEST_SUB_PARTS_BY_DESC_QUERY, {
    variables: {
      description: values.title + " " + values.shortDescription,
    },
  });

  const {
    data: { getSuggestedInputFieldsByDesc: topLvlSuggestedDetails } = {},
    // error,
    loading: loadingSuggestedDetails,
  } = useQuery(FETCH_SUGGEST_DETAILS_BY_DESC_QUERY, {
    variables: {
      description: values.title + " " + values.shortDescription,
      isTopLevel: true,
      selectedTopLvlInputFieldIds: values.selectedTopLvlInputFieldIds,
    },
  });
  const [prevTopLvlSuggestedDetails, setPrevTopLvlSuggestedDetails] = useState(
    []
  );

  useEffect(() => {
    if (
      topLvlSuggestedDetails &&
      topLvlSuggestedDetails !== prevTopLvlSuggestedDetails
    ) {
      setPrevTopLvlSuggestedDetails(topLvlSuggestedDetails);
    }
  }, [topLvlSuggestedDetails, prevTopLvlSuggestedDetails]);

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 pt-6 md:mb-2 md:p-0">
          <PageHeader
            props={props}
            titleBold={parentProjectId ? "New sub part" : "New project"}
            hideHomeButton={true}
            // caption="Fill out this form to save your idea. Later, you can get quotations for it within two days."
          />
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-4">
          <InputField
            fieldTitle={`Title* ${
              values.title ? `(${values.title.length}/30)` : ""
            }`}
            errorMsg={errors && errors.title}
            placeholder="Name your invention"
            name="title"
            onChangeFunction={onChange}
            value={values.title}
            type="text"
            keepAutoCompleteOff={true}
            maxLength="30"
          />
          <InputField
            fieldTitle={`Short description* ${
              values.shortDescription
                ? `(${values.shortDescription.length}/100)`
                : ""
            }`}
            errorMsg={errors && errors.shortDescription}
            placeholder="What are you making?"
            name="shortDescription"
            onChangeFunction={onChange}
            value={values.shortDescription}
            type="textarea"
            rows={"3"}
            maxLength="100"
          />

          {((topLvlSuggestedDetails && topLvlSuggestedDetails.length > 0) ||
            (prevTopLvlSuggestedDetails &&
              prevTopLvlSuggestedDetails.length > 0)) && (
            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <InputTitle
                fieldTitle={"Details (optional)"}
                errorMsg={errors && errors.selectedTopLvlInputFieldIds}
                hint={
                  "Add details by clicking one of the options below. Click again to remove that detail."
                }
              />

              <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                {topLvlSuggestedDetails &&
                  topLvlSuggestedDetails.length > 0 &&
                  topLvlSuggestedDetails.map((topLvlSuggestedDetail, index) => (
                    <InputField
                      name={"selectedTopLvlInputFieldIds"}
                      onChangeFunction={onArrayChange}
                      checkboxValue={topLvlSuggestedDetail.id}
                      type="checkbox"
                      value={values.selectedTopLvlInputFieldIds}
                      checkboxTitle={topLvlSuggestedDetail.title}
                      key={index}
                    />
                  ))}
                {(!topLvlSuggestedDetails ||
                  topLvlSuggestedDetails.length === 0) &&
                  prevTopLvlSuggestedDetails &&
                  prevTopLvlSuggestedDetails.length > 0 &&
                  prevTopLvlSuggestedDetails.map(
                    (prevTopLvlSuggestedDetail, index) => (
                      <InputField
                        name={"selectedTopLvlInputFieldIds"}
                        onChangeFunction={onArrayChange}
                        checkboxValue={prevTopLvlSuggestedDetail.id}
                        type="checkbox"
                        value={values.selectedTopLvlInputFieldIds}
                        checkboxTitle={prevTopLvlSuggestedDetail.title}
                        key={index}
                        disabled={true}
                      />
                    )
                  )}
              </div>
            </div>
          )}

          {values.selectedTopLvlInputFieldIds &&
            values.selectedTopLvlInputFieldIds.length > 0 &&
            values.selectedTopLvlInputFieldIds.map(
              (topLvlInputFieldId, index) => (
                <InputFieldsForSelectedTopLevelField
                  key={index}
                  onChange={onChange}
                  topLvlInputFieldId={topLvlInputFieldId}
                  errors={errors}
                  values={values}
                  setValues={setValues}
                />
              )
            )}

          {/* Sub parts */}
          {values.shortDescription &&
            values.title &&
            suggestedSubParts &&
            suggestedSubParts.length > 0 && (
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <InputTitle
                  fieldTitle="Suggested sub parts (optional)"
                  errorMsg={errors && errors.details}
                  hint={`Add parts you might need with ${values.title}. You can edit them or add more later on.`}
                />

                <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                  {suggestedSubParts.map((subPartName, index) => (
                    <InputField
                      name="subParts"
                      onChangeFunction={onArrayChange}
                      checkboxValue={subPartName}
                      type="checkbox"
                      value={values.subParts}
                      checkboxTitle={subPartName}
                    />
                  ))}
                </div>
              </div>
            )}
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <CheckButton
            loading={
              loading ||
              loadingSuggestedDetails ||
              loadingCustomer ||
              loadingSuggestedSubparts
            }
            btnFunction={createProjectCallback}
            type="button"
          />
        </div>
      </div>
    </div>
  );
}

export const FETCH_SUGGEST_DETAILS_BY_DESC_QUERY = gql`
  query getSuggestedInputFieldsByDesc(
    $description: String
    $isTopLevel: Boolean
    $selectedTopLvlInputFieldIds: [String]
  ) {
    getSuggestedInputFieldsByDesc(
      description: $description
      isTopLevel: $isTopLevel
      selectedTopLvlInputFieldIds: $selectedTopLvlInputFieldIds
    ) {
      id
      title
      description
      stringPlaceHolder
      intPlaceHolder
      floatPlaceHolder
      tags
      parentInputFieldId
      inputBoxType
      actualType
      rows
      maxLength
      isMoney
      rangeMin
      rangeMax
      rangeStep
      acceptedFileFormats
      stringOptions
      intOptions
      floatOptions
      inputFieldIdsToDisabledStrings {
        inputFieldId
        disabledStrings
      }
      inputFieldIdsToDisabledInts {
        inputFieldId
        disabledInts
      }
      inputFieldIdsToDisabledFloats {
        inputFieldId
        disabledFloats
      }
      inputFieldIdsToDefaultStringVal {
        inputFieldId
        defaultString
      }
      inputFieldIdsToDefaultStringArrayVal {
        inputFieldId
        defaultStringArray
      }
      inputFieldIdsToDefaultIntVal {
        inputFieldId
        defaultInt
      }
      inputFieldIdsToDefaultIntArrayVal {
        inputFieldId
        defaultIntArray
      }
      inputFieldIdsToDefaultFloatVal {
        inputFieldId
        defaultFloat
      }
      inputFieldIdsToDefaultFloatArrayVal {
        inputFieldId
        defaultFloat
      }
      timesUsed
      active
      createdAt
    }
  }
`;

export const FETCH_SUGGEST_SUB_PARTS_BY_DESC_QUERY = gql`
  query getSuggestedSubPartsByDesc($description: String, $projectId: String) {
    getSuggestedSubPartsByDesc(description: $description, projectId: $projectId)
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject(
    $customerId: String
    $editProjectId: String
    $parentProjectId: String
    $title: String!
    $shortDescription: String!
    $selectedTopLvlInputFieldIds: [String]
    $inputIdsToUploads: [InputIdToUploadValInput]
    $inputIdsToDates: [InputIdToDateValInput]
    $inputIdsToStrings: [InputIdToStringValInput]
    $inputIdsToInts: [InputIdToIntValInput]
    $inputIdsToFloats: [InputIdToFloatValInput]
    $inputIdsToUploadArrays: [InputIdToUploadArrayValInput]
    $inputIdsToStringArrays: [InputIdToStringArrayValInput]
    $inputIdsToIntArrays: [InputIdToIntArrayValInput]
    $inputIdsToFloatArrays: [InputIdToFloatArrayValInput]
    $subParts: [String]
  ) {
    createProject(
      customerId: $customerId
      editProjectId: $editProjectId
      parentProjectId: $parentProjectId
      title: $title
      shortDescription: $shortDescription
      selectedTopLvlInputFieldIds: $selectedTopLvlInputFieldIds
      inputIdsToUploads: $inputIdsToUploads
      inputIdsToDates: $inputIdsToDates
      inputIdsToStrings: $inputIdsToStrings
      inputIdsToInts: $inputIdsToInts
      inputIdsToFloats: $inputIdsToFloats
      inputIdsToUploadArrays: $inputIdsToUploadArrays
      inputIdsToStringArrays: $inputIdsToStringArrays
      inputIdsToIntArrays: $inputIdsToIntArrays
      inputIdsToFloatArrays: $inputIdsToFloatArrays
      subParts: $subParts
    ) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export default CreateProject;
