import { gql, useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "../../util/hooks";
import { FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS } from "./RegisterPartnerServiceElement";

function CardSelectButton({ paymentMethod, customerId }) {
  // const [errors, setErrors] = useState({});

  const { values, onSubmit } = useForm(saveCustomerDetailsCallback, {
    customerId,
    primaryStripePaymentMethodId: paymentMethod.stripePaymentMethodId,
  });
  const [saveCustomerDetails, { loading }] = useMutation(
    SAVE_CUSTOMER_DETAILS_MUTATION,
    {
      refetchQueries: [{ query: FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS }],
      update(proxy, { data: { saveCustomerDetails: storeData } }) {
        // setErrors({});
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        // setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: values,
    }
  );

  function saveCustomerDetailsCallback() {
    saveCustomerDetails();
  }
  return (
    <button
      onClick={onSubmit}
      className={`${
        paymentMethod.isPrimaryPaymentMethod
          ? "bg-white text-blue-600 hover:bg-gray-300 border-4 border-blue-600"
          : "md:border-4 md:border-black bg-gray-300 hover:bg-gray-200 text-black"
      } focus:bg-white flex items-center justify-between focus:border-blue-800 font-medium focus:outline-none rounded-lg w-full text-left shadow-lg p-4 ${
        loading ? "animate-pulse" : ""
      }`}
    >
      {paymentMethod.brand && (
        <img
          src={
            paymentMethod.brand === "visa"
              ? "https://bh-company-images.s3.amazonaws.com/visa_symbol.svg"
              : paymentMethod.brand === "masterCard"
              ? "https://bh-company-images.s3.amazonaws.com/mc_symbol.svg"
              : ""
          }
          className="h-3"
          alt=""
        />
      )}
      <p className="font-medium text-2xl leading-none">{paymentMethod.last4}</p>
    </button>
  );
}

export const SAVE_CUSTOMER_DETAILS_MUTATION = gql`
  mutation saveCustomerDetails(
    $customerId: String!
    $primaryStripePaymentMethodId: String
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $line1: String
    $line2: String
    $city: String
    $region: String
    $country: String
  ) {
    saveCustomerDetails(
      saveCustomerDetailsInput: {
        customerId: $customerId
        primaryStripePaymentMethodId: $primaryStripePaymentMethodId
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        line1: $line1
        line2: $line2
        city: $city
        region: $region
        country: $country
      }
    ) {
      id
      stripeCustomerId
      primaryStripePaymentMethodId
      firstName
      lastName
      email

      createdAt
    }
  }
`;

export default CardSelectButton;
