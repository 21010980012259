import jwtDecode from "jwt-decode";
import React, { createContext, useReducer } from "react";

const initialState = {
  customer: null,
};

if (localStorage.getItem("jwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("jwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("jwtToken");
  } else {
    initialState.customer = decodedToken;
  }
}

const AuthContext = createContext({
  customer: null,
  loginCustomer: (customerData) => {},
  logout: () => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN_CUSTOMER":
      return {
        ...state,
        customer: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        customer: null,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function loginCustomer(customerData) {
    localStorage.setItem("jwtToken", customerData.token);
    dispatch({
      type: "LOGIN_CUSTOMER",
      payload: customerData,
    });
  }

  function logout() {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("newUserAddress");
    dispatch({ type: "LOGOUT" });
  }

  return (
    <AuthContext.Provider
      value={{ customer: state.customer, loginCustomer, logout }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
