import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY } from "../../pages/customer/WriteQuote";
import { useForm } from "../../util/hooks";
import { FETCH_ORDER_MILESTONE_BY_ID_QUERY } from "./EditMilestoneImages";

function DeleteOrderMilestoneMedia({ url, orderMilestone, isAbsolute }) {
  const [errors, setErrors] = useState({});
  if (errors) {
    console.log(errors);
  }
  const { values } = useForm(deleteOrderMilestoneMediaCallback, {
    orderMilestoneId: orderMilestone.id,
    url: url,
  });
  const [deleteOrderMilestoneMedia, { loading }] = useMutation(
    DELETE_ORDER_MILESTONE_MEDIA,
    {
      refetchQueries: [
        {
          query: FETCH_ORDER_MILESTONE_BY_ID_QUERY,
          variables: { orderMilestoneId: orderMilestone.id },
        },

        {
          query: FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
          variables: { quoteRequestId: orderMilestone.quoteRequestId },
        },
      ],

      update(_, { data: { deleteOrderMilestoneMedia: projectData } }) {},
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: values,
    }
  );

  function deleteOrderMilestoneMediaCallback() {
    deleteOrderMilestoneMedia();
  }

  return (
    <button
      type="button"
      onClick={deleteOrderMilestoneMediaCallback}
      className={`${
        isAbsolute ? "absolute right-1 top-1 " : "ml-20"
      } bg-red-500 p-1 rounded-full w-min transition-colors focus:outline-none focus:ring-1`}
      disabled={loading}
    >
      <svg
        className="fill-current text-white h-4"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
      </svg>
    </button>
  );
}

const DELETE_ORDER_MILESTONE_MEDIA = gql`
  mutation deleteOrderMilestoneMedia(
    $orderMilestoneId: String!
    $url: String!
  ) {
    deleteOrderMilestoneMedia(orderMilestoneId: $orderMilestoneId, url: $url)
  }
`;
export default DeleteOrderMilestoneMedia;
