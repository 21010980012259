import { useQuery } from "@apollo/client";
import React from "react";
import { FETCH_PARTNER_SERVICE_BY_ID_QUERY } from "./QuoteQuickDisplayBox";

export default function PartnerStaffRoleCaption({
  staffRole,
  index,
  isLast,
  isSecondLast,
}) {
  const {
    data: { getPartnerServiceById: fetchedPartnerService } = {},
    // loading: { loadingPartnerService },
  } = useQuery(FETCH_PARTNER_SERVICE_BY_ID_QUERY, {
    variables: { partnerServiceId: staffRole.partnerServiceId },
  });
  return fetchedPartnerService ? (
    <span>
      {staffRole.positionTitle} at {fetchedPartnerService.businessName}
      {isLast ? "." : isSecondLast ? ", and " : ", "}
    </span>
  ) : (
    <></>
  );
}
