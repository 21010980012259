import React, { useState } from "react";

export default function VersionsButton({ project, props }) {
  // console.log("yo what's up");
  const [openVersionsCard, setOpenVersionsCard] = useState(false);

  function toggleOpenVersionCard() {
    setOpenVersionsCard(!openVersionsCard);
  }

  // const myRef = (ref) => {
  //   if (ref) {
  //     // console.log(activeFilters);
  //     ref.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };
  return (
    <div className=" inline-block relative">
      <button
        className="bg-white rounded-full w-11 h-11 sm:h-14 sm:w-14 md:w-11 md:h-11 p-3 shadow-lg focus:outline-none focus:ring-1"
        onClick={toggleOpenVersionCard}
        disabled={!project}
      >
        <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9875 2.5C8.0875 2.5 2.5 8.1 2.5 15C2.5 21.9 8.0875 27.5 14.9875 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 14.9875 2.5ZM15 25C9.475 25 5 20.525 5 15C5 9.475 9.475 5 15 5C20.525 5 25 9.475 25 15C25 20.525 20.525 25 15 25ZM13.75 8.75H15.625V15.3125L21.25 18.65L20.3125 20.1875L13.75 16.25V8.75Z"
            fill="#2563EB"
          />
        </svg>
      </button>

      {openVersionsCard && project && project.allProjectVersionIds && (
        <>
          <button
            className="fixed inset-0 h-full w-full cursor-pointer z-10"
            tabIndex="-1"
            onClick={toggleOpenVersionCard}
          ></button>

          <div className="flex flex-col bg-white absolute top-0 z-20 rounded-lg shadow-lg overflow-y-auto max-h-24 p-1">
            {project.allProjectVersionIds.map((versionId, index) => (
              <button
                className={`${
                  project.id === versionId
                    ? "text-white bg-blue-600 cursor-default"
                    : "text-blue-600 hover:bg-gray-200"
                } p-2 rounded focus:outline-none focus:ring-1 flex-shrink-0`}
                type="button"
                onClick={(e) => {
                  props.history.push("/projectDetails/" + versionId);
                }}
                // ref={project.id === versionId ? myRef : null}
                key={index}
              >
                {(index / 10).toFixed(1)}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
