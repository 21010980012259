import React, { useCallback, useEffect, useState } from "react";
import InputField from "./InputField";

export default function StringInputField({
  values,
  errors,
  setValues,
  topLvlInputFieldId,
  fetchedInputField,
  disabled,
}) {
  const [stringVal, setStringVal] = useState("");

  const [doneSettingDefaultVals, setDoneSettingDefaultVals] = useState(false);

  useEffect(() => {
    const setDefaultVal = () => {
      if (
        fetchedInputField.actualType === "string" &&
        (fetchedInputField.inputBoxType === "radio" ||
          fetchedInputField.inputBoxType === "checkbox")
      ) {
        if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
          for (let i = 0; i < values.inputIdsToStrings.length; ++i) {
            if (
              values.inputIdsToStrings[i].inputFieldId === fetchedInputField.id
            ) {
              // console.log("found a match; "+);
              setStringVal(values.inputIdsToStrings[i].stringVal);
              return;
            }
          }
        }
        setStringVal(fetchedInputField.stringPlaceHolder);
      }
    };
    if (!doneSettingDefaultVals && !stringVal) {
      setDefaultVal();
      setDoneSettingDefaultVals(true);
    } else {
      // console.log(stringVal);
      // console.log(intVal);
      // console.log(floatVal);
    }
  }, [stringVal, fetchedInputField, values, doneSettingDefaultVals]);

  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setStringHereAndInValues(e.target.value);
  };

  const setStringHereAndInValues = useCallback(
    (targetString) => {
      if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
        for (let i = 0; i < values.inputIdsToStrings.length; ++i) {
          if (
            values.inputIdsToStrings[i].inputFieldId === fetchedInputField.id
          ) {
            var copiedValues = { ...values };

            copiedValues.inputIdsToStrings[i].stringVal = targetString;
            setValues(copiedValues);
            break;
          }
        }
      } else {
        const copiedValues = {
          ...values,
          inputIdsToStrings: [
            {
              inputFieldId: fetchedInputField.id,
              stringVal: targetString,
            },
          ],
        };

        setValues(copiedValues);
      }

      setStringVal(targetString);
    },
    [fetchedInputField, values, setValues]
  );

  // const setDefaultStringHereAndInValues = useCallback(() => {
  //   if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
  //     for (let i = 0; i < values.inputIdsToStrings.length; ++i) {
  //       if (values.inputIdsToStrings[i].inputFieldId === fetchedInputField.id) {
  //         setStringVal(values.inputIdsToStrings[i].stringVal);
  //         return;
  //       }
  //     }
  //   }

  //   if (
  //     fetchedInputField.inputBoxType === "radio" ||
  //     fetchedInputField.inputBoxType === "checkbox"
  //   ) {
  //     setStringVal(fetchedInputField.stringPlaceHolder);
  //   }
  // }, [fetchedInputField, setStringHereAndInValues, values.inputIdsToStrings]);

  // useEffect(() => {
  //   if (!checkedInitialVal) {
  //     setDefaultStringHereAndInValues();
  //     setCheckedInitialVal(true);
  //   }
  // }, [checkedInitialVal, setDefaultStringHereAndInValues]);

  return (
    <InputField
      fieldTitle={
        disabled ? fetchedInputField.title : fetchedInputField.title + "*"
      }
      errorMsg={errors && errors[fetchedInputField.id]}
      name={fetchedInputField.id}
      onChangeFunction={onChange}
      type={fetchedInputField.inputBoxType}
      placeholder={fetchedInputField.stringPlaceHolder}
      rows={fetchedInputField.rows}
      value={stringVal}
      hint={fetchedInputField.description}
      disabled={disabled} // setDocumentCallback={setDocumentCallback}
    />
  );
}
