import { useQuery } from "@apollo/client";
import React from "react";
import { FETCH_CUSTOMER_BY_ID_QUERY } from "../../pages/customer/Chat";

export default function ChatMemberTitle({
  customerId,
  isLast,
  thisCustomerId,
}) {
  const {
    data: { getCustomerById: fetchedCustomer } = {},

    // loading,
  } = useQuery(FETCH_CUSTOMER_BY_ID_QUERY, {
    variables: { customerId },
  });

  return fetchedCustomer ? (
    <p
      className={`text-gray-700 md:text-gray-300 text-left leading-tight text-lg`}
    >
      {thisCustomerId === customerId ? "You" : fetchedCustomer.firstName}
      {isLast ? "" : ","}
    </p>
  ) : (
    <></>
  );
}
