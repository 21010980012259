import { gql, useMutation, useQuery } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import CardSection from "../../components/customer/CardSection";
import InputField from "../../components/customer/InputField";
import PageHeader from "../../components/customer/PageHeader";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_CUSTOMER_QUERY } from "../../pages/customer/Settings";
import { useForm } from "../../util/hooks";
import HomeButton from "./HomeButton";
import NewCardInputSection from "./NewCardInputSection";
import ShareSoftwair from "./ShareSoftwair";
import SocialsFooter from "./SocialsFooter";

// const validator = require("validator");
// const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

function RegisterPartnerServiceElement({ props }) {
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }
  const stripe = useStripe();
  const elements = useElements();

  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(
    registerPartnerServiceCallback,
    {
      customerId: customer && customer.id,

      imageFiles: [],

      city: "",
      region: "",
      country: "",
      line1: "",
      line2: "",
      areaCode: "",
      businessName: "",
      businessEmail: "",
      businessPhone: "",
      businessWebsite: "",
      businessRegistrationNumber: "",
      servicesProvided: "",
      useNewCard: false,
      signedUpOn: "tech",
    }
  );

  const {
    data: { getCustomerPaymentMethodDetails: paymentMethodDetails } = {},
    loading: { loadingPaymentMethodDetails },
    // error: errorPaymentMethods,
  } = useQuery(FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS);

  const [partnerServiceId, setPaymentServiceId] = useState("");
  function toggleCardOpen() {
    setValues({
      ...values,
      useNewCard: !values.useNewCard,
    });
  }

  const [registerPartnerService, { loading }] = useMutation(
    REGISTER_PARTNER_SERVICE,
    {
      async update(
        _,
        { data: { registerPartnerService: partnerServiceData } }
      ) {
        setPaymentServiceId(partnerServiceData.id);

        if (values.useNewCard) {
          const result = await stripe.confirmCardPayment(
            partnerServiceData.paymentIntentClientSecret,
            {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            }
          );

          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            setErrors({
              ...errors,
              paymentError: result.error.message,
            });
          } else {
            switch (result.paymentIntent.status) {
              case "requires_capture":
                // update status of checkout to requires capture
                // update orders to begin fulfillment
                //redirect to success page
                processPartnerServicePaymentResult();
                break;
              case "requires_action":
              case "requires_source_action":
                console.log("Payment requires action");
                setErrors({
                  ...errors,
                  paymentError: "Payment requires action",
                });
                break;
              case "requires_payment_method":
              case "requires_source":
                console.log("Card Denied");
                setErrors({
                  ...errors,
                  paymentError:
                    "Your card was denied, please provide a new payment method",
                });
                break;
              case "succeeded":
                console.log("💰 Payment received?");
                // throw new UserInputError(
                //   "Payment should not have gone through since we are just placing a hold",
                setErrors({
                  ...errors,
                  paymentError:
                    "Your payment was received even though we were just trying to place a hold on your card",
                });
                break;
              default:
                break;
            }
          }
        } else {
          processPartnerServicePaymentResult();
        }
      },
      onError(err) {
        // setLoadingWholePayment(false);
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: values,
    }
  );

  function registerPartnerServiceCallback() {
    // setLoadingWholePayment(true);
    registerPartnerService();
  }

  const [
    processPartnerServicePaymentResult,
    { loading: loadingPartnerServicePaymentResult },
  ] = useMutation(PROCESS_PARTNER_SERVICE_PAYMENT_RESULT_MUTATION, {
    refetchQueries: [{ query: FETCH_CUSTOMER_QUERY }],
    update(proxy, { data }) {
      // setLoadingWholePayment(false);
      setErrors({});

      // if (quoteData.status === 1) {
      window.location.href = data.processPartnerServicePaymentResult;

      //   props.history.push(`/partnerServiceRegistered/${partnerServiceId}`);
      // }
    },
    onError(err) {
      // setLoadingWholePayment(false);
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: { partnerServiceId },
  });

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    event.stopPropagation();
    setErrors({});

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // add addresses to orders/make sure they have addresses
    registerPartnerServiceCallback();
  };

  const setImagesCallback = (imageFiles) => {
    var totalImageFiles = [...imageFiles, ...values.imageFiles];

    setValues({
      ...values,
      imageFiles: totalImageFiles,
    });
  };

  const deleteDocumentCallback = (index) => {
    var totalImageFiles = [...values.imageFiles];
    totalImageFiles.splice(index, 1);
    setValues({
      ...values,
      imageFiles: totalImageFiles,
    });
  };

  const [showLine2, setShowLine2] = useState(false);
  function toggleLine2(e) {
    e.preventDefault();
    if (!showLine2 === false) {
      setValues({
        ...values,
        line2: "",
      });
    }
    setShowLine2(!showLine2);
  }

  if (
    paymentMethodDetails &&
    paymentMethodDetails.length === 0 &&
    !values.useNewCard
  ) {
    console.log(paymentMethodDetails);
    setValues({
      ...values,
      useNewCard: true,
    });
  }
  // const [loadingWholePayment, setLoadingWholePayment] = useState(false);

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={true}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 pt-6 md:mb-2 md:p-0">
          <PageHeader
            props={props}
            titleBold="Partner registration"
            // caption={
            //   // curStep === 4?
            //   "You will be charged CA$200.00 minus credits earned every month. You can cancel your subscription at any time with membership refunds for the months you got 0 quotation requests by emailing us at softwair@bringhome.ca."
            //   // : "Complete this form to start providing your product/service to Softwair customers."
            // }
            hideHomeButton={true}
            // padTop={true}
          />
        </div>

        <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-4">
          <InputField
            fieldTitle="Business name*"
            errorMsg={errors && errors.businessName}
            placeholder="XYZ International"
            name="businessName"
            onChangeFunction={onChange}
            value={values.businessName}
            type="text"
          />

          <InputField
            fieldTitle="Business email*"
            errorMsg={errors && errors.businessEmail}
            placeholder="xyz@company.com"
            name="businessEmail"
            onChangeFunction={onChange}
            value={values.businessEmail}
            type="email"
          />

          <InputField
            fieldTitle="Business phone (with region code)*"
            errorMsg={errors && errors.businessPhone}
            placeholder="+1 416-647-9050"
            name="businessPhone"
            onChangeFunction={onChange}
            value={values.businessPhone}
            type="tel"
            // hint="With country code."
          />

          <InputField
            fieldTitle="Business registration number*"
            errorMsg={errors && errors.businessRegistrationNumber}
            placeholder="XIAHWHEHR"
            name="businessRegistrationNumber"
            onChangeFunction={onChange}
            value={values.businessRegistrationNumber}
            type="text"
          />
          <InputField
            fieldTitle="Business website*"
            errorMsg={errors && errors.businessWebsite}
            placeholder="xyz.com"
            name="businessWebsite"
            onChangeFunction={onChange}
            value={values.businessWebsite}
            type="text"
          />
          <InputField
            fieldTitle="Address line 1*"
            errorMsg={errors && errors.line1}
            placeholder="416 Boulevard"
            name="line1"
            onChangeFunction={onChange}
            value={values.line1}
            type="text"
            additionalBtnText={"Add line 2"}
            additionalBtnFunction={toggleLine2}
          />

          {showLine2 && (
            <InputField
              fieldTitle="Address line 2 (optional)"
              errorMsg={errors && errors.line2}
              placeholder="Apt #905"
              name="line2"
              onChangeFunction={onChange}
              value={values.line2}
              type="text"
            />
          )}

          <InputField
            fieldTitle="Area code*"
            errorMsg={errors && errors.areaCode}
            placeholder="L5M2J3"
            name="areaCode"
            onChangeFunction={onChange}
            value={values.areaCode}
            type="text"
          />

          <InputField
            fieldTitle="City*"
            errorMsg={errors && errors.city}
            placeholder="Toronto"
            name="city"
            onChangeFunction={onChange}
            value={values.city}
            type="text"
          />
          <InputField
            fieldTitle="Region*"
            errorMsg={errors && errors.region}
            placeholder="Ontario"
            name="region"
            onChangeFunction={onChange}
            value={values.region}
            type="text"
          />
          <InputField
            fieldTitle="Country*"
            errorMsg={errors && errors.country}
            placeholder="Canada"
            name="country"
            onChangeFunction={onChange}
            value={values.country}
            type="text"
          />
          <InputField
            fieldTitle="Images of your products/services.*"
            errorMsg={errors && errors.imageFiles}
            name="imageFiles"
            onChangeFunction={onChange}
            value={values.imageFiles}
            type="documents"
            setDocumentsCallback={setImagesCallback}
            deleteDocumentCallback={deleteDocumentCallback}
            hint="Customers will see these pictures when they consider accepting your quotes."
            acceptedFileFormats={["image/gif", "image/jpeg", "image/png"]}
          />

          <InputField
            fieldTitle="Products/services (comma separated)*"
            errorMsg={errors && errors.servicesProvided}
            name="servicesProvided"
            onChangeFunction={onChange}
            value={values.servicesProvided}
            placeholder="FPGA design, PCB manufacturing, Embedded software, Shoe manufacturing, Last-mile shipping"
            type="textarea"
            rows="3"
            hint={`List all the services you provide to help us better match quotation requests with ${
              values.businessName ? values.businessName : "your company"
            }.`}
          />
          {fetchedCustomer && paymentMethodDetails && (
            <>
              <InputField
                fieldTitle="Total for first month (CA$)"
                value={20000}
                disabled={true}
                type="range"
                discountApplied={fetchedCustomer.discount}
                isMoney={true}
                hint="You will be charged CA$200/mo. You cancel anytime by messaging Softwair Support."
              />

              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <div className="flex items-center justify-between w-full">
                  <h5 className="md:text-gray-300 text-gray-500">
                    Card details*
                  </h5>
                  {errors && errors.paymentError && (
                    <h5 className="text-red-500 font-medium text-right">
                      {errors.paymentError}
                    </h5>
                  )}
                  {paymentMethodDetails &&
                    paymentMethodDetails.length > 0 &&
                    (!values.useNewCard ? (
                      <button
                        onClick={toggleCardOpen}
                        className="text-blue-400 hover:text-blue-600 focus:outline-none font-medium text-right"
                      >
                        Use A New Card
                      </button>
                    ) : (
                      <button
                        onClick={toggleCardOpen}
                        className="text-blue-400 hover:text-blue-600 focus:outline-none font-medium text-right"
                      >
                        Choose from Cards
                      </button>
                    ))}
                </div>

                <div className="text-4xl md:text-3xl lg:text-4xl bg-transparent text-white w-full flex-grow-0">
                  {!values.useNewCard &&
                  paymentMethodDetails &&
                  paymentMethodDetails.length > 0 ? (
                    <CardSection
                      paymentMethodDetails={paymentMethodDetails}
                      toggleCardOpen={toggleCardOpen}
                      customerId={fetchedCustomer.id}
                    />
                  ) : (
                    <NewCardInputSection />
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <button
            onClick={handleSubmit}
            disabled={
              loading ||
              loadingPaymentMethodDetails ||
              loadingCustomer ||
              loadingPartnerServicePaymentResult
              // ||
              // loadingWholePayment
            }
            className="p-3 w-min hover:bg-gray-300 rounded-full bg-white transition-colors focus:outline-none focus:ring-1"
          >
            {loading ||
            loadingPaymentMethodDetails ||
            loadingCustomer ||
            loadingPartnerServicePaymentResult ? (
              //  ||
              // loadingWholePayment
              <svg
                className="h-6 animate-spin"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                  stroke="black"
                  strokeWidth="2"
                />
                <path
                  d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                  stroke="black"
                  strokeWidth="2"
                />
              </svg>
            ) : (
              <svg
                viewBox="0 0 30 30"
                className="fill-current text-black h-6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.9937 19.8438L5.78124 14.6313L4.00624 16.3938L10.9937 23.3813L25.9937 8.38127L24.2312 6.61877L10.9937 19.8438Z" />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

const REGISTER_PARTNER_SERVICE = gql`
  mutation registerPartnerService(
    $customerId: String!
    $city: String!
    $region: String!
    $country: String!
    $line1: String!
    $line2: String
    $areaCode: String!
    $businessName: String!
    $businessEmail: String!
    $businessPhone: String!
    $businessWebsite: String!
    $businessRegistrationNumber: String!
    $servicesProvided: String!
    $imageFiles: [Upload]!
    $useNewCard: Boolean!
    $signedUpOn: String!
  ) {
    registerPartnerService(
      customerId: $customerId
      city: $city
      region: $region
      country: $country
      line1: $line1
      line2: $line2
      areaCode: $areaCode
      businessName: $businessName
      businessEmail: $businessEmail
      businessPhone: $businessPhone
      businessWebsite: $businessWebsite
      businessRegistrationNumber: $businessRegistrationNumber
      servicesProvided: $servicesProvided
      imageFiles: $imageFiles

      useNewCard: $useNewCard
      signedUpOn: $signedUpOn
    ) {
      id
      paymentIntentClientSecret
    }
  }
`;

const PROCESS_PARTNER_SERVICE_PAYMENT_RESULT_MUTATION = gql`
  mutation processPartnerServicePaymentResult($partnerServiceId: String!) {
    processPartnerServicePaymentResult(partnerServiceId: $partnerServiceId)
  }
`;
export const FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS = gql`
  query getCustomerPaymentMethodDetails {
    getCustomerPaymentMethodDetails {
      stripePaymentMethodId
      brand
      expMonth
      expYear
      last4
      name
      isPrimaryPaymentMethod
    }
  }
`;
export default RegisterPartnerServiceElement;
