import React, { useState } from "react";
import googlePlayImage from "../../images/google-play.svg";
import tiktokImage from "../../images/tiktok.svg";
import twitterImage from "../../images/twitter.svg";
import youtubeImage from "../../images/youtube.svg";

export default function SocialsFooter() {
  const [emissionsText, setEmissionsText] = useState("Net-zero emissions");
  const mouseOver = () => {
    setEmissionsText(
      emissionsText === "Net-zero emissions"
        ? "100% offset"
        : "Net-zero emissions"
    );
  };
  return (
    <div className="flex flex-row space-x-4 items-center justify-start">
      <div
        className={`${
          emissionsText === "Net-zero emissions" ? "cursor-pointer" : ""
        } hover:bg-black hover:text-white border-black border-2 lg:text-xl rounded-full px-8 py-1 text-center w-60`}
        onMouseOver={(e) => {
          e.preventDefault();
          mouseOver();
        }}
        onMouseOut={(e) => {
          e.preventDefault();
          mouseOver();
        }}
      >
        {emissionsText}
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://play.google.com/store/apps/details?id=tech.softwair"
      >
        <img
          className="h-10 object-cover"
          src={googlePlayImage}
          alt="Get it on google play"
        />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.tiktok.com/@softwair.tech"
      >
        <img
          className="h-10 object-cover"
          src={tiktokImage}
          alt="Softwair | TikTok"
        />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/channel/UCgyiwgls8R0LLyyLy1VUHAg"
      >
        <img
          className="h-10 object-cover"
          src={youtubeImage}
          alt="Softwair | YouTube"
        />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/Softwair_tech"
      >
        <img
          className="h-10 object-cover"
          src={twitterImage}
          alt="Softwair | Twitter"
        />
      </a>
    </div>
  );
}
