import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FETCH_SUGGEST_DETAILS_BY_DESC_QUERY } from "../../pages/customer/CreateProject";
import { FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY } from "../../pages/customer/FindJobs";
import { FETCH_QUOTE_REQUEST_BY_ID_QUERY } from "../../pages/customer/JobDetails";
import {
  FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
  FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
} from "../../pages/customer/Jobs";
import {
  FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
  FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
} from "../../pages/customer/WriteQuote";
import { useForm } from "../../util/hooks";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import InputField from "./InputField";
import InputFieldsForSelectedTopLevelField from "./InputFieldsForSelectedTopLevelField";
import InputTitle from "./InputTitle";
import PageHeader from "./PageHeader";
import QuoteSummaryBox from "./QuoteSummaryBox";

function AddOrderMilestoneElement({
  quoteRequest,
  orderMilestones,
  quote,
  props,
}) {
  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: quote.projectId },
    }
  );

  const [errors, setErrors] = useState({});

  const { onChange, values, setValues, onArrayChange } = useForm(
    createOrderMilestoneCallback,
    {
      editOrderMilestoneId: null,
      quoteId: quote.id, //
      responsiblePartnerServiceId: "",
      doneByPartnerServiceId: "",
      partnerStaffId: "",

      title: "", //
      shortDescription: "", //
      finalSubTotal: 2000, //
      deadline: "", //

      selectedTopLvlInputFieldIds: [],
    }
  );

  const [createOrderMilestone, { loading }] = useMutation(
    CREATE_ORDER_MILESTONE,
    {
      refetchQueries: [
        {
          query: FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
          variables: { quoteRequestId: quoteRequest.id },
        },
        {
          query: FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
          variables: { quoteRequestId: quoteRequest.id },
        },
      ],

      update(_, { data: { createOrderMilestone: orderMilestoneData } }) {
        // refetch projects
        setPrevTopLvlSuggestedDetails([]);
        // props.history.push("/projects");
        setErrors({});
        setValues({
          ...values,
          editOrderMilestoneId: null,
          quoteId: quote.id, //

          title: "", //
          shortDescription: "", //
          finalSubTotal: 2000, //
          deadline: "", //

          selectedTopLvlInputFieldIds: [],
        });
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: values,
    }
  );

  function createOrderMilestoneCallback() {
    createOrderMilestone();
  }

  const {
    data: { getPartnerServicesByStaff: partnerServices } = {},
    loading: { loadingPartnerServices },
  } = useQuery(FETCH_PARTNER_SERVICES_BY_STAFF_QUERY);

  const {
    data: { getPartnerStaffRolesByCustomerId: partnerStaffRoles } = {},
    loading: { loadingStaffRoles },
  } = useQuery(FETCH_PARTNER_STAFF_ROLES_BY_CUSTOMER_ID_QUERY);

  const [didSetDefaultPartnerService, setDidSetDefaultPartnerService] =
    useState(false);

  useEffect(() => {
    if (
      !didSetDefaultPartnerService &&
      partnerServices &&
      partnerServices.length > 0 &&
      partnerStaffRoles &&
      partnerStaffRoles.length > 0
    ) {
      setValues({
        ...values,
        responsiblePartnerServiceId: partnerServices[0].id,
        doneByPartnerServiceId: partnerServices[0].id,
        partnerStaffId: partnerStaffRoles[0].id,
      });
      setDidSetDefaultPartnerService(true);
    }
    // if (errors) {
    //   setErrors({});
    // }
  }, [
    partnerServices,
    values,
    setValues,
    didSetDefaultPartnerService,
    setDidSetDefaultPartnerService,
    partnerStaffRoles,
  ]);

  // const {
  //   data: { getChatIdByQuoteRequestAndStaff: chatId } = "",
  //   loading: { loadingChatId },
  // } = useQuery(FETCH_PARTNER_STAFF_ROLES_BY_CUSTOMER_ID_QUERY);

  const [
    submitQuote,
    {
      loading: { loadingSubmit },
    },
  ] = useMutation(SUBMIT_QUOTE_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
      },
      {
        query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
        variables: { searchTerm: "" },
      },
      {
        query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
        variables: { searchTerm: "" },
      },
      {
        query: FETCH_QUOTE_REQUEST_BY_ID_QUERY,
        variables: { quoteRequestId: quoteRequest.id },
      },
      // { query: FETCH_CHAT_BY_ID_QUERY, variables: { chatId } },
      // {
      //   query: FETCH_MESSAGES_BY_CHAT_QUERY,
      //   variables: { chatId },
      // },
    ],

    update(_, { data: { submitQuote: quoteMessageData } }) {
      console.log("should redirect");
      props.history.push(
        "/chat/" + quoteMessageData.chatId + "/" + quoteMessageData.id
      );
    },
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: { quoteId: quote.id },
  });

  function submitQuoteCallback() {
    submitQuote();
  }

  const {
    data: { getSuggestedInputFieldsByDesc: topLvlSuggestedDetails } = {},
    // error,
    loading: loadingSuggestedDetails,
  } = useQuery(FETCH_SUGGEST_DETAILS_BY_DESC_QUERY, {
    variables: {
      description: values.title + " " + values.shortDescription,
      isTopLevel: true,
      selectedTopLvlInputFieldIds: values.selectedTopLvlInputFieldIds,
    },
  });
  const [prevTopLvlSuggestedDetails, setPrevTopLvlSuggestedDetails] = useState(
    []
  );

  useEffect(() => {
    if (
      topLvlSuggestedDetails &&
      topLvlSuggestedDetails !== prevTopLvlSuggestedDetails
    ) {
      setPrevTopLvlSuggestedDetails(topLvlSuggestedDetails);
    }
  }, [topLvlSuggestedDetails, prevTopLvlSuggestedDetails]);

  return (
    <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
      <div className="px-6 pt-6 md:mb-2 md:p-0">
        <PageHeader
          props={props}
          titleBold={quote.status === 1 ? "Edit quote" : "Write quote"}
          hideHomeButton={true}
          linkMsg={
            fetchedProject &&
            `For ${fetchedProject.title} v${fetchedProject.versionNumber}`
          }
          link={`/quoteRequestDetails/${quoteRequest.id}/${quoteRequest.projectId}`}
        />
      </div>

      <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-4">
        <InputField
          fieldTitle={`Title* ${
            values.title ? `(${values.title.length}/30)` : ""
          }`}
          errorMsg={errors && errors.title}
          placeholder="Task name"
          name="title"
          onChangeFunction={onChange}
          value={values.title}
          type="text"
          keepAutoCompleteOff={true}
          maxLength="30"
        />
        <InputField
          fieldTitle={`Short description* ${
            values.shortDescription
              ? `(${values.shortDescription.length}/100)`
              : ""
          }`}
          errorMsg={errors && errors.shortDescription}
          placeholder="What is this task?"
          name="shortDescription"
          onChangeFunction={onChange}
          value={values.shortDescription}
          type="textarea"
          rows={"2"}
          maxLength="100"
        />
        {partnerServices && partnerServices.length > 0 && (
          <div className="w-full">
            <div className="flex items-center justify-between w-full">
              <h5 className="text-gray-500 md:text-gray-300">Done by*</h5>
              {errors && errors.doneByPartnerServiceId && (
                <h5 className="text-red-500 font-medium text-right">
                  {errors.doneByPartnerServiceId}
                </h5>
              )}
            </div>
            <div className="flex items-center justify-start w-full space-x-2 md:space-x-4 mt-2">
              {partnerServices.map((partnerService, index) => (
                <InputField
                  name="doneByPartnerServiceId"
                  onChangeFunction={onChange}
                  radioValue={partnerService.id}
                  type="radio"
                  value={values.doneByPartnerServiceId}
                  radioTitle={partnerService.businessName}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}
        {partnerStaffRoles && partnerStaffRoles.length > 0 && (
          <div className="w-full">
            <div className="flex items-center justify-between w-full">
              <h5 className="text-gray-500 md:text-gray-300">Your role*</h5>
              {errors && errors.partnerStaffId && (
                <h5 className="text-red-500 font-medium text-right">
                  {errors.partnerStaffId}
                </h5>
              )}
            </div>
            <div className="flex items-center justify-start w-full space-x-2 md:space-x-4 mt-2">
              {partnerStaffRoles.map((partnerStaffRole, index) => (
                <InputField
                  name="partnerStaffId"
                  onChangeFunction={onChange}
                  radioValue={partnerStaffRole.id}
                  type="radio"
                  value={values.partnerStaffId}
                  radioTitle={partnerStaffRole.positionTitle}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col items-start justify-start w-full">
          <InputField
            fieldTitle={`Cost (CA$)*`}
            errorMsg={errors && errors.finalSubTotal}
            placeholder={2000}
            name="finalSubTotal"
            onChangeFunction={onChange}
            value={values.finalSubTotal}
            type="range"
            isMoney={true}
            rangeStep={500}
            rangeMin={1000}
            rangeMax={200000}
          />
          {/* <p className="px-4 py-1 md:bg-white bg-blue-600 font-semibold text-white md:text-black rounded-full">
            CA$
            {getNatural((values.finalSubTotal / 100).toFixed(2))}.
            {getDecimal((values.finalSubTotal / 100).toFixed(2))}
          </p>
        */}
        </div>
        <InputField
          fieldTitle="Deadline*"
          errorMsg={errors && errors.deadline}
          placeholder="Jun 12, 2021"
          name="deadline"
          onChangeFunction={onChange}
          value={values.deadline}
          type="text"
          keepAutoCompleteOff={true}
        />
        {((topLvlSuggestedDetails && topLvlSuggestedDetails.length > 0) ||
          (prevTopLvlSuggestedDetails &&
            prevTopLvlSuggestedDetails.length > 0)) && (
          <div className="flex flex-col items-start justify-start w-full space-y-2">
            <InputTitle
              fieldTitle={"Details (optional)"}
              errorMsg={errors && errors.selectedTopLvlInputFieldIds}
              hint={
                "Add details by clicking one of the options below. Click again to remove that detail."
              }
            />

            <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
              {topLvlSuggestedDetails &&
                topLvlSuggestedDetails.length > 0 &&
                topLvlSuggestedDetails.map((topLvlSuggestedDetail, index) => (
                  <InputField
                    name={"selectedTopLvlInputFieldIds"}
                    onChangeFunction={onArrayChange}
                    checkboxValue={topLvlSuggestedDetail.id}
                    type="checkbox"
                    value={values.selectedTopLvlInputFieldIds}
                    checkboxTitle={topLvlSuggestedDetail.title}
                    key={index}
                  />
                ))}
              {(!topLvlSuggestedDetails ||
                topLvlSuggestedDetails.length === 0) &&
                prevTopLvlSuggestedDetails &&
                prevTopLvlSuggestedDetails.length > 0 &&
                prevTopLvlSuggestedDetails.map(
                  (prevTopLvlSuggestedDetail, index) => (
                    <InputField
                      name={"selectedTopLvlInputFieldIds"}
                      onChangeFunction={onArrayChange}
                      checkboxValue={prevTopLvlSuggestedDetail.id}
                      type="checkbox"
                      value={values.selectedTopLvlInputFieldIds}
                      checkboxTitle={prevTopLvlSuggestedDetail.title}
                      key={index}
                      disabled={true}
                    />
                  )
                )}
            </div>
          </div>
        )}

        {values.selectedTopLvlInputFieldIds &&
          values.selectedTopLvlInputFieldIds.length > 0 &&
          values.selectedTopLvlInputFieldIds.map(
            (topLvlInputFieldId, index) => (
              <InputFieldsForSelectedTopLevelField
                key={index}
                onChange={onChange}
                topLvlInputFieldId={topLvlInputFieldId}
                errors={errors}
                values={values}
                setValues={setValues}
              />
            )
          )}
      </div>

      <div className="flex flex-col items-center justify-center space-y-2 md:space-x-4 mt-6 mb-6 md:mb-0 w-full">
        {orderMilestones && orderMilestones.length > 0 && (
          <QuoteSummaryBox
            orderMilestones={orderMilestones}
            quote={quote}
            setValues={setValues}
            values={values}
          />
        )}

        <div className="flex items-center justify-center space-x-4 w-full">
          <button
            type="button"
            onClick={createOrderMilestoneCallback}
            disabled={
              loading ||
              loadingPartnerServices ||
              loadingStaffRoles ||
              loadingSubmit ||
              loadingSuggestedDetails
            }
            className={`px-4 py-2 ${
              loadingSubmit
                ? "bg-blue-700 hover:bg-blue-500"
                : "bg-green-500 hover:bg-green-700"
            } border-transparent border-2 text-white shadow-lg text-xl sm:text-2xl md:text-xl rounded-full transition-colors focus:outline-none focus:ring-1 flex items-center justify-center space-x-2 leading-none font-semibold`}
          >
            {loading ||
            loadingPartnerServices ||
            loadingStaffRoles ||
            loadingSubmit ||
            loadingSuggestedDetails ? (
              <svg
                className="h-6 animate-spin"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                  stroke={"white"}
                  strokeWidth="2"
                />
                <path
                  d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                  stroke={"white"}
                  strokeWidth="2"
                />
              </svg>
            ) : values.editOrderMilestoneId ? (
              "Save milestone"
            ) : (
              `Add milestone${
                orderMilestones && orderMilestones.length > 0 ? "" : " to quote"
              }`
            )}
          </button>

          {orderMilestones &&
            orderMilestones.length > 0 &&
            !(
              loading ||
              loadingPartnerServices ||
              loadingStaffRoles ||
              loadingSubmit ||
              loadingSuggestedDetails
            ) && (
              <button
                type="button"
                onClick={submitQuoteCallback}
                disabled={
                  loading ||
                  loadingPartnerServices ||
                  loadingStaffRoles ||
                  loadingSubmit ||
                  loadingSuggestedDetails
                }
                className="px-4 py-2 bg-blue-700 hover:bg-blue-500 border-transparent border-2 text-white shadow-lg text-xl sm:text-2xl md:text-xl rounded-full transition-colors focus:outline-none focus:ring flex items-center justify-center space-x-2 leading-none font-semibold"
              >
                Send
              </button>
            )}
        </div>
      </div>
    </div>
  );
}

export const FETCH_PARTNER_SERVICES_BY_STAFF_QUERY = gql`
  query getPartnerServicesByStaff($customerId: String) {
    getPartnerServicesByStaff(customerId: $customerId) {
      id
      businessName
      images
      stripeAccountId
      isStripeAccountLinked
    }
  }
`;

export const FETCH_PARTNER_STAFF_ROLES_BY_CUSTOMER_ID_QUERY = gql`
  query getPartnerStaffRolesByCustomerId($customerId: String) {
    getPartnerStaffRolesByCustomerId(customerId: $customerId) {
      id
      positionTitle
      partnerServiceId
    }
  }
`;

const CREATE_ORDER_MILESTONE = gql`
  mutation createOrderMilestone(
    $editOrderMilestoneId: String
    $quoteId: String!
    $responsiblePartnerServiceId: String!
    $doneByPartnerServiceId: String!
    $partnerStaffId: String!
    $customerId: String
    $finalSubTotal: Int!
    $deadline: String!
    $title: String!
    $shortDescription: String!
    $selectedTopLvlInputFieldIds: [String]
    $inputIdsToUploads: [InputIdToUploadValInput]
    $inputIdsToDates: [InputIdToDateValInput]
    $inputIdsToStrings: [InputIdToStringValInput]
    $inputIdsToInts: [InputIdToIntValInput]
    $inputIdsToFloats: [InputIdToFloatValInput]
    $inputIdsToUploadArrays: [InputIdToUploadArrayValInput]
    $inputIdsToStringArrays: [InputIdToStringArrayValInput]
    $inputIdsToIntArrays: [InputIdToIntArrayValInput]
    $inputIdsToFloatArrays: [InputIdToFloatArrayValInput]
  ) {
    createOrderMilestone(
      editOrderMilestoneId: $editOrderMilestoneId
      quoteId: $quoteId
      responsiblePartnerServiceId: $responsiblePartnerServiceId
      doneByPartnerServiceId: $doneByPartnerServiceId
      partnerStaffId: $partnerStaffId
      customerId: $customerId
      finalSubTotal: $finalSubTotal
      deadline: $deadline
      title: $title
      shortDescription: $shortDescription
      selectedTopLvlInputFieldIds: $selectedTopLvlInputFieldIds
      inputIdsToUploads: $inputIdsToUploads
      inputIdsToDates: $inputIdsToDates
      inputIdsToStrings: $inputIdsToStrings
      inputIdsToInts: $inputIdsToInts
      inputIdsToFloats: $inputIdsToFloats
      inputIdsToUploadArrays: $inputIdsToUploadArrays
      inputIdsToStringArrays: $inputIdsToStringArrays
      inputIdsToIntArrays: $inputIdsToIntArrays
      inputIdsToFloatArrays: $inputIdsToFloatArrays
    ) {
      id
      createdAt
    }
  }
`;

const SUBMIT_QUOTE_MUTATION = gql`
  mutation submitQuote($quoteId: String!) {
    submitQuote(quoteId: $quoteId) {
      id
      chatId
      message
      quoteId
      createdAt
    }
  }
`;

export default AddOrderMilestoneElement;
