import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import HomeButton from "../../components/customer/HomeButton";
import JobRequestBox from "../../components/customer/JobRequestBox";
import NextButton from "../../components/customer/NextButton";
import PageHeader from "../../components/customer/PageHeader";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useWindowSize } from "../../util/hooks";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function FindJobs(props) {
  const location = useLocation();

  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Find jobs | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  if (fetchedCustomer && !fetchedCustomer.isAPartnerStaffMember) {
    props.history.push("/registerPartner");
  }

  const {
    data: { getQuoteRequestsForStaff: fetchedQuoteRequests } = {},
    loading: { loadingQuoteRequests },
    refetch,
  } = useQuery(FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY, {});

  // var quotationDeliveryDate;
  // var naturalNetAmount;
  // var decimalNetAmount;
  // function getNatural(num) {
  //   return num
  //     .toString()
  //     .split(".")[0]
  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }
  // function getDecimal(num) {
  //   return num.toString().split(".")[1];
  // }

  const size = useWindowSize();

  // if (fetchedQuoteRequests) {
  //   console.log(fetchedQuoteRequests);
  // }

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <div className="flex flex-col space-y-2 items-start justify-start">
          <p className="font-thin text-4xl">Keep an eye on</p>
          {loadingCustomer ? (
            <svg
              className="h-6 animate-spin"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          ) : (
            fetchedCustomer &&
            fetchedCustomer.email && (
              <p className="text-4xl font-semibold">{fetchedCustomer.email}</p>
            )
          )}
        </div>

        <SocialsFooter />
      </div>

      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 py-6 md:mb-2 md:p-0 w-full z-10">
          <PageHeader
            props={props}
            titleBold={`Find jobs`}
            // link={"/projectDetails/" + fetchedProject.id}
            // linkMsg={`${fetchedProject ? fetchedProject.title : ""}`}
            caption={
              !loadingQuoteRequests &&
              !loadingCustomer &&
              (!fetchedQuoteRequests || fetchedQuoteRequests.length === 0) &&
              "No quotes are available right now. Click Refresh or come back after a while to find jobs here."
            }
            hideHomeButton={true}
            isWhite={true}
          />
        </div>
        {size.width < 768 && (
          <div className="bg-blue-800 w-full h-1/3 absolute top-0 left-0 right-0"></div>
        )}

        <div className="flex flex-col items-center justify-start w-full h-full pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-4 z-10">
          {fetchedQuoteRequests &&
            fetchedQuoteRequests.length > 0 &&
            fetchedQuoteRequests.map((quoteRequest, index) => (
              <JobRequestBox
                quoteRequest={quoteRequest}
                props={props}
                key={index}
                invertTitleColours={true}
              />
            ))}
          {/* {!loadingQuoteRequests &&
            !loadingCustomer &&
            (!fetchedQuoteRequests || fetchedQuoteRequests.length === 0) && (
              <p className="text-white text-lg md:text-base px-2 md:px-0">
                No quotes are available right now. Click Refresh or come back
                after a while to find jobs here.
              </p>
            )} */}
        </div>
        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <NextButton
            loading={loadingQuoteRequests}
            goToNextStep={(e) => {
              e.preventDefault();
              refetch();
            }}
            title={`Refresh`}
            hideArrow={true}
          />
        </div>
      </div>
    </div>
  );
}

export const FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY = gql`
  query getQuoteRequestsForStaff($customerId: String) {
    getQuoteRequestsForStaff(customerId: $customerId) {
      id
      projectId
      type
      numOfQuotesReceived
      numOfViews
      createdAt
      expiredAt
      numOfRejections
      status
    }
  }
`;

export default FindJobs;
