import { useQuery } from "@apollo/client";
import React from "react";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "./OrderMilestoneMessageBox";

export default function InProgressQuoteCard({
  quote,
  props,
  key,
  isLast,
  isFirst,
}) {
  const { data: { getProjectById: project } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: quote.projectId },
    }
  );
  const {
    data: { getOrderMilestonesByQuote: orderMilestones } = {},
    // loading: { loadingOrderMilestones },
  } = useQuery(FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY, {
    variables: { quoteId: quote.id },
  });

  var mainImageUrl = "";
  if (project) {
    for (let i = 0; i < project.inputIdsToFileUrlArrays.length; ++i) {
      if (project.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8")
        //Images{
        mainImageUrl = project.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return project && orderMilestones ? (
    <div
      className={`bg-gray-100 flex-shrink-0 shadow-xl rounded-xl overflow-hidden flex flex-col items-center justify-center w-full md:w-56 ${
        isLast ? "mb-16 md:mb-0" : ""
      } ${isFirst ? "" : "mt-8 md:mt-0"}`}
    >
      <div className="bg-white p-3 flex flex-col items-start justify-start w-full h-full space-y-1 rounded-b-xl text-center">
        {mainImageUrl && (
          <img
            src={mainImageUrl}
            alt={project.title}
            className="rounded-xl shadow-xl w-full h-32 object-cover"
          />
        )}
        <p className="font-semibold text-3xl truncate w-full px-2">
          {project.title}
        </p>
        <p className="w-full truncate font-medium text-gray-400 px-2">
          {project.shortDescription}
        </p>
      </div>
      <div className="bg-gray-100 p-4 w-full h-full flex items-center justify-between rounded-b-xl overflow-hidden">
        <div className="flex items-center justify-start space-x-2">
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.45704 0.923096L0.820435 2.6856L6.13651 8.42309L0.820435 14.1606L2.45704 15.9231L9.42133 8.42309L2.45704 0.923096Z"
              fill="#9CA3AF"
            />
          </svg>
          <p className="font-semibold">v{project.versionNumber}</p>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            props.history.push("/jobDetails/" + quote.id);
          }}
          className="font-semibold focus:outline-none focus:ring-4 text-white bg-gray-300 hover:bg-gray-700 rounded-full px-4 py-2 transition-colors"
        >
          Details
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}
