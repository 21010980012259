import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import React from "react";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "./OrderMilestoneMessageBox";
import OrderMilestoneTrackBox from "./OrderMilestoneTrackBox";
import { FETCH_PARTNER_SERVICE_BY_ID_QUERY } from "./QuoteQuickDisplayBox";
import TextAndValue from "./TextAndValue";

function QuoteBox({ quote, props }) {
  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: quote.projectId },
    }
  );

  var quotationReceivedAtTime;
  var quotationReceivedAtDay;
  var quoteExprDay;
  var quoteExprTime;
  // var quotationDeliveryDate;
  if (quote.paymentSuccessAt) {
    quotationReceivedAtTime = moment(quote.paymentSuccessAt).format("h:mm");
    quotationReceivedAtDay = moment(quote.paymentSuccessAt).format("D MMM, y");

    // console.log("deadline");
    // console.log(quote.deadline);
    quoteExprTime = moment(quote.deadline).format("h:mm");
    quoteExprDay = moment(quote.deadline).format("D MMM, y");

    // quotationDeliveryDate = moment(quote.paymentSuccessAt)
    //   .add(2, "days")
    //   // .tz("America/Toronto")
    //   .format("MMM D - h:mm a");
  }
  // if(quote.)

  // const { data: { getQuotesByQuoteRequest: quote } = {} } = useQuery(
  //   FETCH_QUOTES_BY_QUOTE_REQUEST_QUERY,
  //   {
  //     variables: { quoteRequestId: quote.quoteRequestId },
  //   }
  // );
  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  const {
    data: { getOrderMilestonesByQuote: orderMilestones } = {},
    // loading: { loadingOrderMilestones },
  } = useQuery(FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY, {
    variables: { quoteId: quote.id },
  });
  const { data: { getPartnerServiceById: fetchedPartnerService } = {} } =
    useQuery(FETCH_PARTNER_SERVICE_BY_ID_QUERY, {
      variables: { partnerServiceId: quote.partnerServiceId },
    });
  console.log(quote);
  return fetchedProject && fetchedPartnerService && quote ? (
    <div className="bg-white md:bg-gray-100 md:bg-opacity-20 rounded-xl w-full shadow-xl">
      <div className="p-2 text-black md:text-white text-center md:text-sm">
        <p className="font-semibold">
          {fetchedProject.title} v{fetchedProject.versionNumber}
        </p>
        <p className="font-medium">
          {quote.type === "design" ? "Design refinement" : "Production"}{" "}
          {quote.status === 4
            ? "complete"
            : quote.status === 5
            ? "refunded"
            : quote.status === 6
            ? "cancelled"
            : quote.status === 7
            ? "expired"
            : "in-progress"}
          {/* for{" "} */}
          {/* <button
            onClick={(e) => {
              e.preventDefault();
              props.history.push("");
            }}
            className="text-blue-100 hover:text-blue-200"
          > */}
          {/* Quote v{quote.versionNumber} */}
          {/* </button> */}
        </p>
        <p className="font-light">
          {fetchedPartnerService.businessName}
          {/* {" "} */}
          {/* {quote.status === 5 ? "has done it" : "is working on it"}! */}
        </p>
      </div>

      <div
        className="rounded-t-xl bg-gray-100 md:bg-opacity-50 flex items-center justify-between p-4 space-x-4"
        style={{ backdropFilter: "blur(40px)" }}
      >
        <div className="flex flex-col items-start justify-start text-sm">
          <p className="font-medium text-blue-500 md:text-blue-100">Sent at</p>
          <p className="text-black md:text-white text-4xl">
            {quotationReceivedAtTime}
          </p>
          <p className="text-blue-600 md:text-gray-200 font-medium">
            {quotationReceivedAtDay}
          </p>
        </div>

        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          className="text-black md:text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.94006 0.5L0.0600586 2.4975L6.16672 9L0.0600586 15.5025L1.94006 17.5L9.94006 9L1.94006 0.5Z" />
        </svg>

        <div className="flex flex-col items-end justify-end text-sm">
          <p className="font-medium text-blue-500 md:text-blue-100">Deadline</p>
          <p className="text-black md:text-white text-4xl">{quoteExprTime}</p>
          <p className="text-blue-600 md:text-gray-200 font-medium">
            {quoteExprDay}
          </p>
        </div>
      </div>

      <div className="bg-white p-2 overflow-hidden grid grid-flow-col">
        <TextAndValue
          text="Committed"
          value={`CA$${getNatural(
            (quote.finalNetAmount / 100).toFixed(2)
          )}.${getDecimal((quote.finalNetAmount / 100).toFixed(2))}`}
        />

        <TextAndValue
          text="Left to release"
          value={`CA$${getNatural(
            (quote.leftToRelease / 100).toFixed(2)
          )}.${getDecimal((quote.leftToRelease / 100).toFixed(2))}`}
        />

        <TextAndValue
          text="Released"
          value={`CA$${getNatural(
            (quote.releasedSoFar / 100).toFixed(2)
          )}.${getDecimal((quote.releasedSoFar / 100).toFixed(2))}`}
        />
      </div>

      <div className="flex items-center justify-center w-full flex-col overflow-y-auto relative rounded-b-xl">
        {orderMilestones && orderMilestones.length > 0 ? (
          orderMilestones.map((orderMilestone, index) => (
            <OrderMilestoneTrackBox
              orderMilestone={orderMilestone}
              key={index}
              props={props}
            />
          ))
        ) : (
          <>
            <img
              src="https://media4.giphy.com/media/3y0oCOkdKKRi0/giphy.gif?cid=ecf05e47yoqlnwowygx09uu82t04xw7b30udk6nwc1rqsdlz&rid=giphy.gif"
              alt="Waiting"
              className="w-full object-cover"
            />
            <p className="text-white font-semibold absolute left-0 top-0 mx-2 my-2">
              No quotes yet, please wait
            </p>
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}

export const FETCH_QUOTES_BY_QUOTE_REQUEST_QUERY = gql`
  query getQuotesByQuoteRequest($quoteRequestId: String!) {
    getQuotesByQuoteRequest(quoteRequestId: $quoteRequestId) {
      id
      type
      projectId
      customerId
      quoteRequestId
      partnerServiceId
      partnerStaffId
      staffCustomerId
      allQuoteVersionIds
      versionNumber
      notificationReceiverIds
      customerDiscountUsed
      finalSubTotal
      finalDiscount
      finalRefundAmount
      finalNetAmount
      releasedSoFar
      leftToRelease
      currency
      paymentIntentId
      paymentIntentClientSecret
      status
      deadline
      createdAt
      verificationSentAt
      paymentSuccessAt
      quoteSentAt
      expiredAt
      refundedAt
    }
  }
`;

export const FETCH_MESSAGE_BY_ORDER_MILESTONE_QUERY = gql`
  query getMessageByOrderMilestone($orderMilestoneId: String!) {
    getMessageByOrderMilestone(orderMilestoneId: $orderMilestoneId) {
      id
      chatId
    }
  }
`;
export default QuoteBox;
