import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

export default function ShareSoftwair({
  customer,
  loadingCustomer,
  title,
  showPartnerIncentive,
  isDarkMode,
}) {
  function setIsCopyCallback() {
    setIsCopied(true);
  }

  const [isCopied, setIsCopied] = useState(false);

  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isCopied && resetInterval) {
      timeout = setTimeout(() => setIsCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);
  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  var naturalCustomerDiscount;
  var decimalCustomerDiscount;

  if (customer) {
    naturalCustomerDiscount = getNatural((customer.discount / 100).toFixed(2));
    decimalCustomerDiscount = getDecimal((customer.discount / 100).toFixed(2));
  }
  return (
    <div
      className={`${
        isDarkMode ? "text-white md:hidden" : "text-black"
      } transition-colors flex flex-col items-start justify-start space-y-2 md:space-y-6`}
    >
      <p className="hidden md:block font-thin text-2xl md:text-4xl">{title}</p>
      <div className="flex space-x-4 items-center justify-start">
        {loadingCustomer ? (
          <svg
            className="h-6 animate-spin"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
              stroke="black"
              strokeWidth="2"
            />
            <path
              d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        ) : (
          customer &&
          customer.referralCode && (
            <>
              <p
                className={`${
                  isCopied ? "text-blue-400" : ""
                } text-3xl lg:text-4xl font-semibold`}
              >
                Invite code: {customer.referralCode}
              </p>
              {isCopied ? (
                <svg
                  viewBox="0 0 30 30"
                  className={`${
                    isCopied ? "text-blue-400" : ""
                  } fill-current h-7`}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.9937 19.8438L5.78124 14.6313L4.00624 16.3938L10.9937 23.3813L25.9937 8.38127L24.2312 6.61877L10.9937 19.8438Z" />
                </svg>
              ) : (
                <CopyToClipboard
                  text={"https://softwair.tech/signup/" + customer.referralCode}
                  onCopy={setIsCopyCallback}
                >
                  <button
                    className={`${
                      isCopied ? "text-blue-400" : "hover:text-gray-500"
                    }  focus:outline-none`}
                  >
                    <svg
                      className="fill-current h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7.03 2.6a3 3 0 0 1 5.94 0L15 3v1h1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1V3l2.03-.4zM5 6H4v12h12V6h-1v1H5V6zm5-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>
                  </button>
                </CopyToClipboard>
              )}
            </>
          )
        )}
      </div>
      {customer && (
        <p className="text-2xl md:text-4xl font-thin">1 invite = CA$10 off</p>
      )}
      {showPartnerIncentive && (
        <p className="text-2xl md:text-4xl font-thin hidden md:block">
          20 invites = 1 free month of Softwair partnership
        </p>
      )}
      {loadingCustomer ? (
        <svg
          className="h-6 animate-spin"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
            stroke="black"
            strokeWidth="2"
          />
        </svg>
      ) : (
        customer && (
          <>
            {customer.discount !== undefined && (
              <div className="flex items-center justify-start space-x-4">
                <p className="font-semibold text-2xl md:text-4xl text-gray-400">
                  Available discount:
                </p>
                <p className="font-semibold text-2xl md:text-4xl">
                  {customer.discount === 0
                    ? 0
                    : `CA$${naturalCustomerDiscount}.${decimalCustomerDiscount}`}
                </p>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
}
