import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import NextButton from "../../components/customer/NextButton";
import PageHeader from "../../components/customer/PageHeader";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function PartnerServiceRegistered(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Partner Registration Complete | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const partnerServiceId = props.match.params.partnerServiceId;

  const {
    data: { getPartnerServiceById: fetchedPartnerService } = {},
    loading: { loadingPartnerService },
  } = useQuery(FETCH_PARTNER_SERVICE_BY_ID_QUERY, {
    variables: { partnerServiceId },
  });

  var naturalNetAmount;
  var decimalNetAmount;
  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  if (fetchedPartnerService) {
    if (fetchedPartnerService.paymentSuccessAt) {
      naturalNetAmount = getNatural(
        (fetchedPartnerService.finalNetAmount / 100).toFixed(2)
      );
      decimalNetAmount = getDecimal(
        (fetchedPartnerService.finalNetAmount / 100).toFixed(2)
      );
    }
  }

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <div className="flex flex-col space-y-2 items-start justify-start">
          <p className="font-thin text-4xl">Keep an eye on</p>
          {loadingPartnerService ? (
            <svg
              className="h-6 animate-spin"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          ) : (
            fetchedPartnerService &&
            fetchedPartnerService.businessEmail && (
              <p className="text-4xl font-semibold">
                {fetchedPartnerService.businessEmail}
              </p>
            )
          )}
        </div>
        <SocialsFooter />
      </div>

      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <PageHeader
          props={props}
          titleBold="Partner registered"
          caption={
            fetchedPartnerService &&
            fetchedPartnerService.businessName +
              " will now start receiving quotation requests."
          }
          hideHomeButton={true}
          padTop={true}
        />

        <div className="flex flex-col space-y-4 items-start justify-start w-full overflow-y-auto px-6 md:p-0">
          {fetchedPartnerService && (
            <>
              <InputField
                value={fetchedPartnerService.businessName}
                fieldTitle="Business name"
                disabled={true}
                type="text"
              />
              {fetchedPartnerService.images &&
                fetchedPartnerService.images.length > 0 && (
                  <img
                    alt={fetchedPartnerService.title}
                    src={fetchedPartnerService.images[0]}
                    className="w-full h-48 rounded-xl object-cover shadow-lg"
                  />
                )}

              <InputField
                fieldTitle="Amount paid"
                value={`CA$${naturalNetAmount}.${decimalNetAmount}`}
                type="text"
                disabled={true}
              />
              {fetchedPartnerService.message && (
                <p className="text-4xl md:text-3xl lg:text-4xl bg-transparent text-white w-full">
                  {fetchedPartnerService.message}
                </p>
              )}

              {fetchedPartnerService.servicesProvided && (
                <div className="flex items-center justify-start space-x-2 w-full">
                  {fetchedPartnerService.servicesProvided
                    .split(",")
                    .map((keyword, i) => (
                      <span
                        key={`${i}`}
                        className="px-2 m-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                      >
                        {keyword}
                      </span>
                    ))}
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <NextButton
            loading={loadingPartnerService}
            goToNextStep={(e) => {
              e.preventDefault();
              props.history.push("/jobs");
            }}
            title="Go to jobs"
            hideArrow={true}
          />
        </div>
      </div>
    </div>
  );
}

export const FETCH_PARTNER_SERVICE_BY_ID_QUERY = gql`
  query getPartnerServiceById($partnerServiceId: String!) {
    getPartnerServiceById(partnerServiceId: $partnerServiceId) {
      id
      businessName
      businessEmail
      finalNetAmount
      paymentSuccessAt
      images
    }
  }
`;

export default PartnerServiceRegistered;
