import { gql, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import CheckButton from "../../components/customer/CheckButton";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import PageHeader from "../../components/customer/PageHeader";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { useForm } from "../../util/hooks";

function LoginCustomer(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Login | Softwair";
  }, []);
  const context = useContext(AuthContext);

  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(loginCustomerCallback, {
    email: "",

    password: "",
  });

  const [loginCustomer, { loading }] = useMutation(LOGIN_CUSTOMER, {
    update(_, { data: { loginCustomer: customerData } }) {
      context.loginCustomer(customerData);

      props.history.push("/projects");
    },
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function loginCustomerCallback() {
    console.log("hihihihi");
    loginCustomer();
  }

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <div className="flex flex-col space-y-4">
          <p className="font-thin text-4xl">Upload projects/ideas</p>
          <p className="text-gray-500 italic">
            Get quotes from professionals and enterprises to
          </p>
          <p className="text-4xl">Refine designs</p>
          <p className="text-gray-500 italic">
            Get quotes from professionals and enterprises to
          </p>
          <p className="text-4xl font-semibold">Build and ship products</p>
        </div>
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start p-6 overflow-hidden">
        <div className="md:mb-2 md:p-0">
          <PageHeader props={props} titleBold="Login" />
        </div>
        <form onSubmit={onSubmit} className="w-full">
          <div className="flex flex-col space-y-2 items-start justify-start w-full">
            <InputField
              fieldTitle="Email*"
              errorMsg={errors && errors.email}
              placeholder="user@email.com"
              name="email"
              onChangeFunction={onChange}
              value={values.email}
              type="email"
            />

            <InputField
              fieldTitle="Password*"
              errorMsg={errors && errors.password}
              placeholder="●●●●●"
              name="password"
              onChangeFunction={onChange}
              value={values.password}
              type="password"
            />
          </div>
          <div
            className={`flex flex-col items-center justify-center md:justify-start md:items-start w-full mt-6`}
          >
            <p className="text-gray-500 md:text-gray-300 text-sm mb-2">
              Don't have an account?{" "}
              <button
                className="text-blue-600 md:text-blue-400 font-semibold"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/signup");
                }}
                type="button"
              >
                Sign up instead
              </button>
            </p>
            <CheckButton loading={loading} />
          </div>
        </form>
      </div>
    </div>
  );
}

const LOGIN_CUSTOMER = gql`
  mutation loginCustomer($email: String!, $password: String!) {
    loginCustomer(
      email: $email

      password: $password
    ) {
      id
      email
      firstName
      lastName
      phone
      createdAt
      token
    }
  }
`;

export default LoginCustomer;
