import { gql, useMutation, useQuery } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_CUSTOMER_QUERY } from "../../pages/customer/Settings";
import {
  FETCH_QUOTES_BY_PROJECT_QUERY,
  FETCH_QUOTE_REQUESTS_BY_PROJECT_QUERY,
} from "../../pages/customer/TrackProject";
import { useForm } from "../../util/hooks";
import CardSection from "./CardSection";
import CheckButton from "./CheckButton";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import HomeButton from "./HomeButton";
import MilestoneDetailsDropdown from "./MilestoneDetailsDropdown";
import NewCardInputSection from "./NewCardInputSection";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "./OrderMilestoneMessageBox";
import PageHeader from "./PageHeader";
import { FETCH_QUOTE_BY_ID_QUERY } from "./QuoteMessageBox";
import ShareSoftwair from "./ShareSoftwair";
import SocialsFooter from "./SocialsFooter";

function AcceptQuoteElement({ props }) {
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const quoteId = props.match.params.quoteId;
  const { data: { getQuoteById: fetchedQuote } = {}, loading } = useQuery(
    FETCH_QUOTE_BY_ID_QUERY,
    {
      variables: { quoteId },
    }
  );

  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  var projectId = "";
  // var quoteRequestId = "";
  if (fetchedQuote) {
    projectId = fetchedQuote.projectId;
    // quoteRequestId = fetchedQuote.quoteRequestId;
  }

  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: projectId },
    }
  );
  const {
    data: { getOrderMilestonesByQuote: orderMilestones } = {},
    loading: { loadingOrderMilestones },
  } = useQuery(FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY, {
    variables: { quoteId },
  });

  const stripe = useStripe();
  const elements = useElements();

  const [errors, setErrors] = useState({});

  const {
    data: { getCustomerPaymentMethodDetails: paymentMethodDetails } = {},
    loading: { loadingPaymentMethodDetails },
    // error: errorPaymentMethods,
  } = useQuery(FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS);
  const { values, setValues } = useForm(acceptAndCommitQuoteCallback, {
    quoteId,
    useNewCard: false,
  });

  const [acceptAndCommitQuote, { loading: loadingAcceptQuote }] = useMutation(
    ACCEPT_QUOTE_MUTATION,
    {
      // refetchQueries: [
      //   {
      //     query: FETCH_QUOTE_REQUESTS_BY_PROJECT_QUERY,
      //     variables: { projectId },
      //   },
      //   {
      //     query:FETCH_QUOTES_BY_PROJECT_QUERY,
      //     variables: { projectId },
      //   },
      //   {query:FETCH_PROJECT_BY_ID_QUERY,
      //     variables: { projectId },
      //   }
      // ],
      async update(_, { data: { acceptAndCommitQuote: quoteData } }) {
        if (values.useNewCard) {
          const result = await stripe.confirmCardPayment(
            quoteData.paymentIntentClientSecret,
            {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            }
          );

          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            setErrors({
              ...errors,
              paymentError: result.error.message,
            });
          } else {
            switch (result.paymentIntent.status) {
              case "requires_capture":
                // update status of checkout to requires capture
                // update orders to begin fulfillment
                //redirect to success page
                processQuotePaymentResult();
                break;
              case "requires_action":
              case "requires_source_action":
                console.log("Payment requires action");
                setErrors({
                  ...errors,
                  paymentError: "Payment requires action",
                });
                break;
              case "requires_payment_method":
              case "requires_source":
                console.log("Card Denied");
                setErrors({
                  ...errors,
                  paymentError:
                    "Your card was denied, please provide a new payment method",
                });
                break;
              case "succeeded":
                console.log("💰 Payment received?");
                // throw new UserInputError(
                //   "Payment should not have gone through since we are just placing a hold",
                // setErrors({
                //   ...errors,
                //   paymentError:
                //     "Your payment was received even though we were just trying to place a hold on your card",
                // });
                processQuotePaymentResult();
                break;
              default:
                break;
            }
          }
        } else {
          processQuotePaymentResult();
        }
      },
      onError(err) {
        setLoadingWholePayment(false);
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: values,
    }
  );

  function acceptAndCommitQuoteCallback() {
    setLoadingWholePayment(true);
    acceptAndCommitQuote();
  }

  const [processQuotePaymentResult, { loading: loadingProcessQuotePayment }] =
    useMutation(PROCESS_QUOTE_PAYMENT_RESULT_MUTATION, {
      refetchQueries: [
        { query: FETCH_CUSTOMER_QUERY },

        {
          query: FETCH_QUOTE_REQUESTS_BY_PROJECT_QUERY,
          variables: { projectId },
        },
        {
          query: FETCH_QUOTES_BY_PROJECT_QUERY,
          variables: { projectId },
        },
        { query: FETCH_PROJECT_BY_ID_QUERY, variables: { projectId } },
      ],
      update(proxy, { data: { processQuotePaymentResult: quoteData } }) {
        setLoadingWholePayment(false);
        setErrors({});
        if (quoteData.status === 3) {
          props.history.push(`/trackProject/${projectId}`);
        }
      },
      onError(err) {
        setLoadingWholePayment(false);
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: { quoteId },
    });

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setErrors({});

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoadingWholePayment(true);

    // add addresses to orders/make sure they have addresses
    acceptAndCommitQuote();
  };

  function toggleCardOpen() {
    console.log("you");
    setValues({
      ...values,
      useNewCard: !values.useNewCard,
    });
  }

  if (
    paymentMethodDetails &&
    paymentMethodDetails.length === 0 &&
    !values.useNewCard
  ) {
    console.log(paymentMethodDetails);
    setValues({
      ...values,
      useNewCard: true,
    });
  }

  const [loadingWholePayment, setLoadingWholePayment] = useState(false);
  const [naturalAmount, setNaturalAmount] = useState("5");
  const [decimalAmount, setDecimalAmount] = useState("00");
  const [naturalNetAmount, setNetNaturalAmount] = useState("5");
  const [decimalNetAmount, setNetDecimalAmount] = useState("00");
  const [naturalCustomerDiscount, setNaturalCustomerDiscount] = useState("00");
  const [decimalCustomerDiscount, setDecimalCustomerDiscount] = useState("00");

  const [discountApplied, setDiscountApplied] = useState(0);
  useEffect(() => {
    function getNatural(num) {
      return num
        .toString()
        .split(".")[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function getDecimal(num) {
      return num.toString().split(".")[1];
    }
    if (fetchedQuote && fetchedCustomer) {
      if (fetchedCustomer.discount > 0) {
        var tempDiscountValue = 0;
        tempDiscountValue = Math.min(
          fetchedCustomer.discount,
          fetchedQuote.finalSubTotal
        );

        if (fetchedQuote.finalSubTotal - discountApplied < 50) {
          tempDiscountValue = fetchedQuote.finalSubTotal;
        }
        setDiscountApplied(tempDiscountValue);
      }
      setNaturalCustomerDiscount(
        getNatural((discountApplied / 100).toFixed(2))
      );
      setDecimalCustomerDiscount(
        getDecimal((discountApplied / 100).toFixed(2))
      );

      var naturalAmountNew = getNatural(
        (fetchedQuote.finalSubTotal / 100).toFixed(2)
      );
      var decimalAmountNew = getDecimal(
        (fetchedQuote.finalSubTotal / 100).toFixed(2)
      );
      setNaturalAmount(naturalAmountNew);
      setDecimalAmount(decimalAmountNew);

      setNetNaturalAmount(
        getNatural(
          ((fetchedQuote.finalSubTotal - discountApplied) / 100).toFixed(2)
        )
      );
      setNetDecimalAmount(
        getDecimal(
          ((fetchedQuote.finalSubTotal - discountApplied) / 100).toFixed(2)
        )
      );
    }
  }, [fetchedCustomer, discountApplied, values, fetchedQuote]);

  if (
    !loading &&
    !loadingAcceptQuote &&
    !loadingCustomer &&
    !loadingOrderMilestones &&
    !loadingPaymentMethodDetails &&
    !loadingProcessQuotePayment &&
    !loadingWholePayment &&
    (!fetchedQuote || fetchedQuote.status !== 1)
  ) {
    props.history.push("/projects");
  }

  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }
  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={false}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        {/* {curStep === 1 ? ( */}
        <div className="px-6 py-6 md:mb-2 md:p-0 w-full z-10">
          <PageHeader
            props={props}
            titleBold={`Accept quote`}
            caption="And commit order amount."
            hideHomeButton={true}
          />
        </div>

        {paymentMethodDetails && fetchedQuote && fetchedProject && (
          <div className="flex flex-col space-y-4 items-start justify-start w-full overflow-y-auto h-full px-4 md:p-0 flex-1 z-10">
            <div className="bg-white rounded-xl w-full p-4 flex flex-col justify-center items-center space-y-2">
              <div className="flex flex-col md:flex-row items-center justify-center md:justify-between md:space-y-0 space-y-2 md:space-x-4 w-full">
                <div className="flex items-start justify-start flex-col flex-shrink-0 w-full md:w-auto">
                  <p className="font-light text-gray-500 w-full">
                    {fetchedProject.title} v{fetchedProject.versionNumber}
                  </p>
                  <p className="text-2xl font-semibold w-full">
                    Quote v{fetchedQuote.versionNumber}
                  </p>
                </div>
                {mainImageUrl && (
                  <img
                    src={mainImageUrl}
                    alt={fetchedProject.title}
                    className="w-full h-16 md:h-12 object-cover rounded-lg overflow-hidden"
                  />
                )}
              </div>

              <div className="w-full flex flex-col items-center justify-center">
                {orderMilestones &&
                  orderMilestones.length > 0 &&
                  orderMilestones.map((orderMilestone, index) => (
                    <MilestoneDetailsDropdown
                      key={index}
                      getDecimal={getDecimal}
                      getNatural={getNatural}
                      orderMilestone={orderMilestone}
                    />
                  ))}
              </div>
              {fetchedQuote && (
                <div
                  className={`flex items-center justify-between w-full focus:outline-none text-black border-t-2 border-black`}
                >
                  <p className="font-semibold truncate">Total</p>

                  <div className="flex flex-col items-end justify-end text-right leading-tight">
                    <p className="font-semibold">
                      CA$
                      {getNatural(
                        (fetchedQuote.finalNetAmount / 100).toFixed(2)
                      )}
                      .
                      {getDecimal(
                        (fetchedQuote.finalNetAmount / 100).toFixed(2)
                      )}
                    </p>
                    <p className="">
                      By {moment(fetchedQuote.deadline).format("D MMM, YYYY")}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex items-center justify-between w-full">
                <h5 className="md:text-gray-300 text-gray-500">Total (CA$)</h5>
                {errors && errors.amount && (
                  <h5 className="text-red-500 font-medium text-right">
                    {errors.amount}
                  </h5>
                )}
              </div>

              <p className="text-4xl md:text-3xl lg:text-4xl bg-transparent md:text-white w-full font-medium">
                {naturalAmount}.{decimalAmount}{" "}
                {discountApplied > 0 && (
                  <span>
                    - {naturalCustomerDiscount}.{decimalCustomerDiscount} ={" "}
                    <span className="text-yellow-500">
                      {naturalNetAmount}.{decimalNetAmount}
                    </span>
                  </span>
                )}
              </p>
            </div>

            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <div className="flex items-center justify-between w-full">
                <h5 className="md:text-gray-300 text-gray-500">
                  Card details*
                </h5>
                {errors && errors.paymentError && (
                  <h5 className="text-red-500 font-medium text-right">
                    {errors.paymentError}
                  </h5>
                )}
                {paymentMethodDetails &&
                  paymentMethodDetails.length > 0 &&
                  (!values.useNewCard ? (
                    <button
                      onClick={toggleCardOpen}
                      className="text-blue-400 hover:text-blue-600 focus:outline-none font-medium text-right"
                    >
                      Use A New Card
                    </button>
                  ) : (
                    <button
                      onClick={toggleCardOpen}
                      className="text-blue-400 hover:text-blue-600 focus:outline-none font-medium text-right"
                    >
                      Choose from Cards
                    </button>
                  ))}
              </div>

              <div className="text-4xl md:text-3xl lg:text-4xl bg-transparent md:text-white w-full flex-grow-0">
                {!values.useNewCard &&
                paymentMethodDetails &&
                paymentMethodDetails.length > 0 ? (
                  <CardSection
                    paymentMethodDetails={paymentMethodDetails}
                    toggleCardOpen={toggleCardOpen}
                    customerId={fetchedCustomer.id}
                  />
                ) : (
                  <NewCardInputSection />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <CheckButton
            btnFunction={handleSubmit}
            loading={
              loading ||
              !stripe ||
              loadingPaymentMethodDetails ||
              loadingCustomer ||
              loadingProcessQuotePayment ||
              loadingWholePayment ||
              loadingAcceptQuote ||
              loadingOrderMilestones
            }
            type={"button"}
          />
        </div>
      </div>
    </div>
  );
}

const ACCEPT_QUOTE_MUTATION = gql`
  mutation acceptAndCommitQuote($quoteId: String!, $useNewCard: Boolean!) {
    acceptAndCommitQuote(quoteId: $quoteId, useNewCard: $useNewCard) {
      id
      paymentIntentClientSecret
    }
  }
`;

export const FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS = gql`
  query getCustomerPaymentMethodDetails {
    getCustomerPaymentMethodDetails {
      stripePaymentMethodId
      brand
      expMonth
      expYear
      last4
      name
      isPrimaryPaymentMethod
    }
  }
`;

const PROCESS_QUOTE_PAYMENT_RESULT_MUTATION = gql`
  mutation processQuotePaymentResult($quoteId: String!) {
    processQuotePaymentResult(quoteId: $quoteId) {
      id
      status
    }
  }
`;
export default AcceptQuoteElement;
