import { useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import NextButton from "../../components/customer/NextButton";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

export default function Explore(props) {
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const location = useLocation();

  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Explore | Softwair";
  }, []);

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  return (
    <div className="flex  bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black  space-y-4 flex-col items-center justify-center w-full h-full min-h-screen p-4">
      <p className="text-center font-semibold text-gray-700 md:text-gray-300 text-2xl">
        This feature is coming soon
      </p>
      <NextButton
        goToNextStep={(e) => {
          e.preventDefault();
          props.history.push("/messages");
        }}
        hideArrow={true}
        title="Back to messages"
      />
    </div>
  );
}
