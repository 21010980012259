import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import Footer from "../../components/customer/Footer";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import NextButton from "../../components/customer/NextButton";
import PageHeader from "../../components/customer/PageHeader";
import ProjectCard from "../../components/customer/ProjectCard";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useWindowSize } from "../../util/hooks";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function Projects(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Projects | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const [errors] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: { getProjectsByCustomer: fetchedProjects } = {},
    // refetch,
    loading: loadingProjects,
  } = useQuery(FETCH_PROJECTS_BY_CUSTOMER_QUERY, {
    variables: { searchTerm },
  });

  const onChangeText = (e) => {
    // [event.target.name]: event.target.value
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const clearTextFunction = (e) => {
    e.preventDefault();
    setSearchTerm("");
  };

  const size = useWindowSize();

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start overflow-hidden md:p-6">
        <div className="px-6 pt-6 md:mb-2 md:p-0">
          <PageHeader
            props={props}
            titleBold={
              searchTerm || (fetchedProjects && fetchedProjects.length > 0)
                ? ""
                : "Projects"
            }
            caption={
              searchTerm || (fetchedProjects && fetchedProjects.length > 0)
                ? ""
                : `Click ${
                    size.width >= 768 ? "the button" : "+"
                  } below to get started.`
            }
          />
          {size.width >= 768 && (
            <ShareSoftwair
              isDarkMode={true}
              title="Share Softwair"
              loadingCustomer={loadingCustomer}
              customer={fetchedCustomer}
              showPartnerIncentive={
                fetchedCustomer && fetchedCustomer.isAPartner
              }
            />
          )}
          {(searchTerm || (fetchedProjects && fetchedProjects.length > 0)) && (
            <InputField
              fieldTitle="Search"
              errorMsg={errors && errors.searchTerm}
              placeholder="Your projects"
              name="searchTerm"
              onChangeFunction={onChangeText}
              value={searchTerm}
              type="text"
              clearBtnText={"Clear"}
              clearTextFunction={clearTextFunction}
            />
          )}
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 md:mb-4 flex-1 overflow-y-auto space-y-4">
          {fetchedProjects &&
            fetchedProjects.length > 0 &&
            fetchedProjects.map((project, index) => (
              <ProjectCard
                project={project}
                props={props}
                key={project.id}
                isLast={index === fetchedProjects.length - 1}
                isFirst={index === 0}
              />
            ))}
        </div>
        <div className="hidden md:block">
          {(fetchedProjects && fetchedProjects.length > 0) ||
          loadingCustomer ||
          loadingProjects ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                props.history.push("/createProject");
              }}
              disabled={loadingCustomer || loadingProjects}
              type={"button"}
              className="w-min p-3 shadow-xl hover:bg-gray-300 bg-white rounded-full transition-colors focus:outline-none focus:ring-1 flex items-center justify-center space-x-2 leading-none font-semibold"
            >
              {loadingCustomer || loadingProjects ? (
                <svg
                  className="h-6 animate-spin"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                    stroke="black"
                    strokeWidth="2"
                  />
                  <path
                    d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                    stroke="black"
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                <svg
                  className="fill-current text-black"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23.1376 15.831H15.831V23.1375H13.3955V15.831H6.08887V13.3954H13.3955V6.08884H15.831V13.3954H23.1376V15.831Z" />
                </svg>
              )}
            </button>
          ) : (
            <NextButton
              title="Add project"
              hideArrow={true}
              goToNextStep={(e) => {
                e.preventDefault();
                props.history.push("/createProject");
              }}
              loading={loadingCustomer || loadingProjects}
              type="button"
            />
          )}
        </div>
        <Footer
          loading={loadingCustomer || loadingProjects}
          activePage="projects"
          props={props}
          pulse={!fetchedProjects || fetchedProjects.length === 0}
          buttonFunction={(e) => {
            e.preventDefault();
            props.history.push("/createProject");
          }}
        />
      </div>
    </div>
  );
}

export const FETCH_PROJECTS_BY_CUSTOMER_QUERY = gql`
  query getProjectsByCustomer($customerId: String, $searchTerm: String) {
    getProjectsByCustomer(customerId: $customerId, searchTerm: $searchTerm) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export default Projects;
