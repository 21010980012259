import { gql, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
// const validator = require("validator");
// const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import CheckButton from "../../components/customer/CheckButton";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
// import NextButton from "../../components/customer/NextButton";
import PageHeader from "../../components/customer/PageHeader";
import SocialsFooter from "../../components/customer/SocialsFooter";
// import PreviousButton from "../../components/customer/PreviousButton";
import { AuthContext } from "../../context/auth";
// import { customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";
// import { FETCH_CUSTOMER_QUERY } from "./Settings";

function RegisterCustomer(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Sign up | Softwair";
  }, []);
  const context = useContext(AuthContext);

  const inviteCode = props.match.params.referralCode;
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(registerCustomerCallback, {
    firstName: "",
    lastName: "",
    email: "",

    password: "",
    confirmPassword: "",

    phone: "",
    city: "",
    region: "",
    country: "",
    line1: "",
    line2: "",
    areaCode: "",
    inviteCode: inviteCode || "",
    signedUpOn: "tech",
  });

  const [registerCustomer, { loading }] = useMutation(REGISTER_CUSTOMER, {
    update(_, { data: { registerCustomer: customerData } }) {
      context.loginCustomer(customerData);

      props.history.push("/projects");
    },
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function registerCustomerCallback() {
    registerCustomer();
  }
  // const [curStep, setCurStep] = useState(1);

  const [showLine2, setShowLine2] = useState(false);
  function toggleLine2(e) {
    e.preventDefault();
    if (!showLine2 === false) {
      setValues({
        ...values,
        line2: "",
      });
    }
    setShowLine2(!showLine2);
  }
  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <div className="flex flex-col space-y-4">
          <p className="font-thin text-4xl">Upload projects/ideas</p>
          <p className="text-gray-500 italic">
            Get quotes from professionals and enterprises to
          </p>
          <p className="text-4xl">Refine designs</p>
          <p className="text-gray-500 italic">
            Get quotes from professionals and enterprises to
          </p>
          <p className="text-4xl font-semibold">Build and ship products</p>
        </div>
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start p-6 overflow-hidden">
        <div className="md:mb-2 md:p-0">
          <PageHeader props={props} titleBold="Sign up" />
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-2">
          <InputField
            fieldTitle="Email*"
            errorMsg={errors && errors.email}
            placeholder="user@email.com"
            name="email"
            onChangeFunction={onChange}
            value={values.email}
            type="email"
          />
          <InputField
            fieldTitle="First name*"
            errorMsg={errors && errors.firstName}
            placeholder="Cool"
            name="firstName"
            onChangeFunction={onChange}
            value={values.firstName}
            type="text"
          />
          <InputField
            fieldTitle="Last name*"
            errorMsg={errors && errors.lastName}
            placeholder="Person"
            name="lastName"
            onChangeFunction={onChange}
            value={values.lastName}
            type="text"
          />

          <InputField
            fieldTitle="Phone (with region code)*"
            errorMsg={errors && errors.phone}
            placeholder="+1 416-647-9050"
            name="phone"
            onChangeFunction={onChange}
            value={values.phone}
            type="tel"
            // hint="With country code."
          />

          <InputField
            fieldTitle="Address line 1*"
            errorMsg={errors && errors.line1}
            placeholder="416 Boulevard"
            name="line1"
            onChangeFunction={onChange}
            value={values.line1}
            type="text"
            additionalBtnText={"Add line 2"}
            additionalBtnFunction={toggleLine2}
          />

          {showLine2 && (
            <InputField
              fieldTitle="Address line 2 (optional)"
              errorMsg={errors && errors.line2}
              placeholder="Apt #905"
              name="line2"
              onChangeFunction={onChange}
              value={values.line2}
              type="text"
            />
          )}

          <InputField
            fieldTitle="Area code*"
            errorMsg={errors && errors.areaCode}
            placeholder="L5M2J3"
            name="areaCode"
            onChangeFunction={onChange}
            value={values.areaCode}
            type="text"
          />

          <InputField
            fieldTitle="City*"
            errorMsg={errors && errors.city}
            placeholder="Toronto"
            name="city"
            onChangeFunction={onChange}
            value={values.city}
            type="text"
          />
          <InputField
            fieldTitle="Region*"
            errorMsg={errors && errors.region}
            placeholder="Ontario"
            name="region"
            onChangeFunction={onChange}
            value={values.region}
            type="text"
          />
          <InputField
            fieldTitle="Country*"
            errorMsg={errors && errors.country}
            placeholder="Canada"
            name="country"
            onChangeFunction={onChange}
            value={values.country}
            type="text"
          />

          <InputField
            fieldTitle="Invite code (optional)"
            errorMsg={
              errors &&
              (errors.inviteCode || errors.email) &&
              (errors.email ? errors.email : errors.inviteCode)
            }
            placeholder="XYZYTOS"
            name="inviteCode"
            onChangeFunction={onChange}
            value={values.inviteCode}
            type="text"
          />
          <InputField
            fieldTitle="Password*"
            errorMsg={errors && errors.password}
            placeholder="●●●●●"
            name="password"
            onChangeFunction={onChange}
            value={values.password}
            type="password"
          />
          <InputField
            fieldTitle="Confirm password*"
            errorMsg={errors && errors.confirmPassword}
            placeholder="●●●●●"
            name="confirmPassword"
            onChangeFunction={onChange}
            value={values.confirmPassword}
            type="password"
          />
        </div>
        <div
          className={`flex flex-col items-center justify-center md:justify-start md:items-start w-full mt-6`}
        >
          <p className="text-gray-500 md:text-gray-300 text-sm mb-2">
            Have an account already?{" "}
            <button
              className="text-blue-600 md:text-blue-400 font-semibold"
              onClick={(e) => {
                e.preventDefault();
                props.history.push("/login");
              }}
              type="button"
            >
              Login instead
            </button>
          </p>

          <CheckButton
            loading={loading}
            type="button"
            btnFunction={registerCustomerCallback}
          />
        </div>
      </div>
    </div>
  );
}

const REGISTER_CUSTOMER = gql`
  mutation registerCustomer(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $city: String!
    $region: String!
    $country: String!
    $line1: String!
    $line2: String
    $areaCode: String!
    $password: String!
    $confirmPassword: String!
    $inviteCode: String
    $signedUpOn: String!
  ) {
    registerCustomer(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      city: $city
      region: $region
      country: $country
      line1: $line1
      line2: $line2
      areaCode: $areaCode
      password: $password
      confirmPassword: $confirmPassword
      inviteCode: $inviteCode
      signedUpOn: $signedUpOn
    ) {
      id
      email
      firstName
      lastName
      phone
      createdAt
      token
    }
  }
`;

export default RegisterCustomer;
