import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import InputFieldsForSelectedTopLevelField from "../../components/customer/InputFieldsForSelectedTopLevelField";
import InputTitle from "../../components/customer/InputTitle";
import {
  CREATE_QUOTE_MUTATION,
  REJECT_QUOTE_REQUEST_MUTATION,
} from "../../components/customer/JobRequestBox";
import MiniProjectCard from "../../components/customer/MiniProjectCard";
import NextButton from "../../components/customer/NextButton";
import PageHeader from "../../components/customer/PageHeader";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";
import { FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY } from "./FindJobs";
import { FETCH_QUOTE_REQUEST_BY_ID_QUERY } from "./JobDetails";
import {
  FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
  FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
} from "./Jobs";
import { FETCH_INPUT_FIELDS_BY_PROJECT_ID_QUERY } from "./ProjectDetails";
import { FETCH_CUSTOMER_QUERY } from "./Settings";
import {
  FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
  FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
} from "./WriteQuote";
function QuoteRequestDetails(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Quote Request Details | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const [errors, setErrors] = useState({});

  const quoteRequestId = props.match.params.quoteRequestId;

  const {
    data: { getQuoteRequestById: fetchedQuoteRequest } = {},
    loading: { loadingQuoteRequest },
  } = useQuery(FETCH_QUOTE_REQUEST_BY_ID_QUERY, {
    variables: { quoteRequestId },
  });

  if (fetchedQuoteRequest) {
    if (
      fetchedQuoteRequest.expiredAt &&
      fetchedQuoteRequest.expiredAt < new Date()
    ) {
      props.history.push("/addJobs");
    }

    if (fetchedQuoteRequest.rejectedByIds.includes(customer.id)) {
      props.history.push("/addJobs");
    }
    //  else if (fetchedQuoteRequest.quotedByIds.includes(customer.id)) {
    //   // props.history.push("/writeQuote/" + quoteRequestId);
    // }
  }
  const projectId = props.match.params.projectId;

  const {
    data: { getProjectById: fetchedProject } = {},
    loading,
    // refetch: refetchProjects,
  } = useQuery(FETCH_PROJECT_BY_ID_QUERY, {
    variables: { projectId },
  });

  const [searchTerm, setSearchTerm] = useState("");

  const onChangeText = (name, text) => {
    setSearchTerm(text);
  };
  const clearTextFunction = () => {
    setSearchTerm("");
  };

  const {
    data: { getProjectSubPartsByCustomerId: fetchedSubProjects } = {},
    // refetch,
    loading: loadingSubParts,
  } = useQuery(FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY, {
    variables: { searchTerm, parentProjectId: projectId },
  });

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const [rejectQuoteRequest, { loading: loadingQuoteRequestRejection }] =
    useMutation(REJECT_QUOTE_REQUEST_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
        },
        {
          query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
      ],

      update(_, { data: { rejectQuoteRequest: projectData } }) {
        // refetch projects
        props.history.push("/jobs");
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: { quoteRequestId },
    });
  const [createQuote, { loading: loadingQuote }] = useMutation(
    CREATE_QUOTE_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
        },
        {
          query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUEST_BY_ID_QUERY,
          variables: { quoteRequestId },
        },
        {
          query: FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
          variables: { quoteRequestId },
        },
        {
          query: FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
          variables: { quoteRequestId },
        },
      ],

      update(_, { data: { createQuote: quoteData } }) {
        props.history.push("/writeQuote/" + quoteRequestId);
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: { quoteRequestId },
    }
  );

  const createQuoteCallback = (e) => {
    e.preventDefault();
    if (
      fetchedQuoteRequest &&
      fetchedQuoteRequest.quotedByIds.includes(customer.id)
    ) {
      props.history.push("/writeQuote/" + quoteRequestId);
    } else {
      createQuote();
    }
  };
  // if (
  //   !loadingCustomer &&
  //   !loadingQuote &&
  //   !loadingSubParts &&
  //   !loadingQuoteRequest &&
  //   !fetchedQuoteRequest
  // ) {
  //   // console.log(fetchedQuoteRequest);
  //   props.history.push("/jobs");
  // }
  const { values, onArrayChange, setValues } = useForm(emptyCallback, {
    editProjectId: projectId,
    // parentProjectId: project ? project : null,
    title: "",
    shortDescription: "",
    selectedTopLvlInputFieldIds: [],

    subParts: [],
  });
  function emptyCallback() {}

  const {
    data: { getInputFieldsByProjectId: projectInputFields } = {},
    // error,
    loading: loadingProjectInputFields,
  } = useQuery(FETCH_INPUT_FIELDS_BY_PROJECT_ID_QUERY, {
    variables: {
      isTopLevel: true,
      projectId,
    },
  });

  const [finishedSettingInitialVals, setFinishedSettingInitialVals] =
    useState(false);
  useEffect(() => {
    if (!finishedSettingInitialVals && fetchedProject) {
      setValues({
        ...values,

        ...fetchedProject,
      });
      setFinishedSettingInitialVals(true);
    }
  }, [
    fetchedProject,
    setValues,
    values,
    finishedSettingInitialVals,
    setFinishedSettingInitialVals,
  ]);

  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }
  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 py-6 md:mb-2 md:p-0 w-full">
          <PageHeader
            props={props}
            titleBold={fetchedProject ? `${fetchedProject.title}` : "Project"}
            caption={fetchedProject && `${fetchedProject.shortDescription}`}
            hideHomeButton={true}
            // padTop={true} // caption="Fill out this form to save your idea. Later, you can get quotations for it within two days."
          />
        </div>
        {fetchedProject && (
          <div className="flex flex-col space-y-4 items-start justify-start w-full overflow-y-auto px-6 md:p-0 flex-1 overflow-x-hidden">
            {mainImageUrl && (
              <img
                className="object-cover w-full h-36 sm:h-52 md:h-36 rounded-lg shadow-lg"
                src={mainImageUrl}
                alt={fetchedProject.title}
              />
            )}

            {fetchedQuoteRequest && (
              <>
                <InputField
                  fieldTitle="Request type"
                  value={fetchedQuoteRequest.type}
                  disabled={true}
                />
                {fetchedQuoteRequest.type === "Production" && (
                  <InputField
                    fieldTitle="Quantity"
                    value={fetchedQuoteRequest.unitsNeeded}
                    disabled={true}
                  />
                )}

                {fetchedQuoteRequest.type === "message" && (
                  <InputField
                    fieldTitle="Message"
                    value={fetchedQuoteRequest.message}
                    disabled={true}
                  />
                )}
              </>
            )}

            {projectInputFields && projectInputFields.length > 0 && (
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <InputTitle
                  fieldTitle={"Details (optional)"}
                  errorMsg={errors && errors.selectedTopLvlInputFieldIds}
                  hint={
                    "Add details by clicking one of the options below. Click again to remove that detail."
                  }
                />

                <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                  {projectInputFields &&
                    projectInputFields.length > 0 &&
                    projectInputFields.map((topLvlSuggestedDetail, index) => (
                      <InputField
                        name={"selectedTopLvlInputFieldIds"}
                        onChangeFunction={onArrayChange}
                        checkboxValue={topLvlSuggestedDetail.id}
                        type="checkbox"
                        value={values.selectedTopLvlInputFieldIds}
                        checkboxTitle={topLvlSuggestedDetail.title}
                        key={index}
                      />
                    ))}
                </div>
              </div>
            )}

            {values.selectedTopLvlInputFieldIds &&
              values.selectedTopLvlInputFieldIds.length > 0 &&
              values.selectedTopLvlInputFieldIds.map(
                (topLvlInputFieldId, index) => (
                  <InputFieldsForSelectedTopLevelField
                    key={index}
                    topLvlInputFieldId={topLvlInputFieldId}
                    errors={errors}
                    disabled={true}
                    values={values}
                    setValues={setValues}
                  />
                )
              )}

            {((fetchedSubProjects && fetchedSubProjects.length > 0) ||
              searchTerm) && (
              <InputField
                fieldTitle="Search"
                errorMsg={errors && errors.searchTerm}
                placeholder="Sub parts"
                name="searchTerm"
                onChangeFunction={onChangeText}
                value={searchTerm}
                type="text"
                clearBtnText={"Clear"}
                clearTextFunction={clearTextFunction}
              />
            )}
            <div className="flex-shrink-0 flex items-center justify-start space-x-4 w-full overflow-x-auto">
              {fetchedSubProjects &&
                fetchedSubProjects.length > 0 &&
                fetchedSubProjects.map((subProject, index) => (
                  <MiniProjectCard props={props} project={subProject} />
                ))}
            </div>
          </div>
        )}
        {fetchedProject &&
          fetchedCustomer &&
          fetchedCustomer.isAPartnerStaffMember &&
          fetchedCustomer.id !== fetchedProject.customerId && (
            <div className="flex items-center justify-center space-x-4 my-6 md:mb-0 w-full">
              {fetchedQuoteRequest &&
              fetchedQuoteRequest.projectId !== projectId ? (
                <NextButton
                  goToNextStep={(e) =>
                    props.history.push(
                      "/quoteRequestDetails/" +
                        quoteRequestId +
                        "/" +
                        fetchedQuoteRequest.projectId
                    )
                  }
                  hideArrow={true}
                  loading={
                    loading ||
                    loadingQuote ||
                    loadingCustomer ||
                    loadingQuoteRequest ||
                    loadingQuoteRequestRejection ||
                    loadingSubParts ||
                    loadingProjectInputFields
                  }
                  title="Back to main job"
                  type="button"
                />
              ) : fetchedQuoteRequest && fetchedQuoteRequest.status === 1 ? (
                <NextButton
                  goToNextStep={(e) => {
                    e.preventDefault();
                    props.history.push("/jobs");
                  }}
                  hideArrow={true}
                  loading={
                    loading ||
                    loadingQuote ||
                    loadingCustomer ||
                    loadingQuoteRequest ||
                    loadingQuoteRequestRejection ||
                    loadingSubParts ||
                    loadingProjectInputFields
                  }
                  title="Go to jobs"
                  type="button"
                />
              ) : (
                <>
                  {!(
                    loading ||
                    loadingQuote ||
                    loadingCustomer ||
                    loadingQuoteRequest ||
                    loadingQuoteRequestRejection ||
                    loadingSubParts
                  ) && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        rejectQuoteRequest();
                      }}
                      disabled={
                        loading ||
                        loadingQuote ||
                        loadingCustomer ||
                        loadingQuoteRequest ||
                        loadingQuoteRequestRejection ||
                        loadingSubParts
                      }
                      className="px-4 py-2 bg-red-500 hover:bg-red-700 border-transparent border-2 text-white shadow-lg text-xl sm:text-2xl md:text-xl rounded-full transition-colors focus:outline-none focus:ring-1 flex items-center justify-center space-x-2 leading-none font-semibold"
                    >
                      Reject
                    </button>
                  )}

                  <button
                    type="button"
                    onClick={createQuoteCallback}
                    disabled={
                      loading ||
                      loadingQuote ||
                      loadingCustomer ||
                      loadingQuoteRequest ||
                      loadingQuoteRequestRejection ||
                      loadingSubParts
                    }
                    className="px-4 py-2 bg-blue-700 hover:bg-blue-500 border-transparent border-2 text-white shadow-lg text-xl sm:text-2xl md:text-xl rounded-full transition-colors focus:outline-none focus:ring-1 flex items-center justify-center space-x-2 leading-none font-semibold"
                  >
                    Write quote
                  </button>
                </>
              )}
            </div>
          )}
      </div>
    </div>
  );
}

export const FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY = gql`
  query getProjectSubPartsByCustomerId(
    $customerId: String
    $searchTerm: String
    $parentProjectId: String!
  ) {
    getProjectSubPartsByCustomerId(
      customerId: $customerId
      searchTerm: $searchTerm
      parentProjectId: $parentProjectId
    ) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export const FETCH_PROJECT_BY_ID_QUERY = gql`
  query getProjectById($projectId: String!) {
    getProjectById(projectId: $projectId) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;
export default QuoteRequestDetails;
