import { useEffect, useState } from "react";

export const useForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const onArrayChange = (event) => {
    // const newValues = [...values];
    // event.preventDefault();
    // event.stopPropagation();

    var list = [...values[event.target.name]];

    let index = 0;

    if (list) {
      if (event.target.checked) {
        if (!list.includes(event.target.value)) {
          list = [...list, event.target.value];
        }
      } else if (list.length === 1) {
        setValues({ ...values, [event.target.name]: [] });
        return;
      } else {
        index = list.indexOf(event.target.value);
        console.log(list);
        if (index > -1) {
          list.splice(index, 1);
        }
      }

      // TODO: instructions out all console.logs
      list.sort();
      setValues({ ...values, [event.target.name]: list });
    }
  };

  const onChange = (event, convertValToNumber) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.type === "number" || event.target.type === "range") {
      const numberValue = parseInt(event.target.value, 10) || 0;

      setValues({
        ...values,
        [event.target.name]: numberValue,
      });
    } else if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setValues({ ...values, [event.target.name]: true });
      } else {
        setValues({ ...values, [event.target.name]: false });
      }
    } else if (event.target.name === "typeOfDiscount") {
      var amountOff = 0;
      if (event.target.value === "0") {
        amountOff = 1000;
      } else if (event.target.value === "1") {
        amountOff = 10;
      } else {
        amountOff = (3 / 23) * 100;
      }
      setValues({
        ...values,
        [event.target.name]: parseInt(event.target.value) || 0,
        amountOff,
      });
    } else if (convertValToNumber) {
      const numberValue = parseFloat(event.target.value);

      setValues({
        ...values,
        [event.target.name]: numberValue,
      });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    event.stopPropagation();
    callback();
  };

  // const counterClickCallback = (targetInputName) => {

  // };

  return {
    onChange,
    onSubmit,
    // onCounterClick,
    onArrayChange,
    values,
    setValues,
    // onDrop,
  };
};

// export const getContentTypeByFile = (fileName) => {
//   var rc = { type: "image/jpeg", extension: ".jpeg" };
//   var fn = fileName.toLowerCase();
//   ".ai,.dwg,.dxf,.dgn,.stl"
//   : name === "gerberFiles"
//   ? ".gbr"
//   : ".doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"

//   if (fn.indexOf(".html") >= 0) {
//     rc.type = "text/html";
//     rc.extension = ".html";
//   } else if (fn.indexOf(".css") >= 0) {
//     rc.type = "text/css";
//     rc.extension = ".css";
//   } else if (fn.indexOf(".json") >= 0) {
//     rc.type = "application/json";
//     rc.extension = ".json";
//   }else if (fn.indexOf(".pdf") >= 0) {
//     rc.type = "application/pdf";
//     rc.extension = ".json";
//   } else if (fn.indexOf(".js") >= 0) {
//     rc.type = "application/x-javascript";
//     rc.extension = ".js";
//   } else if (fn.indexOf(".png") >= 0) {
//     rc.type = "image/png";
//     rc.extension = ".png";
//   } else if (fn.indexOf(".jpg") >= 0) {
//     rc.type = "image/jpg";
//     rc.extension = ".jpg";
//   } else if (fn.indexOf(".gif") >= 0) {
//     rc.type = "image/gif";
//     rc.type = ".gif";
//   }

//   return rc;
// };

export const getNatural = (num) => {
  return num
    .toString()
    .split(".")[0]
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const getDecimal = (num) => {
  return num.toString().split(".")[1];
};

export const getMoneyVersion = (num) => {
  return (
    getNatural((num / 100).toFixed(2)) +
    "." +
    getDecimal((num / 100).toFixed(2))
  );
};
export const checkIsDarkMode = () => {
  return (
    window.matchMedia("(prefers-color-scheme: dark)") &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

export const useWindowSize = () => {
  const isClient = typeof window === "object";

  // function getSize() {
  //   return {
  //     width: isClient ? window.innerWidth : undefined,
  //     height: isClient ? window.innerHeight : undefined,
  //   };
  // }

  const screenSize = {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };

  const [windowSize, setWindowSize] = useState(screenSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      const screenSize = {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      };
      setWindowSize(screenSize);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

const SCROLL_UP = "up";
const SCROLL_DOWN = "down";

export const useScrollDirection = ({
  initialDirection,
  thresholdPixels,
  off,
} = {}) => {
  const [scrollDir, setScrollDir] = useState(initialDirection);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }
      console.log(scrollY);
      setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    /**
     * Bind the scroll handler if `off` is set to false.
     * If `off` is set to true reset the scroll direction.
     */
    !off
      ? window.addEventListener("scroll", onScroll)
      : setScrollDir(initialDirection);

    return () => window.removeEventListener("scroll", onScroll);
  }, [initialDirection, thresholdPixels, off]);

  return scrollDir;
};

// export const useInfiniteScroll = (callback) => {
//   const [isFetching, setIsFetching] = useState(false);

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   useEffect(() => {
//     if (!isFetching) return;
//     callback(() => {

//     });
//   }, [isFetching]);

//   function handleScroll() {
//     if (
//       window.innerHeight + document.documentElement.scrollTop !==
//         document.documentElement.offsetHeight ||
//       isFetching
//     )
//       return;
//     setIsFetching(true);
//   }

//   return [isFetching, setIsFetching];
// };
