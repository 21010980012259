import React, { useCallback, useEffect, useState } from "react";
import RadioInputsWithTitle from "./RadioInputsWithTitle";

export default function RadioInputField({
  values,
  errors,
  setValues,
  topLvlInputFieldId,
  fetchedInputField,
  disabled,
}) {
  const [stringVal, setStringVal] = useState("");
  const [intVal, setIntVal] = useState(null);
  const [floatVal, setFloatVal] = useState(null);

  const [doneSettingDefaultVals, setDoneSettingDefaultVals] = useState(false);

  useEffect(() => {
    const setDefaultVal = () => {
      if (fetchedInputField.actualType === "string") {
        if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
          for (let i = 0; i < values.inputIdsToStrings.length; ++i) {
            if (
              values.inputIdsToStrings[i].inputFieldId === fetchedInputField.id
            ) {
              // console.log("found a match; "+);
              setStringVal(values.inputIdsToStrings[i].stringVal);
              return;
            }
          }
        }
        setStringVal(fetchedInputField.stringPlaceHolder);
      } else if (fetchedInputField.actualType === "int") {
        if (values.inputIdsToInts && values.inputIdsToInts.length > 0) {
          for (let i = 0; i < values.inputIdsToInts.length; ++i) {
            if (
              values.inputIdsToInts[i].inputFieldId === fetchedInputField.id
            ) {
              setIntVal(values.inputIdsToInts[i].intVal);
              return;
            }
          }
        }
        setIntVal(fetchedInputField.intPlaceHolder);
      } else if (fetchedInputField.actualType === "float") {
        if (values.inputIdsToFloats && values.inputIdsToFloats.length > 0) {
          for (let i = 0; i < values.inputIdsToFloats.length; ++i) {
            if (
              values.inputIdsToFloats[i].inputFieldId === fetchedInputField.id
            ) {
              setFloatVal(values.inputIdsToFloats[i].floatVal);
              return;
            }
          }
        }
        setFloatVal(fetchedInputField.floatPlaceHolder);
      }
    };
    if (
      !doneSettingDefaultVals &&
      !stringVal &&
      intVal === null &&
      floatVal === null
    ) {
      setDefaultVal();
      setDoneSettingDefaultVals(true);
    } else {
      // console.log(stringVal);
      // console.log(intVal);
      // console.log(floatVal);
    }
  }, [
    stringVal,
    intVal,
    floatVal,
    fetchedInputField,
    values,
    doneSettingDefaultVals,
  ]);

  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (fetchedInputField.actualType === "int") {
      setIntHereAndInValues(parseInt(e.target.value, 10) || 0);
    } else if (fetchedInputField.actualType === "float") {
      setFloatHereAndInValues(parseFloat(e.target.value) || 0);
    } else {
      setStringHereAndInValues(e.target.value);
    }
  };

  const setStringHereAndInValues = useCallback(
    (targetString) => {
      if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
        for (let i = 0; i < values.inputIdsToStrings.length; ++i) {
          if (
            values.inputIdsToStrings[i].inputFieldId === fetchedInputField.id
          ) {
            let copiedValues = { ...values };
            let targetInputIdToString = {
              ...copiedValues.inputIdsToStrings[i],
            };

            targetInputIdToString.stringVal = targetString;

            copiedValues.inputIdsToStrings = [
              ...copiedValues.inputIdsToStrings,
              targetInputIdToString,
            ];
            copiedValues.inputIdsToStrings.splice(i, 1);

            setValues(copiedValues);
            setStringVal(targetString);
            return;
          }
        }
        const copiedValues2 = {
          ...values,
          inputIdsToStrings: [
            ...values.inputIdsToStrings,
            {
              inputFieldId: fetchedInputField.id,
              stringVal: fetchedInputField.stringPlaceHolder,
            },
          ],
        };

        setValues(copiedValues2);
        setStringVal(targetString);
        return;
      } else {
        const copiedValues = {
          ...values,
          inputIdsToStrings: [
            {
              inputFieldId: fetchedInputField.id,
              stringVal: targetString,
            },
          ],
        };

        // values["inputIdsToStrings"]=            {
        //   inputFieldId: fetchedInputField.id,
        //   stringVal: targetString,
        // }

        setValues(copiedValues);
      }

      setStringVal(targetString);
    },
    [fetchedInputField, values, setValues]
  );

  const setIntHereAndInValues = useCallback(
    (targetInt) => {
      if (values.inputIdsToInts && values.inputIdsToInts.length > 0) {
        for (let i = 0; i < values.inputIdsToInts.length; ++i) {
          if (values.inputIdsToInts[i].inputFieldId === fetchedInputField.id) {
            let copiedValues = { ...values };
            let targetInputIdToInt = {
              ...copiedValues.inputIdsToInts[i],
            };

            targetInputIdToInt.intVal = targetInt;

            copiedValues.inputIdsToInts = [
              ...copiedValues.inputIdsToInts,
              targetInputIdToInt,
            ];
            copiedValues.inputIdsToInts.splice(i, 1);

            setValues(copiedValues);
            setIntVal(targetInt);
            return;
          }
        }
        const copiedValues2 = {
          ...values,
          inputIdsToInts: [
            ...values.inputIdsToInts,
            {
              inputFieldId: fetchedInputField.id,
              intVal: fetchedInputField.intPlaceHolder,
            },
          ],
        };

        setValues(copiedValues2);
        setIntVal(targetInt);
        return;
      } else {
        const copiedValues = {
          ...values,
          inputIdsToInts: [
            {
              inputFieldId: fetchedInputField.id,
              intVal: targetInt,
            },
          ],
        };

        // values["inputIdsToInts"]=            {
        //   inputFieldId: fetchedInputField.id,
        //   intVal: targetInt,
        // }

        setValues(copiedValues);
      }

      setIntVal(targetInt);
    },
    [fetchedInputField, values, setValues]
  );

  const setFloatHereAndInValues = useCallback(
    (targetFloat) => {
      if (values.inputIdsToFloats && values.inputIdsToFloats.length > 0) {
        for (let i = 0; i < values.inputIdsToFloats.length; ++i) {
          if (
            values.inputIdsToFloats[i].inputFieldId === fetchedInputField.id
          ) {
            let copiedValues = { ...values };
            let targetInputIdToFloat = {
              ...copiedValues.inputIdsToFloats[i],
            };

            targetInputIdToFloat.floatVal = targetFloat;

            copiedValues.inputIdsToFloats = [
              ...copiedValues.inputIdsToFloats,
              targetInputIdToFloat,
            ];
            copiedValues.inputIdsToFloats.splice(i, 1);

            setValues(copiedValues);
            setFloatVal(targetFloat);
            return;
          }
        }
        const copiedValues2 = {
          ...values,
          inputIdsToFloats: [
            ...values.inputIdsToFloats,
            {
              inputFieldId: fetchedInputField.id,
              floatVal: fetchedInputField.floatPlaceHolder,
            },
          ],
        };

        setValues(copiedValues2);
        setFloatVal(targetFloat);
        return;
      } else {
        const copiedValues = {
          ...values,
          inputIdsToFloats: [
            {
              inputFieldId: fetchedInputField.id,
              floatVal: targetFloat,
            },
          ],
        };

        // values["inputIdsToFloats"]=            {
        //   inputFieldId: fetchedInputField.id,
        //   floatVal: targetFloat,
        // }

        setValues(copiedValues);
      }

      setFloatVal(targetFloat);
    },
    [fetchedInputField, values, setValues]
  );

  return (
    <RadioInputsWithTitle
      fieldTitle={
        disabled ? fetchedInputField.title : fetchedInputField.title + "*"
      }
      errorMsg={errors && errors[fetchedInputField.id]}
      hint={fetchedInputField.description}
      name={fetchedInputField.title}
      onChangeFunction={onChange}
      disabled={disabled}
      value={
        fetchedInputField.actualType === "int"
          ? intVal
          : fetchedInputField.actualType === "float"
          ? floatVal
          : stringVal
      }
      options={
        fetchedInputField.actualType === "int"
          ? fetchedInputField.intOptions
          : fetchedInputField.actualType === "float"
          ? fetchedInputField.floatOptions
          : fetchedInputField.stringOptions
      }
      convertValToNumber={fetchedInputField.actualType !== "string"}
      // key={index}
    />
  );
}
