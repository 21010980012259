import moment from "moment";
import React from "react";
import MilestoneDetailsDropdown from "./MilestoneDetailsDropdown";

export default function QuoteSummaryBox({
  orderMilestones,
  quote,
  showProjectImage,
  showFormattedTitle,
  expandMilestone,
  setValues,
  values,
}) {
  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }
  return (
    <div className="flex flex-col items-center justify-center space-y-2 w-full md:px-0 px-6">
      {showFormattedTitle && (
        <p className="text-light text-sm text-gray-500 w-full whitespace-nowrap">
          Quote {quote.versionNumber}
        </p>
      )}
      <div className="flex flex-col space-y-1 w-full overflow-y-auto max-h-24">
        {orderMilestones &&
          orderMilestones.map((orderMilestone, index) => (
            <MilestoneDetailsDropdown
              orderMilestone={orderMilestone}
              index={index}
              getNatural={getNatural}
              getDecimal={getDecimal}
              expandMilestone={expandMilestone}
              setValues={setValues}
              quoteId={quote.id}
              values={values}
            />
          ))}
      </div>
      <div
        className={`flex items-center justify-between w-full focus:outline-none text-black md:text-white border-t-2 border-black md:border-white`}
      >
        <p className="font-semibold text-xl truncate">Total</p>

        <div className="flex flex-col items-end justify-end text-right leading-tight">
          <p className="font-semibold text-xl">
            CA$
            {getNatural((quote.finalNetAmount / 100).toFixed(2))}.
            {getDecimal((quote.finalNetAmount / 100).toFixed(2))}
          </p>
          <p className="">By {moment(quote.deadline).format("D MMM, YYYY")}</p>
        </div>
      </div>
    </div>
  );
}
