import moment from "moment";
import React from "react";
import OrderMilestoneMessageBox from "./OrderMilestoneMessageBox";
import PartnerServiceMessageBox from "./PartnerServiceMessageBox";
import QuoteMessageBox from "./QuoteMessageBox";

export default function MessageBox({ message, senderId, customerId, props }) {
  return (
    <div
      className={`${
        customerId === senderId
          ? "items-end justify-end pl-4"
          : "items-start justify-start pr-4"
      } w-full flex flex-col space-y-2`}
    >
      {message.quoteId && (
        <div className="bg-gray-100 rounded-xl shadow-md max-w-full">
          <QuoteMessageBox
            message={message}
            quoteId={message.quoteId}
            staffCustomerId={customerId}
            props={props}
          />
        </div>
      )}
      {message.message && (
        <div className="flex flex-col items-end justify-end w-auto p-2 bg-gray-100 rounded-xl shadow-md">
          <p className="w-full">{message.message}</p>
          <p className="text-xs">
            {moment(message.createdAt).format("h:mm a")}
          </p>
        </div>
      )}

      {message.orderMilestoneId && (
        <div className="bg-gray-100 rounded-xl shadow-md max-w-full">
          <OrderMilestoneMessageBox
            message={message}
            orderMilestoneId={message.orderMilestoneId}
            staffCustomerId={customerId}
            props={props}
          />
        </div>
      )}
      {message.partnerServiceId && (
        <div className="bg-gray-100 rounded-xl shadow-md max-w-full">
          <PartnerServiceMessageBox
            message={message}
            partnerServiceId={message.partnerServiceId}
            staffCustomerId={customerId}
            props={props}
          />
        </div>
      )}
      {/* {fetchedOrderSpecification && message && fetchedPrice && (
        <div
          className={`${
            userId !== senderId
              ? "border-gray-500 mr-6"
              : "text-right border-blue-500 ml-6"
          }  text-xs text-black rounded-lg px-3 py-1 my-1 flex flex-col items-center bg-white border-2 inline-flex`}
        >
          <p className="text-xs font-semibold">{message}</p>
          <div className="flex items-center my-1">
            <p className="text-sm font-semibold">
              {fetchedOrderSpecification.quantity} x
            </p>

            <img
              className="h-8 w-8 object-contain ml-2"
              src={`${fetchedPrice.productDetails.image}`}
              // src="https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
              alt={fetchedPrice.productDetails.title}
            />
          </div>
          <p className="text-sm text-center">
            {fetchedPrice.productDetails.title}
          </p>
          <p className="text-xs text-gray-500 text-center">
            {moment(createdAt).format("h:mm A")}
          </p>
        </div>
      )}
      {message && !orderSpecificationId && (
        <div
          className={`${
            userId !== senderId
              ? senderId === customerId
                ? "bg-gray-500 mr-6"
                : "bg-red-500"
              : "text-right bg-blue-500 ml-6"
          } text-white rounded-lg px-3 py-1 inline-block my-1 break-all`}
        >
          {userId !== senderId && senderId !== customerId && (
            <p className="leading-tight text-xs font-semibold uppercase text-gray-100">
              Admin
            </p>
          )}
          <p className="leading-tight">{message}</p>
          <p className="text-xs text-gray-200">
            {moment(createdAt).format("h:mm A")}
          </p>
        </div>
      )} */}
    </div>
  );
}
