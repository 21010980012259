import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import AddOrderMilestoneElement from "../../components/customer/AddOrderMilestoneElement";
import HomeButton from "../../components/customer/HomeButton";
import PageHeader from "../../components/customer/PageHeader";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_QUOTE_REQUEST_BY_ID_QUERY } from "./JobDetails";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function WriteQuote(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Write Quote | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const quoteRequestId = props.match.params.quoteRequestId;

  const {
    data: { getQuoteRequestById: fetchedQuoteRequest } = {},
    loading: { loadingQuoteRequest },
  } = useQuery(FETCH_QUOTE_REQUEST_BY_ID_QUERY, {
    variables: { quoteRequestId },
  });

  const {
    data: { getPendingStaffQuoteByQuoteRequest: fetchedQuote } = {},
    loading: { loadingQuote },
  } = useQuery(FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY, {
    variables: { quoteRequestId },
  });

  const {
    data: { getOrderMilestonesByQuoteRequestId: fetchedOrderMilestones } = {},
    loading: loadingOrderMilestones,
  } = useQuery(FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY, {
    variables: { quoteRequestId },
  });

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  if (
    !loadingCustomer &&
    !loadingOrderMilestones &&
    !loadingQuote &&
    !loadingQuoteRequest &&
    !fetchedQuote
  ) {
    console.log("hi");
    // props.history.push("/jobs");
  }

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>

      {fetchedQuoteRequest && fetchedOrderMilestones && fetchedQuote ? (
        <AddOrderMilestoneElement
          quoteRequest={fetchedQuoteRequest}
          orderMilestones={fetchedOrderMilestones}
          quote={fetchedQuote}
          props={props}
        />
      ) : (
        <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
          <PageHeader
            props={props}
            titleBold={
              fetchedQuote && fetchedQuote.status === 1
                ? "Edit quote"
                : "Write quote"
            }
            hideHomeButton={true}
            padTop={true}
          />
        </div>
      )}
    </div>
  );
}

export const FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY = gql`
  query getPendingStaffQuoteByQuoteRequest($quoteRequestId: String!) {
    getPendingStaffQuoteByQuoteRequest(
      # customerId: $customerId
      quoteRequestId: $quoteRequestId
    ) {
      id
      quoteRequestId
      projectId
      createdAt
      versionNumber
      finalNetAmount
      deadline
      status
    }
  }
`;

export const FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY = gql`
  query getOrderMilestonesByQuoteRequestId(
    $customerId: String
    $quoteRequestId: String!
  ) {
    getOrderMilestonesByQuoteRequestId(
      customerId: $customerId
      quoteRequestId: $quoteRequestId
    ) {
      id
      quoteRequestId
      quoteId
      staffCustomerId
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      finalSelectedTopLvlInputFieldIds
      finalInputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      finalInputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      finalInputIdsToDates {
        inputFieldId
        dateVal
      }
      finalInputIdsToStrings {
        inputFieldId
        stringVal
      }
      finalInputIdsToInts {
        inputFieldId
        intVal
      }
      finalInputIdsToFloats {
        inputFieldId
        floatVal
      }
      finalInputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      finalInputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      finalInputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      responsiblePartnerServiceId
      doneByPartnerServiceId
      customerDiscountUsed
      finalSubTotal
      finalDiscount
      finalRefundAmount
      finalNetAmount
      startedWorkingAtDate
      deadline
      completedAtDate
      projectId
      customerId
      partnerStaffId
      allMilestoneVersionIds
      versionNumber
      notificationReceiverIds
      transferId
      currency
      status
      createdAt
      verificationSentAt
      paymentSuccessAt
      expiredAt
      refundedAt
    }
  }
`;

export default WriteQuote;
