import React, { useState } from "react";
import { getDecimal, getNatural } from "../../util/hooks";
import InputTitle from "./InputTitle";
import MultipleDocumentsUploadBox from "./MultipleDocumentsUploadBox";
import OneDocumentUploadBox from "./OneDocumentUploadBox";
import ViewUploadedImageBox from "./ViewUploadedImageBox";

export default function InputField({
  fieldTitle,
  errorMsg,
  placeholder,
  name,
  onChangeFunction,
  value,
  type,
  rows,
  whiteBack,

  fetchedProject,
  radioValue,
  radioTitle,
  disabled,
  keepAutoCompleteOff,

  additionalBtnText,
  additionalBtnFunction,
  clearBtnText,
  clearTextFunction,
  setDocumentsCallback,
  setDocumentCallback,
  deleteDocumentCallback,

  rangeMin,
  rangeMax,
  editOrderMilestoneId,
  blueBack,
  loading,
  maxLength,

  checkboxTitle,
  checkboxValue,
  hint,
  convertValToNumber,
  isMoney,

  rangeStep,
  acceptedFileFormats,
  urlArrayVal,
}) {
  // useEffect(() => {
  //   console.log(name + ": " + value);
  // }, [value, name]);
  const [previewDocumentUrl, setPreviewDocumentUrl] = useState(null);

  const setDocumentPreviewCallback = (documentPreviewUrl) => {
    setPreviewDocumentUrl(documentPreviewUrl);
  };

  const deleteIndividualDocumentCallback = (e) => {
    e.preventDefault();

    setPreviewDocumentUrl(null);
    setDocumentCallback(null);
  };

  return (
    <div
      className={` ${
        type === "checkbox" || type === "radio"
          ? "h-full"
          : "flex flex-col items-start justify-start w-full"
      }`}
    >
      {(fieldTitle ||
        errorMsg ||
        clearBtnText ||
        value ||
        additionalBtnText ||
        hint) && (
        <InputTitle
          fieldTitle={fieldTitle}
          errorMsg={errorMsg}
          clearBtnText={clearBtnText}
          value={value}
          additionalBtnText={additionalBtnText}
          hint={hint}
          blueBack={blueBack}
          additionalBtnFunction={additionalBtnFunction}
          clearTextFunction={clearTextFunction}
          whiteBack={whiteBack}
        />
      )}
      {type === "textarea" ? (
        <textarea
          className={`text-4xl md:text-3xl bg-transparent ${
            blueBack ? "text-white" : "text-black"
          } md:text-white w-full font-medium focus:outline-none rounded`}
          placeholder={placeholder}
          error={errorMsg ? "true" : "false"}
          name={name}
          onChange={onChangeFunction}
          value={value}
          type={type}
          rows={rows}
          maxLength={maxLength ? maxLength : "524288"}
        />
      ) : type === "documents" ? (
        <>
          {!disabled && (
            <MultipleDocumentsUploadBox
              setDocumentsCallback={setDocumentsCallback}
              filesToAccept={acceptedFileFormats}
            />
          )}
          {((value && value.length > 0) ||
            (urlArrayVal && urlArrayVal.length > 0)) && (
            <div className="mt-2 flex w-full items-start justify-start space-x-2 overflow-x-auto">
              {urlArrayVal &&
                urlArrayVal.length > 0 &&
                urlArrayVal.map((uploadItem, index) =>
                  uploadItem.indexOf(".jpg") >= 0 ||
                  uploadItem.indexOf(".png") >= 0 ||
                  uploadItem.indexOf(".gif") >= 0 ? (
                    <ViewUploadedImageBox
                      uploadItem={uploadItem}
                      key={index}
                      deleteDocumentCallback={(index) =>
                        deleteDocumentCallback(index, true)
                      }
                      disabled={disabled}
                      loading={loading}
                      index={index}
                    />
                  ) : (
                    <div //Open this up later
                      key={index}
                      className="flex items-center justify-center space-x-2 flex-shrink-0 text-blue-600 hover:bg-gray-200 border-blue-600 bg-white md:hover:bg-gray-600 md:bg-gray-700 md:text-white focus:outline-none focus:ring-1 flex-grow-0 text-center cursor-pointer border-2 md:border-0 font-normal rounded-full whitespace-nowrap py-2 px-2"
                    >
                      <p className="truncate">{uploadItem}</p>
                      {!disabled && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            deleteDocumentCallback(index, true);
                          }}
                          className="p-1 w-min hover:bg-red-600 rounded-full bg-red-500 transition-colors focus:outline-none focus:ring-1"
                        >
                          <svg
                            className="fill-current text-white h-4"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
                          </svg>
                        </button>
                      )}
                    </div>
                  )
                )}
              {value.map((uploadItem, index) =>
                uploadItem.name ? (
                  // fn.indexOf(".html") >= 0
                  uploadItem.name.indexOf(".jpg") >= 0 ||
                  uploadItem.name.indexOf(".png") >= 0 ||
                  uploadItem.name.indexOf(".gif") >= 0 ? (
                    <ViewUploadedImageBox
                      uploadItem={uploadItem}
                      key={index}
                      deleteDocumentCallback={(index) =>
                        deleteDocumentCallback(index, false)
                      }
                      index={index}
                      disabled={disabled}
                      loading={loading}
                    />
                  ) : (
                    <div //Open this up later
                      key={index}
                      className="flex items-center justify-center space-x-2 flex-shrink-0 text-blue-600 hover:bg-gray-200 border-blue-600 bg-white md:hover:bg-gray-600 md:bg-gray-700 md:text-white focus:outline-none focus:ring-1 flex-grow-0 text-center cursor-pointer border-2 md:border-0 font-normal rounded-full whitespace-nowrap py-2 px-2"
                    >
                      <p className="truncate">{uploadItem.name}</p>
                      {!disabled && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            deleteDocumentCallback(index, false);
                          }}
                          className="p-1 w-min hover:bg-red-600 rounded-full bg-red-500 transition-colors focus:outline-none focus:ring-1"
                        >
                          <svg
                            className="fill-current text-white h-4"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
                          </svg>
                        </button>
                      )}
                    </div>
                  )
                ) : uploadItem.indexOf(".jpg") >= 0 ||
                  uploadItem.indexOf(".png") >= 0 ||
                  uploadItem.indexOf(".gif") >= 0 ? (
                  <ViewUploadedImageBox
                    uploadItem={uploadItem}
                    key={index}
                    deleteDocumentCallback={(index) =>
                      deleteDocumentCallback(index, false)
                    }
                    disabled={disabled}
                    index={index}
                    loading={loading}
                  />
                ) : (
                  <div //Open this up later
                    key={index}
                    className="flex items-center justify-center space-x-2 flex-shrink-0 text-blue-600 hover:bg-gray-200 border-blue-600 bg-white md:hover:bg-gray-600 md:bg-gray-700 md:text-white focus:outline-none focus:ring-1 flex-grow-0 text-center cursor-pointer border-2 md:border-0 font-normal rounded-full whitespace-nowrap py-2 px-2"
                  >
                    <p className="truncate">{uploadItem}</p>
                    {!disabled && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          deleteDocumentCallback(index, false);
                        }}
                        className="p-1 w-min hover:bg-red-600 rounded-full bg-red-500 transition-colors focus:outline-none focus:ring-1"
                      >
                        <svg
                          className="fill-current text-white h-4"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
                        </svg>
                      </button>
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </>
      ) : type === "document" ? (
        <>
          {!(
            previewDocumentUrl ||
            (fetchedProject && fetchedProject.gerberFile)
          ) && (
            <OneDocumentUploadBox
              setDocumentPreviewCallback={setDocumentPreviewCallback}
              setDocumentCallback={setDocumentCallback}
              filesToAccept={".gbr"}
            />
          )}
          {(previewDocumentUrl ||
            (fetchedProject && fetchedProject.gerberFile)) && (
            <div
              className={`w-full flex items-center justify-start space-x-4 ${
                previewDocumentUrl ||
                (fetchedProject && fetchedProject.gerberFile)
                  ? "mt-2"
                  : ""
              } overflow-x-auto rounded-lg`}
            >
              <div
                key={previewDocumentUrl}
                className={`flex-shrink-0 relative rounded-lg overflow-hidden flex items-center justify-between space-x-2 bg-gray-200 p-2 shadow-lg w-full`}
              >
                <p className="truncate">{previewDocumentUrl}</p>
                <button
                  onClick={deleteIndividualDocumentCallback}
                  className="p-1 flex items-center justify-center w-min hover:bg-red-600 rounded-full bg-red-500 transition-colors focus:outline-none focus:ring-1"
                >
                  <svg
                    className="fill-current text-white h-4"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
                  </svg>
                </button>
              </div>
            </div>
          )}

          {/* {fetchedProject && name === "gerberFile" && (
              <EditProjectDocument
                project={fetchedProject}
                previewDocumentUrl={previewDocumentUrl}
              />
            )}

            {editOrderMilestoneId && name === "gerberFile" && (
              <EditMilestoneDocument
                editOrderMilestoneId={editOrderMilestoneId}
              />
            )} */}
        </>
      ) : type === "number" && !disabled ? (
        <input
          className={`text-4xl md:text-3xl bg-transparent ${
            blueBack ? "text-white" : "text-black"
          } md:text-white w-full font-medium rounded`}
          placeholder={placeholder}
          error={errorMsg ? "true" : "false"}
          name={name}
          onChange={onChangeFunction}
          value={value}
          type={type}
          min="0"
        />
      ) : type === "range" ? (
        <>
          {!disabled && (
            <input
              className="w-full border-white border-2 py-2"
              placeholder={placeholder}
              min={rangeMin}
              max={rangeMax}
              error={errorMsg ? "true" : "false"}
              name={name}
              onChange={onChangeFunction}
              value={value}
              type={type}
              step={rangeStep}
              disabled={disabled ? true : false}
            />
          )}
          {isMoney ? (
            <p
              className={`text-4xl md:text-3xl bg-transparent ${
                blueBack ? "text-white" : "text-black"
              } md:text-white w-full font-medium rounded`}
            >
              {getNatural((value / 100).toFixed(2))}.
              {getDecimal((value / 100).toFixed(2))}{" "}
              {/* {discountApplied&&discountApplied > 0 && (
                      <span>
                        - {naturalCustomerDiscount}.{decimalCustomerDiscount} ={" "}
                        <span className="text-yellow-500">
                          {naturalNetAmount}.{decimalNetAmount}
                        </span>
                      </span>
                    )} */}
            </p>
          ) : (
            <p
              className={`text-4xl md:text-3xl bg-transparent ${
                blueBack ? "text-white" : "text-black"
              } md:text-white w-full font-medium rounded`}
            >
              {value}
            </p>
          )}
        </>
      ) : type === "checkbox" ? (
        <div
          className={`${disabled ? "cursor-default" : "cursor-pointer"} ${
            value && checkboxValue && value.includes(checkboxValue)
              ? `border-blue-600 bg-blue-600 text-white ${
                  disabled ? "" : "hover:bg-blue-500"
                }`
              : "text-blue-600 hover:bg-gray-200 border-blue-600 bg-white md:bg-black md:hover:bg-gray-700 md:text-white"
          } focus:outline-none focus:ring-1 flex-grow-0 text-center border-2 md:border-0 font-normal rounded-full whitespace-nowrap`}
        >
          <input
            className="hidden"
            type={type}
            id={name + checkboxValue}
            name={name}
            value={checkboxValue}
            onChange={onChangeFunction}
            checked={value && checkboxValue && value.includes(checkboxValue)}
            error={errorMsg ? "true" : "false"}
            disabled={disabled}
          />
          <label
            className={`${
              disabled ? "cursor-default" : "cursor-pointer"
            } py-2 px-4 block`}
            htmlFor={name + checkboxValue}
          >
            {checkboxTitle}
          </label>
        </div>
      ) : type === "radio" && !disabled ? (
        <div
          className={`${
            value === radioValue
              ? " bg-blue-600 md:bg-white text-white md:text-black"
              : "md:bg-black hover:shadow-lg hover:bg-gray-700 md:text-white text-gray-400"
          } focus:outline-none focus:ring-1 cursor-pointer font-semibold rounded-full transition-colors whitespace-nowrap`}
        >
          <input
            className="hidden"
            type={type}
            id={name + radioValue}
            name={name}
            value={radioValue}
            onChange={(e) => {
              onChangeFunction(e, convertValToNumber);
            }}
            defaultChecked={
              convertValToNumber
                ? value === parseFloat(radioValue)
                : value === radioValue
            }
            disabled={disabled}
            error={errorMsg ? "true" : "false"}
          />
          <label
            className="cursor-pointer py-2 px-4 block"
            htmlFor={name + radioValue}
          >
            {radioTitle}
          </label>
        </div>
      ) : (
        <input
          className={`text-4xl md:text-3xl bg-transparent ${
            blueBack ? "text-white" : "text-black"
          } ${
            whiteBack ? "" : "md:text-white"
          } w-full font-medium focus:outline-none rounded`}
          placeholder={placeholder}
          error={errorMsg ? "true" : "false"}
          name={name}
          onChange={onChangeFunction}
          value={value}
          type={type}
          autoComplete={keepAutoCompleteOff ? "off" : "on"}
          disabled={disabled ? true : false}
          maxLength={maxLength ? maxLength : "524288"}
        />
      )}
    </div>
  );
}
