import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import Address1Editor from "../../components/customer/Address1Editor";
import AreaCodeEditor from "../../components/customer/AreaCodeEditor";
import CityEditor from "../../components/customer/CityEditor";
import CountryEditor from "../../components/customer/CountryEditor";
import EmailEditor from "../../components/customer/EmailEditor";
import FirstNameEditor from "../../components/customer/FirstNameEditor";
// import Footer from "../../components/customer/Footer";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import LastNameEditor from "../../components/customer/LastNameEditor";
import Line2Editor from "../../components/customer/Line2Editor";
import NextButton from "../../components/customer/NextButton";
import PhoneEditor from "../../components/customer/PhoneEditor";
import RegionEditor from "../../components/customer/RegionEditor";
// import PageHeader from "../../components/customer/PageHeader";
// import ProjectCard from "../../components/customer/ProjectCard";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useWindowSize } from "../../util/hooks";

function Settings(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Settings | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  // const [errors, setErrors] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: { getProjectsByCustomer: fetchedProjects } = {},
    // refetch,
    loading: loadingProjects,
  } = useQuery(FETCH_PROJECTS_BY_CUSTOMER_QUERY, {
    variables: { searchTerm },
  });

  const onChangeText = (e) => {
    // [event.target.name]: event.target.value
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const clearTextFunction = (e) => {
    e.preventDefault();
    setSearchTerm("");
  };

  const size = useWindowSize();

  if (fetchedProjects) {
    console.log(fetchedProjects);
  }

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start overflow-hidden md:p-6">
        <div className="px-6 pt-6 md:p-0 md:mb-2">
          {size.width >= 768 && (
            <ShareSoftwair
              isDarkMode={true}
              title="Share Softwair"
              loadingCustomer={loadingCustomer}
              customer={fetchedCustomer}
              showPartnerIncentive={
                fetchedCustomer && fetchedCustomer.isAPartner
              }
            />
          )}
          <InputField
            fieldTitle="Search"
            // errorMsg={errors && errors.searchTerm}
            placeholder="Your settings"
            name="searchTerm"
            onChangeFunction={onChangeText}
            value={searchTerm}
            type="text"
            clearBtnText={"Clear"}
            clearTextFunction={clearTextFunction}
          />
        </div>
        {fetchedCustomer && (
          <div className="flex flex-col items-center justify-start w-full h-full mt-4 pb-6 px-6 md:p-0 md:mt-0 space-y-4 flex-1 overflow-y-auto">
            <FirstNameEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <LastNameEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <EmailEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <PhoneEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <Address1Editor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <Line2Editor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <AreaCodeEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <CityEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <RegionEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
            <CountryEditor
              fetchedCustomer={fetchedCustomer}
              searchTerm={searchTerm}
            />
          </div>
        )}
        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <NextButton
            loading={loadingCustomer || loadingProjects}
            goToNextStep={(e) => {
              e.preventDefault();
              logout();
              customerClient.cache.reset();
            }}
            type="button"
            hideArrow={true}
            title="Sign out"
          />
        </div>
      </div>
    </div>
  );
}

export const FETCH_CUSTOMER_QUERY = gql`
  {
    getCustomer {
      id
      firstName
      lastName
      email
      phone
      discount
      referralCode
      isAPartner
      isAPartnerStaffMember
      line1
      line2
      city
      region
      country
      areaCode
    }
  }
`;

export const FETCH_PROJECTS_BY_CUSTOMER_QUERY = gql`
  query getProjectsByCustomer($customerId: String, $searchTerm: String) {
    getProjectsByCustomer(customerId: $customerId, searchTerm: $searchTerm) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export default Settings;
