import React from "react";

export default function MiniProjectCard({
  project,

  props,
}) {
  var mainImageUrl = "";
  if (project) {
    for (let i = 0; i < project.inputIdsToFileUrlArrays.length; ++i) {
      if (project.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8")
        //Images{
        mainImageUrl = project.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return (
    <div
      className={`bg-gray-100 md:border-gray-100 md:border-2 flex-shrink-0 border-2 border-gray-300 rounded-xl overflow-hidden flex flex-col items-center justify-center w-64`}
    >
      {mainImageUrl && (
        <img
          src={mainImageUrl}
          alt={project.title}
          className="rounded-xl w-full h-32 object-cover"
        />
      )}
      <div className="bg-gray-100 px-4 py-2 w-full h-full flex items-center justify-between">
        <div className="flex items-center justify-start space-x-2 truncate">
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.45704 0.923096L0.820435 2.6856L6.13651 8.42309L0.820435 14.1606L2.45704 15.9231L9.42133 8.42309L2.45704 0.923096Z"
              fill="#9CA3AF"
            />
          </svg>
          <p className="font-semibold">
            {project.title} v{project.versionNumber}
          </p>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            props.history.push("/projectDetails/" + project.id);
          }}
          className="font-semibold focus:outline-none focus:ring-4 text-white bg-gray-300 hover:bg-gray-700 rounded-full px-4 py-2 transition-colors"
        >
          Details
        </button>
      </div>
    </div>
  );
}
