import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import { animateScroll } from "react-scroll";
import ChatMemberTitle from "../../components/customer/ChatMemberTitle";
import HomeButton from "../../components/customer/HomeButton";
import MessageBox from "../../components/customer/MessageBox";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function Chat(props) {
  const location = useLocation();

  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Chat | Softwair";
  }, []);

  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const chatId = props.match.params.chatId;

  const messageId = props.match.params.messageId;

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const [errors, setErrors] = useState({});

  // const [searchTerm, setSearchTerm] = useState("");

  const {
    data: { getChatById: fetchedChat } = {},

    loading: loadingChat,
  } = useQuery(FETCH_CHAT_BY_ID_QUERY, {
    variables: { chatId },
  });

  // var staffCustomerId = "";

  // const onChangeText = (e) => {
  //   // [event.target.name]: event.target.value
  //   e.preventDefault();
  //   setSearchTerm(e.target.value);
  // };
  // const clearTextFunction = (e) => {
  //   e.preventDefault();
  //   setSearchTerm("");
  // };

  // const size = useWindowSize();

  const {
    subscribeToMore,
    refetch,
    data: { getMessagesByChat: messages } = {},
    loading: loadingMessages,
  } = useQuery(FETCH_MESSAGES_BY_CHAT_QUERY, {
    variables: { chatId },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  // const myRef = (ref) => {
  //   if (ref) {
  //     // console.log(activeFilters);
  //     ref.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };
  //   useEffect(() => {
  //     myRef();
  //   }, [messages]);

  useEffect(() => {
    subscribeToMore({
      document: FETCH_MESSAGES_BY_CHAT_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const newMessage = subscriptionData.data.chatMessageSent;

        return {
          ...prev,
          getMessagesByChat: [...prev.getMessagesByChat, newMessage],
        };
      },
      variables: { chatId },
    });
  }, [chatId, subscribeToMore]);

  const [hasScrolledToElement, setHasScrolledToElement] = useState(false);

  useEffect(() => {
    function scrollToBottom() {
      animateScroll.scrollToBottom({
        containerId: "customerChat",
        // isDynamic: true,
        // to: "target",
        // offset: 1000,
      });
    }

    function scrollToElement(elementId) {
      animateScroll.scrollTo({
        containerId: elementId,
        // isDynamic: true,
        // to: "target",
        // offset: 1000,
      });
    }
    if (messages && messages.length > 0) {
      if (!hasScrolledToElement && messageId) {
        scrollToElement(messageId);
        setHasScrolledToElement(true);
      } else {
        scrollToBottom();
      }
    }
  }, [messages, messageId, hasScrolledToElement]);

  const { values, onChange, onSubmit, setValues } = useForm(
    sendMessageCallback,
    {
      chatId,
      senderId: customer.id,
      message: null,
      partnerServiceId: null,
      quoteId: null,
      orderMilestoneId: null,
      image: null,
      video: null,
    }
  );

  const [createMessage, { loading: loadingSendMessage }] = useMutation(
    CREATE_MESSAGE_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_MESSAGES_BY_CHAT_QUERY,
          variables: { chatId },
        },
      ],
      update(proxy, { data: { createMessage: messageData } }) {
        // myRef();
        values.senderId = customer.id;
        values.message = null;
        values.partnerServiceId = null;
        values.quoteId = null;
        values.orderMilestoneId = null;
        values.image = null;
        values.video = null;
        setValues(values);
        setErrors({});
      },
      onError(err) {
        console.log(values);
        console.log(err);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: values,
    }
  );

  function sendMessageCallback() {
    console.log(values);
    createMessage();
  }

  const {
    data: { getPendingQuotesByStaffCustomerIdAndChat: pendingQuotes } = {},
    // error,
    // refetch,
    loading: loadingPendingQuotes,
  } = useQuery(FETCH_PENDING_QUOTES_BY_STAFF_CUSTOMER_AND_CHAT_QUERY, {
    variables: {
      chatId,
      staffCustomerId: customer.id,
    },
  });
  // have to figure out messages and remove explore
  if (errors) {
    console.log(errors);
  }
  if (
    !loadingChat &&
    !loadingCustomer &&
    !loadingPendingQuotes &&
    !loadingSendMessage &&
    !fetchedChat
  ) {
    props.history.push("/messages");
  }
  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start overflow-hidden md:p-6">
        <div
          className={`flex flex-col items-start space-y-1 justify-start w-full mb-2 z-10 px-6 pt-6 md:p-0`}
        >
          <h1 className="md:text-white font-thin text-3xl sm:text-5xl md:text-3xl break-words w-full truncate">
            <span className="font-semibold">
              {fetchedChat ? fetchedChat.title : "Chat"}
            </span>
          </h1>

          {fetchedChat &&
            fetchedChat.customerIds &&
            fetchedChat.customerIds.length > 0 && (
              <div className="flex items-center justify-start space-x-1">
                {fetchedChat.customerIds.map((customerId, index) => (
                  <ChatMemberTitle
                    key={index}
                    customerId={customerId}
                    isLast={index + 1 === fetchedChat.customerIds.length}
                    thisCustomerId={customer.id}
                  />
                ))}
              </div>
            )}
        </div>
        <div
          className="flex flex-col items-start justify-start w-full space-y-2 h-full py-2 px-4 md:p-0 md:mt-0 flex-1 overflow-y-auto"
          id="customerChat"
        >
          {messages &&
            messages.length > 0 &&
            messages.map((message, index) => (
              <MessageBox
                // ref={myRef}
                key={index}
                id={message.id}
                message={message}
                senderId={message.senderId}
                customerId={customer.id}
                // ref={
                //   index + 1 === messages.length || messageId === message.id
                //     ? myRef
                //     : null
                // }
                props={props}
              />
            ))}
        </div>

        <div className="flex flex-col items-center justify-center space-y-2 px-4 md:px-0 my-4 md:mt-2 md:mb-0 w-full">
          {pendingQuotes && pendingQuotes.length > 0 && (
            <div className="flex items-center justify-start space-x-2 w-full">
              {pendingQuotes.map((pendingQuote, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(
                      "/writeQuote/" + pendingQuote.quoteRequestId
                    );
                  }}
                  type="button"
                  className="bg-blue-600 hover:bg-blue-700 transition-colors text-white px-4 py-1 rounded-full shadow-lg font-medium"
                >
                  Continue writing Quote v{pendingQuote.versionNumber}
                </button>
              ))}
            </div>
          )}
          <form
            onSubmit={onSubmit}
            autoComplete={false}
            className="flex items-center justify-between bg-white rounded-full overflow-hidden py-2 px-4 w-full shadow-lg"
          >
            <input
              placeholder="Type a message"
              className="w-full focus:outline-none"
              value={values.message ? values.message : ""}
              name="message"
              type="text"
              onChange={onChange}
              autoComplete="off"
            />

            <button
              type="submit"
              disabled={
                loadingMessages ||
                loadingChat ||
                loadingCustomer ||
                loadingPendingQuotes ||
                loadingSendMessage ||
                !values.message
              }
              className={`${
                values.message ? "" : "cursor-default"
              } rounded-full focus:outline-none focus:ring`}
            >
              {loadingMessages ||
              loadingChat ||
              loadingCustomer ||
              loadingPendingQuotes ||
              loadingSendMessage ? (
                <svg
                  className="h-6 animate-spin"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                    stroke="black"
                    strokeWidth="2"
                  />
                  <path
                    d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                    stroke="black"
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className={`fill-current ${
                    values.message
                      ? "text-blue-600 hover:text-blue-700"
                      : "text-gray-300"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.875 12.5L1.8875 3.75L28.125 15L1.8875 26.25L1.875 17.5L20.625 15L1.875 12.5ZM4.3875 7.5375L13.775 11.5625L4.375 10.3125L4.3875 7.5375ZM13.7625 18.4375L4.375 22.4625V19.6875L13.7625 18.4375Z"
                  />
                </svg>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export const FETCH_MESSAGES_BY_CHAT_QUERY = gql`
  query getMessagesByChat($chatId: String!, $customerId: String) {
    getMessagesByChat(chatId: $chatId, customerId: $customerId) {
      id
      createdAt
      message
      quoteId
      partnerServiceId
      orderMilestoneId
      status
      senderId
    }
  }
`;

export const FETCH_CHAT_BY_ID_QUERY = gql`
  query getChatById($chatId: String!) {
    getChatById(chatId: $chatId) {
      id
      title
      createdAt
      customerIds
    }
  }
`;

export const FETCH_CUSTOMER_BY_ID_QUERY = gql`
  query getCustomerById($customerId: String!) {
    getCustomerById(customerId: $customerId) {
      id
      firstName
    }
  }
`;

export const FETCH_SENT_QUOTES_BY_STAFF_CUSTOMER_AND_CHAT_QUERY = gql`
  query getSentQuotesByStaffCustomerIdAndChat(
    $staffCustomerId: String!
    $chatId: String!
  ) {
    getSentQuotesByStaffCustomerIdAndChat(
      staffCustomerId: $staffCustomerId
      chatId: $chatId
    ) {
      id
    }
  }
`;

export const FETCH_PENDING_QUOTES_BY_STAFF_CUSTOMER_AND_CHAT_QUERY = gql`
  query getPendingQuotesByStaffCustomerIdAndChat(
    $staffCustomerId: String!
    $chatId: String!
  ) {
    getPendingQuotesByStaffCustomerIdAndChat(
      staffCustomerId: $staffCustomerId
      chatId: $chatId
    ) {
      id
      versionNumber
      quoteRequestId
    }
  }
`;

const FETCH_MESSAGES_BY_CHAT_SUBSCRIPTION = gql`
  subscription chatMessageSent($chatId: String!) {
    chatMessageSent(chatId: $chatId) {
      id
      createdAt
      message
      quoteId
      partnerServiceId
      orderMilestoneId
      status
    }
  }
`;

const CREATE_MESSAGE_MUTATION = gql`
  mutation createMessage(
    $chatId: String!
    $senderId: String!
    $message: String
    $partnerServiceId: String
    $quoteId: String
    $orderMilestoneId: String
    $image: Upload
    $video: Upload
  ) {
    createMessage(
      chatId: $chatId
      senderId: $senderId
      message: $message
      partnerServiceId: $partnerServiceId
      quoteId: $quoteId
      orderMilestoneId: $orderMilestoneId
      image: $image
      video: $video
    ) {
      id
      createdAt
      message
      quoteId
      partnerServiceId
      orderMilestoneId
      status
    }
  }
`;

export default Chat;
