import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import ChatSummaryCard from "../../components/customer/ChatSummaryCard";
import Footer from "../../components/customer/Footer";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import PageHeader from "../../components/customer/PageHeader";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useWindowSize } from "../../util/hooks";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function Messages(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Messages | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }
  const [errors] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: { getChatsByCustomer: fetchedChats } = {},

    loading: loadingProjects,
  } = useQuery(FETCH_CHATS_BY_CUSTOMER_QUERY, {
    variables: { searchTerm },
  });

  const onChangeText = (e) => {
    // [event.target.name]: event.target.value
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const clearTextFunction = (e) => {
    e.preventDefault();
    setSearchTerm("");
  };

  const size = useWindowSize();

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start overflow-hidden md:p-6">
        <div className="px-6 pt-6 md:mb-2 md:p-0">
          <PageHeader
            props={props}
            titleBold={
              fetchedChats && fetchedChats.length > 0 ? "" : "Messages"
            }
            caption={
              fetchedChats && fetchedChats.length > 0 ? "" : "No messages yet."
            }
          />
          {size.width >= 768 && (
            <ShareSoftwair
              isDarkMode={true}
              title="Share Softwair"
              loadingCustomer={loadingCustomer}
              customer={fetchedCustomer}
              showPartnerIncentive={
                fetchedCustomer && fetchedCustomer.isAPartner
              }
            />
          )}
          {fetchedChats && fetchedChats.length > 0 && (
            <InputField
              fieldTitle="Search"
              errorMsg={errors && errors.searchTerm}
              placeholder="Your messages"
              name="searchTerm"
              onChangeFunction={onChangeText}
              value={searchTerm}
              type="text"
              clearBtnText={"Clear"}
              clearTextFunction={clearTextFunction}
            />
          )}
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-4">
          {fetchedChats &&
            fetchedChats.length > 0 &&
            fetchedChats.map((chat, index) => (
              <ChatSummaryCard
                chat={chat}
                props={props}
                key={chat.id}
                isLast={index === fetchedChats.length - 1}
                isFirst={index === 0}
                staffCustomerId={customer.id}
              />
            ))}
        </div>
        <div className="hidden md:block">
          <button
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/explore");
            }}
            disabled={loadingCustomer || loadingProjects}
            type={"button"}
            className="w-min p-3 shadow-xl hover:bg-gray-300 bg-white rounded-full transition-colors focus:outline-none focus:ring-1 flex items-center justify-center space-x-2 leading-none font-semibold"
          >
            {loadingCustomer || loadingProjects ? (
              <svg
                className="h-6 animate-spin"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                  stroke="black"
                  strokeWidth="2"
                />
                <path
                  d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                  stroke="black"
                  strokeWidth="2"
                />
              </svg>
            ) : (
              <svg
                className="fill-current text-black"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.5 15C2.5 8.1 8.1 2.5 15 2.5C21.9 2.5 27.5 8.1 27.5 15C27.5 21.9 21.9 27.5 15 27.5C8.1 27.5 2.5 21.9 2.5 15ZM5 15C5 20.5125 9.4875 25 15 25C20.5125 25 25 20.5125 25 15C25 9.4875 20.5125 5 15 5C9.4875 5 5 9.4875 5 15ZM8.125 21.875L17.5125 17.5125L21.875 8.125L12.4875 12.4875L8.125 21.875ZM16.375 15C16.375 14.2375 15.7625 13.625 15 13.625C14.2375 13.625 13.625 14.2375 13.625 15C13.625 15.7625 14.2375 16.375 15 16.375C15.7625 16.375 16.375 15.7625 16.375 15Z"
                />
              </svg>
            )}
          </button>
        </div>
        <Footer
          loading={loadingCustomer || loadingProjects}
          imageToDisplay="explore"
          props={props}
          buttonFunction={(e) => {
            e.preventDefault();
            props.history.push("/explore");
          }}
        />
      </div>
    </div>
  );
}

export const FETCH_CHATS_BY_CUSTOMER_QUERY = gql`
  query getChatsByCustomer($customerId: String, $searchTerm: String) {
    getChatsByCustomer(customerId: $customerId, searchTerm: $searchTerm) {
      id
      title
      customerIds
      createdAt
    }
  }
`;

export default Messages;
