import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import React from "react";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import { FETCH_ORDER_MILESTONE_BY_ID_QUERY } from "./EditMilestoneImages";

function OrderMilestoneMessageBox({
  orderMilestoneId,
  isLast,
  isFirst,
  message,
  staffCustomerId,
  props,
}) {
  // const [errors, setErrors] = useState({});
  // const [isDescriptionBoxOpen, setDescriptionBox] = useState(false);

  const {
    data: { getOrderMilestoneById: fetchedOrderMilestone } = {},
    loading,
  } = useQuery(FETCH_ORDER_MILESTONE_BY_ID_QUERY, {
    variables: { orderMilestoneId },
  });

  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  var projectId = "";
  var quoteId = "";
  if (fetchedOrderMilestone) {
    projectId = fetchedOrderMilestone.projectId;
    quoteId = fetchedOrderMilestone.quoteId;
  }

  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: projectId },
    }
  );

  // const [showDetails, setShowDetails] = useState(false);

  const {
    data: { getQuoteById: fetchedQuote } = {},
    // loading: { loadingQuote },
  } = useQuery(FETCH_QUOTE_BY_ID_QUERY, {
    variables: { quoteId },
  });

  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return (
    <div
      className={`flex items-center flex-col justify-center space-y-1 w-full ${
        isLast ? "mb-16 md:mb-0" : ""
      }`}
    >
      <button
        className="bg-white rounded-xl w-full p-4 flex flex-col justify-center items-center space-y-2 focus:outline-none hover:bg-gray-100 focus:ring-1"
        onClick={(e) => {
          e.preventDefault();
          props.history.push("/milestoneDetails/" + orderMilestoneId);
        }}
      >
        {fetchedProject && fetchedOrderMilestone && fetchedQuote && (
          <>
            <div className="flex flex-col md:flex-row items-center justify-center md:justify-between space-y-2 md:space-y-0 md:space-x-4 w-full">
              <div className="flex items-start justify-start flex-col flex-shrink-0 w-full md:w-auto text-left">
                <p className="font-light text-gray-500 w-full leading-snug">
                  {fetchedProject.title} v{fetchedProject.versionNumber}
                </p>
                <p className="text-2xl font-semibold w-full leading-snug">
                  {fetchedOrderMilestone.title}
                  {/* {" "}
                  {fetchedOrderMilestone.status === 1 ? "" : "completed"} */}
                </p>
              </div>
              {mainImageUrl && (
                <img
                  src={mainImageUrl}
                  alt={fetchedProject.title}
                  className="w-full h-16 md:h-12 object-cover rounded-lg overflow-hidden"
                />
              )}
            </div>
          </>
        )}
      </button>

      {fetchedOrderMilestone && (
        <div className="flex items-baseline justify-between w-full px-2 pb-2 space-x-2">
          {loading ? (
            <svg
              className="h-6 animate-spin"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          ) : message.status === 1 ? (
            <p className="uppercase text-blue-600 font-semibold text-sm md:text-base">
              Started
            </p>
          ) : message.status === 2 &&
            staffCustomerId === fetchedOrderMilestone.staffCustomerId ? (
            <p className="uppercase text-blue-600 font-semibold text-sm md:text-base">
              In review
            </p>
          ) : message.status === 2 && fetchedOrderMilestone.status === 2 ? (
            <button
              className="uppercase text-blue-600 font-semibold text-sm md:text-base"
              type="button"
              onClick={(e) => {
                e.preventDefault();

                props.history.push("/milestoneDetails/" + orderMilestoneId);
              }}
            >
              Review &#38; Release Payment
              {/* & Pay CA$
              {getNatural((fetchedOrderMilestone.finalNetAmount / 100).toFixed(2))}.
              {getDecimal((fetchedOrderMilestone.finalNetAmount / 100).toFixed(2))} */}
            </button>
          ) : message.status === 2 ? (
            <p className="uppercase text-blue-600 font-semibold text-sm md:text-base">
              Completed
            </p>
          ) : message.status === 3 ? (
            <div className="uppercase text-green-600 font-semibold text-sm md:text-base">
              Paid CA$
              {getNatural(
                (fetchedOrderMilestone.finalNetAmount / 100).toFixed(2)
              )}
              .
              {getDecimal(
                (fetchedOrderMilestone.finalNetAmount / 100).toFixed(2)
              )}
            </div>
          ) : fetchedOrderMilestone.status === 5 ? (
            <p className="uppercase text-red-600 font-semibold text-sm md:text-base">
              Cancelled
            </p>
          ) : (
            <p className="uppercase text-red-600 font-semibold text-sm md:text-base">
              Refunded
            </p>
          )}

          <p className="text-xs">
            {moment(message.createdAt).format("h:mm a")}
          </p>
        </div>
      )}
    </div>
  );
}

export const FETCH_QUOTE_BY_ID_QUERY = gql`
  query getQuoteById($quoteId: String!) {
    getQuoteById(quoteId: $quoteId) {
      id
      type
      projectId
      customerId
      quoteRequestId
      partnerServiceId
      partnerStaffId
      staffCustomerId
      allQuoteVersionIds
      versionNumber
      notificationReceiverIds
      customerDiscountUsed
      finalSubTotal
      finalDiscount
      finalRefundAmount
      finalNetAmount
      releasedSoFar
      leftToRelease
      currency
      paymentIntentId
      paymentIntentClientSecret
      status
      deadline
      createdAt
      verificationSentAt
      paymentSuccessAt
      quoteSentAt
      expiredAt
      refundedAt
    }
  }
`;

export const FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY = gql`
  query getOrderMilestonesByQuote($quoteId: String!) {
    getOrderMilestonesByQuote(quoteId: $quoteId) {
      id
      quoteRequestId
      quoteId
      staffCustomerId
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      finalSelectedTopLvlInputFieldIds
      finalInputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      finalInputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      finalInputIdsToDates {
        inputFieldId
        dateVal
      }
      finalInputIdsToStrings {
        inputFieldId
        stringVal
      }
      finalInputIdsToInts {
        inputFieldId
        intVal
      }
      finalInputIdsToFloats {
        inputFieldId
        floatVal
      }
      finalInputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      finalInputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      finalInputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      responsiblePartnerServiceId
      doneByPartnerServiceId
      customerDiscountUsed
      finalSubTotal
      finalDiscount
      finalRefundAmount
      finalNetAmount
      startedWorkingAtDate
      deadline
      completedAtDate
      projectId
      customerId
      partnerStaffId
      allMilestoneVersionIds
      versionNumber
      notificationReceiverIds
      transferId
      currency
      status
      createdAt
      verificationSentAt
      paymentSuccessAt
      expiredAt
      refundedAt
    }
  }
`;
export default OrderMilestoneMessageBox;
