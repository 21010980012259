import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { FETCH_CUSTOMER_QUERY } from "../../pages/customer/Settings";
import { useForm } from "../../util/hooks";
import { SAVE_CUSTOMER_DETAILS_MUTATION } from "./CardSelectButton";
import InputField from "./InputField";

export default function AreaCodeEditor({ fetchedCustomer, searchTerm }) {
  const [errors, setErrors] = useState({});

  const { values, onChange } = useForm(saveCustomerDetailsCallback, {
    customerId: fetchedCustomer.id,
    areaCode: fetchedCustomer.areaCode,
  });

  const [saveCustomerDetails, { loading }] = useMutation(
    SAVE_CUSTOMER_DETAILS_MUTATION,
    {
      refetchQueries: [{ query: FETCH_CUSTOMER_QUERY }],
      update(proxy, { data: { saveCustomerDetails: storeData } }) {
        setErrors({});
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: values,
    }
  );
  function saveCustomerDetailsCallback() {
    saveCustomerDetails();
  }
  return (
    "Area code".includes(searchTerm) && (
      <InputField
        additionalBtnFunction={(e) => {
          e.preventDefault();
          saveCustomerDetailsCallback();
        }}
        additionalBtnText={
          !(errors && errors.areaCode) &&
          (loading
            ? "Loading"
            : values.areaCode !== fetchedCustomer.areaCode && "Save")
        }
        disabled={loading}
        onChangeFunction={(e) => {
          onChange(e);
          setErrors({});
        }}
        fieldTitle="Area code*"
        errorMsg={errors && errors.areaCode}
        placeholder="L5M2J3"
        name="areaCode"
        value={values.areaCode}
        type="text"
      />
    )
  );
}
