// import { useQuery } from "@apollo/client";
import moment from "moment";
import React from "react";
import { useWindowSize } from "../../util/hooks";
// import { FETCH_MESSAGE_BY_ORDER_MILESTONE_QUERY } from "./QuoteBox";

export default function OrderMilestoneTrackBox({ orderMilestone, props }) {
  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  // const {
  //   data: { getMessageByOrderMilestone: fetchedMessage } = {},
  // } = useQuery(FETCH_MESSAGE_BY_ORDER_MILESTONE_QUERY, {
  //   variables: { orderMilestoneId: orderMilestone.id },
  // });

  const size = useWindowSize();

  return (
    <button
      className="w-full bg-gray-100 hover:bg-gray-200 p-4 flex items-center justify-between space-x-2 text-sm"
      onClick={(e) => {
        e.preventDefault();
        // if (fetchedMessage) {
        props.history.push("/milestoneDetails/" + orderMilestone.id);
        // }
        // go to chat with this quote props.history.push("/chat/"+quote)
      }}
    >
      <div className="flex flex-col items-start justify-start text-left truncate">
        <p className="font-semibold truncate w-full">{orderMilestone.title}</p>
        <p className="text-gray-400">
          {orderMilestone.status === 0
            ? "Not started yet"
            : orderMilestone.status === 1
            ? "In progress"
            : orderMilestone.status === 2
            ? "Completed"
            : orderMilestone.status === 3
            ? "Paid"
            : orderMilestone.status === 4
            ? "Refunded"
            : "Cancelled"}
        </p>
      </div>
      <div className="flex items-end justify-end flex-col flex-shrink-0">
        <p className="rounded-full bg-gray-400 text-white px-2">
          CA${getNatural((orderMilestone.finalNetAmount / 100).toFixed(2))}.
          {getDecimal((orderMilestone.finalNetAmount / 100).toFixed(2))}
        </p>
        <p className="text-gray-400">
          {orderMilestone.status <= 1 &&
            (size.width >= 768 ? "Deadline: " : "By ")}
          {orderMilestone.status <= 1
            ? moment(orderMilestone.deadline).format("D MMM, y")
            : orderMilestone.status === 2
            ? moment(orderMilestone.completedAtDate).format("D MMM, y")
            : orderMilestone.status === 3
            ? moment(orderMilestone.paymentSuccessAt).format("D MMM, y")
            : orderMilestone.status === 4
            ? moment(orderMilestone.refundedAt).format("D MMM, y")
            : moment(orderMilestone.cancelledAt).format("D MMM, y")}
        </p>
      </div>
    </button>
  );
}
