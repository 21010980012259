import { gql, useMutation, useQuery } from "@apollo/client";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import InputField from "../../components/customer/InputField";
import PageHeader from "../../components/customer/PageHeader";
import { AuthContext } from "../../context/auth";
import { FETCH_CUSTOMER_QUERY } from "../../pages/customer/Settings";
import {
  FETCH_QUOTES_BY_PROJECT_QUERY,
  FETCH_QUOTE_REQUESTS_BY_PROJECT_QUERY,
} from "../../pages/customer/TrackProject";
import { useForm } from "../../util/hooks";
import CheckButton from "./CheckButton";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import HomeButton from "./HomeButton";
import RadioInputsWithTitle from "./RadioInputsWithTitle";
import ShareSoftwair from "./ShareSoftwair";
import SocialsFooter from "./SocialsFooter";

function GetQuotesElement({ props }) {
  const { customer } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  const projectId = props.match.params.projectId;

  const {
    data: { getProjectById: fetchedProject } = {},
    loading: loadingProject,
  } = useQuery(FETCH_PROJECT_BY_ID_QUERY, {
    variables: { projectId },
  });

  const stripe = useStripe();
  const elements = useElements();

  const [errors, setErrors] = useState({});

  const { onChange, values } = useForm(createQuoteRequestCallback, {
    projectId,
    type: "Production",
    message: "",
    unitsNeeded: 1,
    // useNewCard: false,
  });

  const [createQuoteRequest, { loading }] = useMutation(CREATE_QUOTE_REQUEST, {
    refetchQueries: [
      {
        query: FETCH_QUOTE_REQUESTS_BY_PROJECT_QUERY,
        variables: { projectId },
      },
      { query: FETCH_CUSTOMER_QUERY },

      { query: FETCH_QUOTES_BY_PROJECT_QUERY, variables: { projectId } },
    ],
    async update(_, { data: { createQuoteRequest: quoteRequestData } }) {
      setErrors({});

      props.history.push(`/trackProject/${projectId}`);
    },
    onError(err) {
      // setLoadingWholePayment(false);
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function createQuoteRequestCallback() {
    createQuoteRequest();
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setErrors({});

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // setLoadingWholePayment(true);

    // add addresses to orders/make sure they have addresses
    createQuoteRequest();
  };

  if (!loadingProject && !loading && !loadingCustomer && !fetchedProject) {
    props.history.push("/projects");
  }
  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={false}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        {/* {curStep === 1 ? ( */}
        <div className="px-6 py-6 md:mb-2 md:p-0 w-full">
          <PageHeader
            props={props}
            titleBold={`Get quotes`}
            // caption={`Request design refinement or production quotes ${
            //   fetchedProject
            //     ? " for " +
            //       fetchedProject.title +
            //       " v" +
            //       fetchedProject.versionNumber
            //     : ""
            // }.`}
            hideHomeButton={true}
          />
        </div>
        {/* ) : (
          <PageHeader
            props={props}
            titleThin={
              values.type === "design"
                ? "Confirm Design Request"
                : "Confirm Production Request"
            }
            caption="Receive curated quotations in your email within two days.  Safe payments, safe working conditions, endless non-disclosure agreement."
          />
        )} */}
        <div className="flex flex-col space-y-4 items-start justify-start w-full overflow-y-auto px-6 md:p-0 flex-1">
          <InputField
            fieldTitle="Project"
            value={
              fetchedProject &&
              fetchedProject.title + " v" + fetchedProject.versionNumber
            }
            placeholder="Title v0"
            type="text"
            disabled={true}
          />

          <RadioInputsWithTitle
            fieldTitle="Request type*"
            errorMsg={errors && errors.type}
            hint={
              fetchedProject &&
              `Design refinements will help make ${fetchedProject.title} more sustainable and production ready. Production means actually making ${fetchedProject.title} according to your requirements.`
            }
            name="type"
            onChangeFunction={onChange}
            value={values.type}
            options={["Design refinement", "Production"]}
            convertValToNumber={false}
          />

          {values.type === "Production" && (
            <InputField
              fieldTitle="Quantity*"
              errorMsg={errors && errors.unitsNeeded}
              placeholder={1}
              name="unitsNeeded"
              onChangeFunction={onChange}
              value={values.unitsNeeded}
              type="range"
              rangeMin={1}
              rangeStep={1}
              rangeMax={200}
            />
          )}
          <InputField
            fieldTitle="Message (optional)"
            errorMsg={errors && errors.message}
            placeholder="This can be used in multiple forms of architecture."
            name="message"
            onChangeFunction={onChange}
            value={values.message}
            type="textarea"
            rows={3}
          />
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <CheckButton
            btnFunction={handleSubmit}
            loading={
              loading || !stripe || loadingCustomer || loadingProject
              //  ||
              // loadingWholePayment
            }
            type={"button"}
          />
        </div>
      </div>
    </div>
  );
}

const CREATE_QUOTE_REQUEST = gql`
  mutation createQuoteRequest(
    $projectId: String!
    $message: String
    $unitsNeeded: Int
    # $useNewCard: Boolean!
    $type: String!
  ) {
    createQuoteRequest(
      projectId: $projectId
      type: $type
      message: $message
      unitsNeeded: $unitsNeeded # useNewCard: $useNewCard
    ) {
      id
    }
  }
`;

export default GetQuotesElement;
