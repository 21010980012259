import { useQuery } from "@apollo/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import GetQuotesElement from "../../components/customer/GetQuotesElement";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_NlZLhehUO2NfdIIDpOww0Qoo001nXLnzWh"
    : "pk_test_ITst5V2FKIZmXZhib3REV0Pb00cN3nJ5cf"
);

export default function GetQuotes(props) {
  const location = useLocation();

  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Get Quotes | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }
  return (
    <Elements stripe={stripePromise}>
      <GetQuotesElement props={props} />
    </Elements>
  );
}
