import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
// import moment from "moment";
import React, { useState } from "react";
import { FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY } from "../../pages/customer/FindJobs";
import { FETCH_QUOTE_REQUEST_BY_ID_QUERY } from "../../pages/customer/JobDetails";
import {
  FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
  FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
} from "../../pages/customer/Jobs";
import {
  FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
  FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
} from "../../pages/customer/WriteQuote";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import TextAndValue from "./TextAndValue";

// import QuoteQuickDisplayBox from "./QuoteQuickDisplayBox";
// import TextAndValue from "./TextAndValue";

function JobRequestBox({ quoteRequest, props, invertTitleColours }) {
  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: quoteRequest.projectId },
    }
  );
  console.log(quoteRequest.projectId);
  const [errors, setErrors] = useState({});

  if (errors) {
    console.log(errors);
  }

  const [rejectQuoteRequest, { loading }] = useMutation(
    REJECT_QUOTE_REQUEST_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
        },
        {
          query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
      ],

      update(_, { data: { rejectQuoteRequest: projectData } }) {
        // refetch projects
        // props.history.push("/projects");
        props.history.push("/jobs");
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: {
        quoteRequestId: quoteRequest.id,
      },
    }
  );

  const [createQuote, { loading: loadingQuote }] = useMutation(
    CREATE_QUOTE_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
        },
        {
          query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUEST_BY_ID_QUERY,
          variables: { quoteRequestId: quoteRequest.id },
        },
        {
          query: FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
          variables: { quoteRequestId: quoteRequest.id },
        },
        {
          query: FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
          variables: { quoteRequestId: quoteRequest.id },
        },
      ],

      update(_, { data: { createQuote: quoteData } }) {
        props.history.push("/writeQuote/" + quoteRequest.id);
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: { quoteRequestId: quoteRequest.id },
    }
  );

  const createQuoteCallback = (e) => {
    e.preventDefault();
    createQuote();
  };

  var quotationReceivedAtTime;
  var quotationReceivedAtDay;
  var quoteRequestExprDay;
  var quoteRequestExprTime;

  quotationReceivedAtTime = moment(quoteRequest.createdAt)
    .format("h:mma")
    .slice(0, -1);
  quotationReceivedAtDay = moment(quoteRequest.createdAt).format("D MMM, y");

  quoteRequestExprTime = moment(quoteRequest.expiredAt)
    .format("h:mma")
    .slice(0, -1);
  quoteRequestExprDay = moment(quoteRequest.expiredAt).format("D MMM, y");

  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return fetchedProject ? (
    <div
      className={`${
        invertTitleColours
          ? "bg-white md:mb-0 md:bg-gray-200 md:bg-opacity-20"
          : "bg-gray-100 md:bg-gray-200 bg-opacity-20"
      } rounded-xl w-full shadow-xl`}
    >
      <div
        className={`p-2 ${
          invertTitleColours ? "text-black md:text-white" : "text-white"
        }  md:text-black text-center text-sm`}
      >
        <button
          className="font-semibold text-base text-blue-700 hover:text-blue-500 md:text-blue-200 md:hover:text-blue-300"
          onClick={(e) => {
            e.preventDefault();
            props.history.push(
              "/quoteRequestDetails/" +
                quoteRequest.id +
                "/" +
                quoteRequest.projectId
            );
          }}
        >
          {fetchedProject.title} v{fetchedProject.versionNumber}
        </button>
        <p className="font-medium">{quoteRequest.type} request sent</p>
        <p className="font-light">You'll receive quotes very soon!</p>
      </div>

      <div
        className="rounded-t-xl bg-gray-100 md:bg-opacity-50 py-4 flex flex-col items-center justify-center space-y-2"
        style={{ backdropFilter: "blur(40px)" }}
      >
        <div className="w-full flex items-center justify-between space-x-4 px-4 overflow-hidden">
          <div className="flex flex-col items-start justify-start text-sm">
            <p className="font-medium text-blue-500 md:text-gray-700">
              Received at
            </p>
            <p className="text-black md:text-white text-3xl">
              {quotationReceivedAtTime}
            </p>
            <p className="text-blue-500 md:text-gray-700 font-medium">
              {quotationReceivedAtDay}
            </p>
          </div>

          <svg
            width="10"
            height="18"
            viewBox="0 0 10 18"
            className="text-black md:text-white fill-current flex-shrink-0"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.94006 0.5L0.0600586 2.4975L6.16672 9L0.0600586 15.5025L1.94006 17.5L9.94006 9L1.94006 0.5Z" />
          </svg>

          <div className="flex flex-col items-end justify-end text-sm">
            <p className="font-medium text-blue-500 md:text-gray-700">
              Expires at
            </p>
            <p className="text-black md:text-white text-3xl">
              {quoteRequestExprTime}
            </p>
            <p className="text-blue-500 md:text-gray-700 font-medium">
              {quoteRequestExprDay}
            </p>
          </div>
        </div>

        {mainImageUrl && (
          <button
            className="px-4 focus:outline-none focus:ring-1 rounded-xl"
            onClick={(e) => {
              e.preventDefault();
              props.history.push(
                "/quoteRequestDetails/" +
                  quoteRequest.id +
                  "/" +
                  quoteRequest.projectId
              );
            }}
          >
            <img
              src={mainImageUrl}
              alt={fetchedProject.title}
              className="w-full h-full max-h-24 rounded-xl shadow-lg object-cover hover:opacity-90"
            />
          </button>
        )}
      </div>

      <div className="bg-white p-2 overflow-hidden grid grid-flow-col">
        <TextAndValue text="Quotes" value={quoteRequest.numOfQuotesReceived} />

        <TextAndValue text="Views" value={quoteRequest.numOfViews} />

        <TextAndValue text="Rejections" value={quoteRequest.numOfRejections} />
      </div>

      <div className="flex items-center justify-center w-full overflow-hidden relative bg-white h-full rounded-b-xl">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            rejectQuoteRequest();
          }}
          disabled={loading || loadingQuote}
          className="bg-red-500 hover:bg-red-700 transition-colors w-1/3 py-2 h-full"
        >
          <p className="font-semibold text-lg text-white">Reject</p>
          {/* <svg
            className="fill-current text-white h-5"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
          </svg> */}
        </button>
        <button
          type="button"
          onClick={createQuoteCallback}
          disabled={loading || loadingQuote}
          className="bg-blue-700 hover:bg-blue-500 transition-colors w-full py-2"
        >
          <p className="font-semibold text-lg text-white">Write quote</p>
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}

export const REJECT_QUOTE_REQUEST_MUTATION = gql`
  mutation rejectQuoteRequest($quoteRequestId: String!) {
    rejectQuoteRequest(quoteRequestId: $quoteRequestId) {
      id

      createdAt
    }
  }
`;

export const CREATE_QUOTE_MUTATION = gql`
  mutation createQuote($quoteRequestId: String!, $partnerStaffId: String) {
    createQuote(
      quoteRequestId: $quoteRequestId
      partnerStaffId: $partnerStaffId
    ) {
      id

      createdAt
    }
  }
`;
export default JobRequestBox;
