import React from "react";

export default function TextAndValue({ text, value }) {
  return (
    <div className="flex flex-col items-start justify-between space-y-1 text-sm">
      <p className="text-gray-400">{text}</p>

      <p className="font-semibold">{value}</p>
    </div>
  );
}
