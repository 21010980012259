import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import CheckButton from "../../components/customer/CheckButton";
import { FETCH_PROJECT_BY_ID_QUERY } from "../../components/customer/DeleteProjectMedia";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import InputFieldsForSelectedTopLevelField from "../../components/customer/InputFieldsForSelectedTopLevelField";
import InputTitle from "../../components/customer/InputTitle";
import MiniProjectCard from "../../components/customer/MiniProjectCard";
import PageHeader from "../../components/customer/PageHeader";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";
import {
  CREATE_PROJECT,
  FETCH_SUGGEST_DETAILS_BY_DESC_QUERY,
  FETCH_SUGGEST_SUB_PARTS_BY_DESC_QUERY,
} from "./CreateProject";
import { FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY } from "./ProjectDetails";
import { FETCH_PROJECTS_BY_CUSTOMER_QUERY } from "./Projects";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function EditProject(props) {
  const location = useLocation();

  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Edit Project | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const projectId = props.match.params.projectId;
  const {
    data: { getProjectById: fetchedProject } = {},
    loading: loadingProject,
  } = useQuery(FETCH_PROJECT_BY_ID_QUERY, {
    variables: { projectId },
  });

  const [errors, setErrors] = useState({});

  const { onChange, values, setValues, onArrayChange } = useForm(
    createProjectCallback,
    {
      editProjectId: projectId ? projectId : null,
      parentProjectId: null,
      title: "",
      shortDescription: "",
      selectedTopLvlInputFieldIds: [],

      subParts: [],
    }
  );

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    refetchQueries:
      fetchedProject &&
      fetchedProject.parentProjectIds &&
      fetchedProject.parentProjectIds.length > 0
        ? [
            {
              query: FETCH_PROJECTS_BY_CUSTOMER_QUERY,
              variables: { searchTerm: "" },
            },
            {
              query: FETCH_PROJECT_BY_ID_QUERY,
              variables: { projectId },
            },
            {
              query: FETCH_PROJECT_BY_ID_QUERY,
              variables: {
                projectId:
                  fetchedProject.parentProjectIds[
                    fetchedProject.parentProjectIds.length - 1
                  ],
              },
            },
            {
              query: FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY,
              variables: {
                parentProjectId:
                  fetchedProject.parentProjectIds[
                    fetchedProject.parentProjectIds.length - 1
                  ],
                searchTerm: "",
              },
            },
          ]
        : [
            { query: FETCH_PROJECT_BY_ID_QUERY, variables: { projectId } },
            {
              query: FETCH_PROJECTS_BY_CUSTOMER_QUERY,
              variables: { searchTerm: "" },
            },
          ],

    update(_, { data: { createProject: projectData } }) {
      // refetch projects
      console.log(values);
      if (
        fetchedProject &&
        fetchedProject.parentProjectIds &&
        fetchedProject.parentProjectIds.length > 0
      ) {
        props.history.push(
          "/projectDetails/" +
            fetchedProject.parentProjectIds[
              fetchedProject.parentProjectIds.length - 1
            ]
        );
      } else {
        props.history.push("/projects");
      }
      props.history.push("/projects");
    },
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function createProjectCallback() {
    createProject();
  }

  const {
    data: { getSuggestedSubPartsByDesc: suggestedSubParts } = {},
    // error,
    loading: loadingSuggestedSubparts,
  } = useQuery(FETCH_SUGGEST_SUB_PARTS_BY_DESC_QUERY, {
    variables: {
      description: values.title + " " + values.shortDescription,
      projectId,
    },
  });

  const [inputsAreAdjusted, setInputsAreAdjusted] = useState(false);

  useEffect(() => {
    if (fetchedProject && !inputsAreAdjusted) {
      setValues({
        ...values,
        ...fetchedProject,
        // parentProjectId: null,
        // title: fetchedProject.title || "",
        // shortDescription: fetchedProject.shortDescription || "",
        // selectedTopLvlInputFieldIds:
        //   fetchedProject.selectedTopLvlInputFieldIds || [],
        // inputIdsToStrings: fetchedProject.inputIdsToStrings || [],
        // inputIdsToInts: fetchedProject.inputIdsToInts || [],
        // inputIdsToFloats: fetchedProject.inputIdsToFloats || [],
        // inputIdsToUploadArrays: fetchedProject.inputIdsToUploadArrays || [],
        // inputIdsToStringArrays: fetchedProject.inputIdsToStringArrays || [],
        // inputIdsToIntArrays: fetchedProject.inputIdsToIntArrays || [],
        // inputIdsToFloatArrays: fetchedProject.inputIdsToFloatArrays || [],
        // subParts: fetchedProject.subParts || [],
      });
      setInputsAreAdjusted(true);
    }
  }, [fetchedProject, setValues, values, inputsAreAdjusted]);
  if (
    !loadingProject &&
    !loading &&
    !loadingCustomer &&
    !loadingSuggestedSubparts &&
    !fetchedProject
  ) {
    props.history.push("/projects");
  }

  const [searchTerm, setSearchTerm] = useState("");

  const onChangeText = (name, text) => {
    setSearchTerm(text);
  };
  const clearTextFunction = () => {
    setSearchTerm("");
  };
  const {
    data: { getProjectSubPartsByCustomerId: fetchedSubProjects } = {},
    // refetch,
  } = useQuery(FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY, {
    variables: { searchTerm, parentProjectId: projectId },
  });

  const {
    data: { getSuggestedInputFieldsByDesc: topLvlSuggestedDetails } = {},
    // error,
    loading: loadingSuggestedDetails,
  } = useQuery(FETCH_SUGGEST_DETAILS_BY_DESC_QUERY, {
    variables: {
      description: values.title + " " + values.shortDescription,
      isTopLevel: true,
      selectedTopLvlInputFieldIds: values.selectedTopLvlInputFieldIds,
    },
  });
  const [prevTopLvlSuggestedDetails, setPrevTopLvlSuggestedDetails] = useState(
    []
  );

  useEffect(() => {
    if (
      topLvlSuggestedDetails &&
      topLvlSuggestedDetails !== prevTopLvlSuggestedDetails
    ) {
      setPrevTopLvlSuggestedDetails(topLvlSuggestedDetails);
    }
  }, [topLvlSuggestedDetails, prevTopLvlSuggestedDetails]);

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 pt-6 md:mb-2 md:p-0">
          <PageHeader
            props={props}
            titleBold={"Edit"}
            hideHomeButton={true}
            // padTop={true} // caption="Fill out this form to save your idea. Later, you can get quotations for it within two days."
          />
        </div>
        {fetchedProject && (
          <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-4">
            <InputField
              fieldTitle={`Title* ${
                values.title ? `(${values.title.length}/30)` : ""
              }`}
              errorMsg={errors && errors.title}
              placeholder="Name your invention"
              name="title"
              onChangeFunction={onChange}
              value={values.title}
              type="text"
              keepAutoCompleteOff={true}
              maxLength="30"
            />
            <InputField
              fieldTitle={`Short description* ${
                values.shortDescription
                  ? `(${values.shortDescription.length}/100)`
                  : ""
              }`}
              errorMsg={errors && errors.shortDescription}
              placeholder="What are you making?"
              name="shortDescription"
              onChangeFunction={onChange}
              value={values.shortDescription}
              type="textarea"
              rows={"3"}
              maxLength="100"
            />

            {((topLvlSuggestedDetails && topLvlSuggestedDetails.length > 0) ||
              (prevTopLvlSuggestedDetails &&
                prevTopLvlSuggestedDetails.length > 0)) && (
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <InputTitle
                  fieldTitle={"Details (optional)"}
                  errorMsg={errors && errors.selectedTopLvlInputFieldIds}
                  hint={
                    "Add details by clicking one of the options below. Click again to remove that detail."
                  }
                />

                <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                  {topLvlSuggestedDetails &&
                    topLvlSuggestedDetails.length > 0 &&
                    topLvlSuggestedDetails.map(
                      (topLvlSuggestedDetail, index) => (
                        <InputField
                          name={"selectedTopLvlInputFieldIds"}
                          onChangeFunction={onArrayChange}
                          checkboxValue={topLvlSuggestedDetail.id}
                          type="checkbox"
                          value={values.selectedTopLvlInputFieldIds}
                          checkboxTitle={topLvlSuggestedDetail.title}
                          key={index}
                        />
                      )
                    )}
                  {(!topLvlSuggestedDetails ||
                    topLvlSuggestedDetails.length === 0) &&
                    prevTopLvlSuggestedDetails &&
                    prevTopLvlSuggestedDetails.length > 0 &&
                    prevTopLvlSuggestedDetails.map(
                      (prevTopLvlSuggestedDetail, index) => (
                        <InputField
                          name={"selectedTopLvlInputFieldIds"}
                          onChangeFunction={onArrayChange}
                          checkboxValue={prevTopLvlSuggestedDetail.id}
                          type="checkbox"
                          value={values.selectedTopLvlInputFieldIds}
                          checkboxTitle={prevTopLvlSuggestedDetail.title}
                          key={index}
                          disabled={true}
                        />
                      )
                    )}
                </div>
              </div>
            )}

            {values.selectedTopLvlInputFieldIds &&
              values.selectedTopLvlInputFieldIds.length > 0 &&
              values.selectedTopLvlInputFieldIds.map(
                (topLvlInputFieldId, index) => (
                  <InputFieldsForSelectedTopLevelField
                    key={index}
                    onChange={onChange}
                    topLvlInputFieldId={topLvlInputFieldId}
                    errors={errors}
                    values={values}
                    setValues={setValues}
                  />
                )
              )}

            {((fetchedSubProjects && fetchedSubProjects.length > 0) ||
              searchTerm) && (
              <InputField
                fieldTitle="Search"
                errorMsg={errors && errors.searchTerm}
                placeholder="Sub parts"
                name="searchTerm"
                onChangeFunction={onChangeText}
                value={searchTerm}
                type="text"
                clearBtnText={"Clear"}
                clearTextFunction={clearTextFunction}
              />
            )}
            <div className="flex-shrink-0 flex items-center justify-start space-x-4 w-full overflow-x-auto">
              {fetchedSubProjects &&
                fetchedSubProjects.length > 0 &&
                fetchedSubProjects.map((subProject, index) => (
                  <MiniProjectCard props={props} project={subProject} />
                ))}
              <div className="flex flex-col items-start justify-center space-y-2">
                {!fetchedSubProjects ||
                  (fetchedSubProjects.length === 0 && (
                    <InputTitle fieldTitle="Sub parts" />
                  ))}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/createProject/" + projectId);
                  }}
                  className={`text-blue-600 hover:bg-gray-200 border-blue-600 bg-white md:bg-black md:hover:bg-gray-700 md:text-white focus:outline-none focus:ring-1 flex-grow-0 text-center cursor-pointer border-2 md:border-0 font-normal rounded-full p-2`}
                >
                  <svg
                    className="fill-current"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.1376 15.831H15.831V23.1375H13.3955V15.831H6.08887V13.3954H13.3955V6.08884H15.831V13.3954H23.1376V15.831Z" />
                  </svg>
                </button>
              </div>
            </div>

            {/* Sub parts */}
            {fetchedProject &&
              values.shortDescription &&
              values.title &&
              suggestedSubParts &&
              suggestedSubParts.length > 0 && (
                <div className="flex flex-col items-start justify-start w-full space-y-2">
                  <InputTitle
                    fieldTitle="Suggested sub parts (optional)"
                    errorMsg={errors && errors.details}
                    hint={`Add parts you might need with ${values.title}. You can edit them or add more later on.`}
                  />

                  <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                    {suggestedSubParts.map((subPartName, index) => (
                      <InputField
                        name="subParts"
                        onChangeFunction={onArrayChange}
                        checkboxValue={subPartName}
                        type="checkbox"
                        value={values.subParts}
                        checkboxTitle={subPartName}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              )}
          </div>
        )}
        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <CheckButton
            loading={
              loading ||
              loadingSuggestedDetails ||
              loadingCustomer ||
              loadingSuggestedSubparts ||
              loadingProject
            }
            btnFunction={createProjectCallback}
            type="button"
          />
        </div>
      </div>
    </div>
  );
}

export default EditProject;
