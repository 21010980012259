import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import React from "react";

function QuoteQuickDisplayBox({ quote, props }) {
  const { data: { getPartnerServiceById: fetchedPartnerService } = {} } =
    useQuery(FETCH_PARTNER_SERVICE_BY_ID_QUERY, {
      variables: { partnerServiceId: quote.partnerServiceId },
    });

  const { data: { getMessageByQuote: fetchedMessage } = {} } = useQuery(
    FETCH_MESSAGE_BY_QUOTE_QUERY,
    {
      variables: { quoteId: quote.id },
    }
  );

  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  return fetchedPartnerService && fetchedMessage ? (
    <button
      className="w-full bg-gray-100 hover:bg-gray-200 p-4 flex items-center justify-between text-sm"
      onClick={(e) => {
        e.preventDefault();
        if (fetchedMessage) {
          props.history.push(
            "/chat/" + fetchedMessage.chatId + "/" + fetchedMessage.id
          );
        }
        // go to chat with this quote props.history.push("/chat/"+quote)
      }}
    >
      <div className="flex flex-col items-start justify-start text-left">
        <p className="font-semibold ">{fetchedPartnerService.businessName}</p>
        <p className="text-gray-400">
          {fetchedPartnerService.city}, {fetchedPartnerService.region}
        </p>
      </div>
      <div className="flex items-end justify-end flex-col">
        <p className="rounded-full bg-gray-400 text-white px-2">
          CA${getNatural((quote.finalNetAmount / 100).toFixed(2))}.
          {getDecimal((quote.finalNetAmount / 100).toFixed(2))}
        </p>
        <p className="text-gray-400">
          By {moment(quote.deadline).format("D MMM, y")}
        </p>
      </div>
    </button>
  ) : (
    <></>
  );
}

export const FETCH_PARTNER_SERVICE_BY_ID_QUERY = gql`
  query getPartnerServiceById($partnerServiceId: String!) {
    getPartnerServiceById(partnerServiceId: $partnerServiceId) {
      id
      businessName
      city
      region
      country
      servicesProvided
      images
    }
  }
`;

export const FETCH_MESSAGE_BY_QUOTE_QUERY = gql`
  query getMessageByQuote($quoteId: String!) {
    getMessageByQuote(quoteId: $quoteId) {
      id
      chatId
    }
  }
`;

export default QuoteQuickDisplayBox;
