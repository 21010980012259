import React from "react";
import InputField from "./InputField";
import InputTitle from "./InputTitle";

export default function RadioInputsWithTitle({
  errorMsg,
  fieldTitle,
  hint,
  onChangeFunction,
  value,
  options,
  name,
  convertValToNumber,
  disabled,
}) {
  // console.log(options);

  return (
    <div className="flex flex-col items-start justify-start w-full space-y-2">
      <InputTitle fieldTitle={fieldTitle} errorMsg={errorMsg} hint={hint} />

      <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
        {disabled ? (
          <InputField
            name={name}
            value={value}
            title={fieldTitle}
            disabled={disabled}
          />
        ) : (
          options &&
          options.map((option, index) => (
            <InputField
              name={name}
              onChangeFunction={onChangeFunction}
              radioValue={option}
              type="radio"
              value={value}
              radioTitle={option}
              key={index}
              convertValToNumber={convertValToNumber}
              disabled={disabled}
            />
          ))
        )}
      </div>
    </div>
  );
}
