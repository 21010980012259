import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import { FETCH_PARTNER_SERVICES_BY_STAFF_QUERY } from "../../components/customer/AddOrderMilestoneElement";
import Footer from "../../components/customer/Footer";
import HomeButton from "../../components/customer/HomeButton";
import InProgressQuoteCard from "../../components/customer/InProgressQuoteCard";
import InputField from "../../components/customer/InputField";
import NextButton from "../../components/customer/NextButton";
import PageHeader from "../../components/customer/PageHeader";
import PartnerServiceBox from "../../components/customer/PartnerServiceBox";
import PartnerWithUsBox from "../../components/customer/PartnerWithUsBox";
import PendingQuoteCard from "../../components/customer/PendingQuoteCard";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useWindowSize } from "../../util/hooks";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function Jobs(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Jobs | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const [errors] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: { getInProgressJobQuotesByCustomerId: fetchedQuotes } = {},

    loading: loadingInProgressJobQuotes,
  } = useQuery(FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY, {
    variables: { searchTerm },
  });

  const {
    data: { getPendingQuotesByCustomerId: fetchedPendingQuotes } = {},
    loading: loadingPendingQuotes,
  } = useQuery(FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY, {
    variables: { searchTerm },
  });

  const {
    data: { getPartnerServicesByStaff: partnerServices } = {},
    loading: loadingPartnerServices,
  } = useQuery(FETCH_PARTNER_SERVICES_BY_STAFF_QUERY, {});

  const onChangeText = (e) => {
    // [event.target.name]: event.target.value
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const clearTextFunction = (e) => {
    e.preventDefault();
    setSearchTerm("");
  };

  if (partnerServices) {
    console.log(partnerServices);
  }

  const size = useWindowSize();
  // console.log(fetchedCustomer);
  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={true}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start overflow-hidden md:p-6">
        <div className="bg-blue-800 w-full h-1/3 absolute top-0 left-0 right-0 md:hidden"></div>
        <div className="px-6 pt-6 md:mb-2 md:p-0 z-10">
          <PageHeader
            props={props}
            titleBold={
              !(
                (fetchedQuotes && fetchedQuotes.length > 0) ||
                (fetchedPendingQuotes && fetchedPendingQuotes.length > 0) ||
                searchTerm
              )
                ? "Jobs"
                : ""
            }
            caption={
              fetchedCustomer && !fetchedCustomer.isAPartnerStaffMember
                ? ""
                : (!fetchedPendingQuotes ||
                    fetchedPendingQuotes.length === 0) &&
                  (!fetchedQuotes || fetchedQuotes.length === 0)
                ? `Click ${
                    size.width >= 768 ? "the button" : "+"
                  } below to find jobs.`
                : ""
            }
            // link={
            //   fetchedCustomer &&
            //   fetchedCustomer.isAPartnerStaffMember &&
            //   !(
            //     (fetchedQuotes && fetchedQuotes.length > 0) ||
            //     (fetchedPendingQuotes && fetchedPendingQuotes.length > 0) ||
            //     searchTerm
            //   )
            //     ? "/findJobs"
            //     : ""
            // }
            // linkMsg={
            //   fetchedCustomer &&
            //   fetchedCustomer.isAPartnerStaffMember &&
            //   !(
            //     (fetchedQuotes && fetchedQuotes.length > 0) ||
            //     (fetchedPendingQuotes && fetchedPendingQuotes.length > 0) ||
            //     searchTerm
            //   )
            //     ? "Add Jobs"
            //     : ""
            // }
            isWhite={true}
          />

          {size.width >= 768 && (
            <ShareSoftwair
              isDarkMode={true}
              title="Share Softwair"
              loadingCustomer={loadingCustomer}
              customer={fetchedCustomer}
              showPartnerIncentive={
                fetchedCustomer && fetchedCustomer.isAPartner
              }
            />
          )}
          {((fetchedQuotes && fetchedQuotes.length > 0) ||
            (fetchedPendingQuotes && fetchedPendingQuotes.length > 0) ||
            searchTerm) && (
            <InputField
              fieldTitle="Search"
              errorMsg={errors && errors.searchTerm}
              placeholder="Your jobs"
              name="searchTerm"
              onChangeFunction={onChangeText}
              value={searchTerm}
              type="text"
              clearBtnText={"Clear"}
              clearTextFunction={clearTextFunction}
              blueBack={size.width >= 768 ? false : true}
            />
          )}
        </div>
        <div className="flex flex-col overflow-y-auto overflow-x-hidden mt-6 md:my-2 z-10 w-full flex-1">
          {((fetchedQuotes && fetchedQuotes.length > 0) ||
            (fetchedPendingQuotes && fetchedPendingQuotes.length > 0) ||
            searchTerm) && (
            <div
              className={`flex-shrink-0 pb-4 flex flex-col md:flex-row md:space-y-0 md:space-x-4 space-y-4 items-center justify-start w-full overflow-y-auto md:overflow-x-auto z-10 px-4 md:px-0`}
            >
              {fetchedPendingQuotes &&
                fetchedPendingQuotes.length > 0 &&
                fetchedPendingQuotes.map((pendingQuote, index) => (
                  <PendingQuoteCard
                    pendingQuote={pendingQuote}
                    props={props}
                    key={pendingQuote.id}
                    isLast={false}
                    // isLast={index === fetchedPendingQuotes.length - 1}
                    isFirst={index === 0}
                  />
                ))}
              {
                fetchedQuotes &&
                  fetchedQuotes.length > 0 &&
                  // <div className="flex flex-col space-y-4 md:flex-row w-full md:items-center md:justify-start md:space-x-4 md:space-y-0">
                  fetchedQuotes.map((quote, index) => (
                    <InProgressQuoteCard
                      quote={quote}
                      props={props}
                      key={quote.id}
                      isLast={false}
                      // isLast={index === fetchedQuotes.length - 1}
                      isFirst={index === 0}
                    />
                  ))
                // </div>
              }
              {/* <div className="flex space-x-4 items-center justify-start overflow-x-auto mx-2"> */}
              {/* )} */}
            </div>
          )}
          <div className="flex flex-col pb-24 md:p-0 flex-shrink-0 px-4 space-y-4">
            {partnerServices && partnerServices.length > 0 ? (
              partnerServices.map((partnerService, index) => (
                <PartnerServiceBox
                  props={props}
                  partnerService={partnerService}
                  invertTitleColours={
                    // (fetchedQuotes && fetchedQuotes.length > 0) ||
                    // (fetchedPendingQuotes && fetchedPendingQuotes.length > 0)
                    true
                  }
                />
              ))
            ) : (
              <PartnerWithUsBox
                props={props}
                invertTitleColours={true}
                isAPartner={fetchedCustomer && fetchedCustomer.isAPartner}
              />
            )}
          </div>
        </div>
        {partnerServices &&
          partnerServices.every(function (partnerService) {
            return (
              partnerService.stripeAccountId &&
              partnerService.isStripeAccountLinked
            );
          }) && (
            <div className="hidden md:block">
              <NextButton
                goToNextStep={(e) => {
                  e.preventDefault();
                  props.history.push("addJobs");
                }}
                hideArrow={true}
                loading={
                  loadingCustomer ||
                  loadingInProgressJobQuotes ||
                  loadingPendingQuotes ||
                  loadingPartnerServices
                }
                type="button"
                title="Add jobs"
              />
            </div>
          )}
        <Footer
          loading={
            loadingCustomer ||
            loadingInProgressJobQuotes ||
            loadingPendingQuotes ||
            loadingPartnerServices
          }
          imageToDisplay={
            fetchedCustomer && !fetchedCustomer.isAPartner ? "partnerUp" : "add"
          }
          pulse={
            (!fetchedQuotes || fetchedQuotes.length === 0) &&
            (!fetchedPendingQuotes || fetchedPendingQuotes.length === 0)
          }
          props={props}
          buttonFunction={(e) => {
            e.preventDefault();
            if (fetchedCustomer && !fetchedCustomer.isAPartner) {
              props.history.push("/registerPartner");
            } else {
              props.history.push("/addJobs");
            }
          }}
        />
      </div>
    </div>
  );
}

export const FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY = gql`
  query getInProgressJobQuotesByCustomerId(
    $customerId: String
    $searchTerm: String
  ) {
    getInProgressJobQuotesByCustomerId(
      customerId: $customerId
      searchTerm: $searchTerm
    ) {
      id
      projectId
      quoteRequestId
    }
  }
`;

export const FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY = gql`
  query getPendingQuotesByCustomerId($customerId: String, $searchTerm: String) {
    getPendingQuotesByCustomerId(
      customerId: $customerId
      searchTerm: $searchTerm
    ) {
      id
      quoteRequestId
      projectId
    }
  }
`;

export default Jobs;
