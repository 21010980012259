import { useQuery } from "@apollo/client";
import React from "react";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import { FETCH_QUOTE_BY_ID_QUERY } from "./QuoteMessageBox";

function QuoteMessageTitleButton({
  message,
  toggleModelMessageToOpen,
  activeModelMessageId,
  quoteId,
}) {
  const { data: { getQuoteById: fetchedQuote } = {} } = useQuery(
    FETCH_QUOTE_BY_ID_QUERY,
    {
      variables: { quoteId },
    }
  );
  var projectId = "";
  if (fetchedQuote) {
    console.log("hihihi");
    projectId = fetchedQuote.projectId;
  }

  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: projectId },
    }
  );

  return fetchedQuote && fetchedProject ? (
    <button
      onClick={(e) => toggleModelMessageToOpen(e, message.id)}
      className={`px-4 py-1 border-2 ${
        activeModelMessageId === message.id
          ? "bg-blue-600 text-white"
          : "bg-white  border-blue-600 hover:text-white text-blue-600 hover:bg-blue-600 focus:bg-white focus:text-blue-600"
      } transition-colors focus:outline-none focus:ring-1 rounded-full whitespace-nowrap text-sm`}
    >
      Quote v{fetchedQuote.versionNumber} for {fetchedProject.title} v
      {fetchedProject.versionNumber}
    </button>
  ) : (
    <></>
  );
}

export default QuoteMessageTitleButton;
