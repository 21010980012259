import React, { useEffect, useState } from "react";
import InputField from "./InputField";

export default function DateInputField({
  values,
  errors,
  setValues,
  topLvlInputFieldId,
  fetchedInputField,
  disabled,
}) {
  const [dateVal, setDateVal] = useState("");

  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (values.inputIdsToDates && values.inputIdsToDates.length > 0) {
      for (let i = 0; i < values.inputIdsToDates.length; ++i) {
        if (values.inputIdsToDates[i].inputFieldId === fetchedInputField.id) {
          var copiedValues = { ...values };

          copiedValues.inputIdsToDates[i].dateVal = e.target.value;
          setValues(copiedValues);
          break;
        }
      }
    } else {
      const copiedValues = {
        ...values,
        inputIdsToDates: [
          {
            inputFieldId: fetchedInputField.id,
            dateVal: e.target.value,
          },
        ],
      };
      setValues(copiedValues);
    }
    setDateVal(e.target.value);
  };

  const [checkedInitialVal, setCheckedInitialVal] = useState(false);

  useEffect(() => {
    if (
      !checkedInitialVal &&
      values.inputIdsToDates &&
      values.inputIdsToDates.length > 0
    ) {
      for (let i = 0; i < values.inputIdsToDates.length; ++i) {
        if (values.inputIdsToDates[i].inputFieldId === fetchedInputField.id) {
          setDateVal(values.inputIdsToDates[i].intVal);
          break;
        }
      }
      setCheckedInitialVal(true);
    }
  }, [
    checkedInitialVal,
    setCheckedInitialVal,
    values,
    setDateVal,
    fetchedInputField,
  ]);
  useEffect(() => {
    console.log(dateVal);
  }, [dateVal]);
  console.log(fetchedInputField.stringPlaceHolder);
  return (
    <InputField
      fieldTitle={
        disabled ? fetchedInputField.title : fetchedInputField.title + "*"
      }
      errorMsg={errors && errors[fetchedInputField.id]}
      name={fetchedInputField.id}
      onChangeFunction={onChange}
      type={fetchedInputField.inputBoxType}
      placeholder={fetchedInputField.stringPlaceHolder}
      rows={fetchedInputField.rows}
      value={dateVal}
      hint={fetchedInputField.description}
      disabled={disabled} // setDocumentCallback={setDocumentCallback}
    />
  );
}
