import React, { useCallback, useEffect, useState } from "react";
import CheckboxInputsWithTitle from "./CheckboxInputsWithTitle";

export default function CheckboxInputField({
  values,
  errors,
  setValues,
  topLvlInputFieldId,
  fetchedInputField,
  disabled,
}) {
  const [stringArrayVal, setStringArrayVal] = useState(null);

  const [doneSettingDefaultVals, setDoneSettingDefaultVals] = useState(false);

  useEffect(() => {
    const setDefaultVal = () => {
      if (fetchedInputField.actualType === "string-array") {
        if (
          values.inputIdsToStringArrays &&
          values.inputIdsToStringArrays.length > 0
        ) {
          for (let i = 0; i < values.inputIdsToStringArrays.length; ++i) {
            if (
              values.inputIdsToStringArrays[i].inputFieldId ===
              fetchedInputField.id
            ) {
              // console.log("found a match; "+);
              setStringArrayVal(
                values.inputIdsToStringArrays[i].stringArrayVal
              );
              return;
            }
          }
        }
        setStringArrayVal(fetchedInputField.stringPlaceHolder);
      } else if (fetchedInputField.actualType === "int-array") {
      } else if (fetchedInputField.actualType === "float-array") {
      }
    };
    if (!doneSettingDefaultVals) {
      setDefaultVal();
      setDoneSettingDefaultVals(true);
    } else {
    }
  }, [stringArrayVal, fetchedInputField, values, doneSettingDefaultVals]);

  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (fetchedInputField.actualType === "string-array") {
      // console.log(e.target.value);
      setStringArrayHereAndInValues(e.target.value);
    } else {
      // setStringHereAndInValues(e.target.value);
    }
  };

  const setStringArrayHereAndInValues = useCallback(
    (targetString) => {
      if (
        values.inputIdsToStringArrays &&
        values.inputIdsToStringArrays.length > 0
      ) {
        for (let i = 0; i < values.inputIdsToStringArrays.length; ++i) {
          if (
            values.inputIdsToStringArrays[i].inputFieldId ===
            fetchedInputField.id
          ) {
            let copiedValues = JSON.parse(JSON.stringify(values));
            let targetInputIdToStringArray =
              copiedValues.inputIdsToStringArrays[i];

            if (
              targetInputIdToStringArray.stringArrayVal &&
              targetInputIdToStringArray.stringArrayVal.includes(targetString)
            ) {
              targetInputIdToStringArray.stringArrayVal.splice(
                targetInputIdToStringArray.stringArrayVal.indexOf(targetString),
                1
              );
            } else {
              targetInputIdToStringArray.stringArrayVal = [
                ...targetInputIdToStringArray.stringArrayVal,
                targetString,
              ];
            }
            setValues(copiedValues);
            setStringArrayVal(
              copiedValues.inputIdsToStringArrays[i].stringArrayVal
            );
            return;
          }
        }

        return;
      } else {
        const copiedValues = {
          ...values,
          inputIdsToStringArrays: [
            {
              inputFieldId: fetchedInputField.id,
              stringArrayVal: [targetString],
            },
          ],
        };

        setValues(copiedValues);
      }

      setStringArrayVal([targetString]);
    },
    [fetchedInputField, values, setValues]
  );

  const prereqsAreMet = () => {
    if (
      fetchedInputField.prereqInputFieldIdsToRequiredStringVal &&
      fetchedInputField.prereqInputFieldIdsToRequiredStringVal.length > 0
    ) {
      for (
        let i = 0;
        i < fetchedInputField.prereqInputFieldIdsToRequiredStringVal.length;
        ++i
      ) {
        const prereqInputFieldId =
          fetchedInputField.prereqInputFieldIdsToRequiredStringVal[i]
            .inputFieldId;
        const prereqStringVal =
          fetchedInputField.prereqInputFieldIdsToRequiredStringVal[i].stringVal;

        if (values.inputIdsToStrings && values.inputIdsToStrings.length > 0) {
          for (let j = 0; j < values.inputIdsToStrings.length; ++j) {
            if (
              values.inputIdsToStrings[j].inputFieldId === prereqInputFieldId
            ) {
              if (values.inputIdsToStrings[j].stringVal === prereqStringVal) {
                return true;
              } else {
                return false;
              }
            }
          }
          return false;
        } else {
          return false;
        }
      }
    }

    if (
      fetchedInputField.prereqInputFieldIdsToRequiredIntVal &&
      fetchedInputField.prereqInputFieldIdsToRequiredIntVal.length > 0
    ) {
      for (
        let i = 0;
        i < fetchedInputField.prereqInputFieldIdsToRequiredIntVal.length;
        ++i
      ) {
        const prereqInputFieldId =
          fetchedInputField.prereqInputFieldIdsToRequiredIntVal[i].inputFieldId;
        const prereqIntVal =
          fetchedInputField.prereqInputFieldIdsToRequiredIntVal[i].intVal;

        if (values.inputIdsToInts && values.inputIdsToInts.length > 0) {
          for (let j = 0; j < values.inputIdsToInts.length; ++j) {
            if (values.inputIdsToInts[j].inputFieldId === prereqInputFieldId) {
              if (values.inputIdsToInts[j].intVal === prereqIntVal) {
                return true;
              } else {
                return false;
              }
            }
          }
          return false;
        } else {
          return false;
        }
      }
    }

    if (
      fetchedInputField.prereqInputFieldIdsToRequiredFloatVal &&
      fetchedInputField.prereqInputFieldIdsToRequiredFloatVal.length > 0
    ) {
      for (
        let i = 0;
        i < fetchedInputField.prereqInputFieldIdsToRequiredFloatVal.length;
        ++i
      ) {
        const prereqInputFieldId =
          fetchedInputField.prereqInputFieldIdsToRequiredFloatVal[i]
            .inputFieldId;
        const prereqFloatVal =
          fetchedInputField.prereqInputFieldIdsToRequiredFloatVal[i].floatVal;

        if (values.inputIdsToFloats && values.inputIdsToFloats.length > 0) {
          for (let j = 0; j < values.inputIdsToFloats.length; ++j) {
            if (
              values.inputIdsToFloats[j].inputFieldId === prereqInputFieldId
            ) {
              if (values.inputIdsToFloats[j].floatVal === prereqFloatVal) {
                return true;
              } else {
                return false;
              }
            }
          }
          return false;
        } else {
          return false;
        }
      }
    }

    return true;
  };

  return prereqsAreMet() ? (
    <CheckboxInputsWithTitle
      fieldTitle={
        disabled ? fetchedInputField.title : fetchedInputField.title + "*"
      }
      errorMsg={errors && errors[fetchedInputField.id]}
      hint={fetchedInputField.description}
      name={fetchedInputField.id}
      onChangeFunction={onChange}
      value={
        fetchedInputField.actualType === "string-array" ? stringArrayVal : null
      }
      options={
        disabled
          ? fetchedInputField.actualType === "string-array"
            ? stringArrayVal
            : null
          : fetchedInputField.actualType === "string-array"
          ? fetchedInputField.stringOptions
          : null
      }
      convertValToNumber={fetchedInputField.actualType !== "string-array"}
      // key={index}
      disabled={disabled}
    />
  ) : (
    <></>
  );
}
