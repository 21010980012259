import React from "react";
import CardSelectButton from "./CardSelectButton";

function CardSection({ paymentMethodDetails, customerId }) {
  return (
    <div
      className="py-1 w-full gap-2 overflow-x-auto grid grid-flow-col"
      style={{
        gridTemplateColumns: `repeat(
        ${paymentMethodDetails.length},
        10rem
      )`,
      }}
    >
      {paymentMethodDetails
        // .filter((paymentMethod) => {
        //   return paymentMethod.isPrimaryPaymentMethod;
        // })
        .map((paymentMethod, index) => (
          <CardSelectButton
            key={index}
            paymentMethod={paymentMethod}
            customerId={customerId}
          />
        ))}
    </div>
  );
}

export default CardSection;
