import React from "react";
import InputField from "./InputField";
import InputTitle from "./InputTitle";

export default function CheckboxInputsWithTitle({
  fieldTitle,
  errorMsg,
  onChangeFunction,
  value,
  hint,
  name,
  options,
  convertValToNumber,
  disabled,
}) {
  // console.log(value);
  return (
    <div className="flex flex-col items-start justify-start w-full space-y-2">
      <InputTitle fieldTitle={fieldTitle} errorMsg={errorMsg} hint={hint} />

      <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
        {options &&
          options.map((option, index) => (
            <InputField
              name={name}
              onChangeFunction={onChangeFunction}
              checkboxValue={option}
              type="checkbox"
              value={value}
              checkboxTitle={option}
              key={index}
              convertValToNumber={convertValToNumber}
              disabled={disabled}
            />
          ))}
      </div>
    </div>
  );
}
