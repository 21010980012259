import React, { useCallback, useEffect, useState } from "react";
import InputField from "./InputField";

export default function NumberInputField({
  values,
  errors,
  setValues,
  topLvlInputFieldId,
  fetchedInputField,
  disabled,
}) {
  const [intVal, setIntVal] = useState(
    fetchedInputField.inputBoxType === "radio" ||
      fetchedInputField.inputBoxType === "checkbox" ||
      fetchedInputField.inputBoxType === "range"
      ? fetchedInputField.intPlaceHolder
      : null
  );
  const [floatVal, setFloatVal] = useState(
    fetchedInputField.inputBoxType === "radio" ||
      fetchedInputField.inputBoxType === "checkbox" ||
      fetchedInputField.inputBoxType === "range"
      ? fetchedInputField.floatPlaceHolder
      : null
  );

  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (fetchedInputField.actualType === "int") {
      setIntHereAndInValues(parseInt(e.target.value, 10) || 0);
    } else {
      setFloatHereAndInValues(parseFloat(e.target.value) || 0);
    }
  };

  const [checkedInitialVal, setCheckedInitialVal] = useState(false);

  const setIntHereAndInValues = useCallback(
    (targetInt) => {
      if (values.inputIdsToInts && values.inputIdsToInts.length > 0) {
        for (let i = 0; i < values.inputIdsToInts.length; ++i) {
          if (values.inputIdsToInts[i].inputFieldId === fetchedInputField.id) {
            let copiedValues = { ...values };
            let targetInputIdToInt = {
              ...copiedValues.inputIdsToInts[i],
            };

            targetInputIdToInt.intVal = targetInt;

            copiedValues.inputIdsToInts = [
              ...copiedValues.inputIdsToInts,
              targetInputIdToInt,
            ];
            copiedValues.inputIdsToInts.splice(i, 1);

            setValues(copiedValues);
            setIntVal(targetInt);
            return;
          }
        }
        const copiedValues2 = {
          ...values,
          inputIdsToInts: [
            ...values.inputIdsToInts,
            {
              inputFieldId: fetchedInputField.id,
              intVal: fetchedInputField.intPlaceHolder,
            },
          ],
        };

        setValues(copiedValues2);
        setIntVal(targetInt);
        return;
      } else {
        const copiedValues = {
          ...values,
          inputIdsToInts: [
            {
              inputFieldId: fetchedInputField.id,
              intVal: targetInt,
            },
          ],
        };

        // values["inputIdsToInts"]=            {
        //   inputFieldId: fetchedInputField.id,
        //   intVal: targetInt,
        // }

        setValues(copiedValues);
      }

      setIntVal(targetInt);
    },
    [fetchedInputField, values, setValues]
  );

  const setFloatHereAndInValues = useCallback(
    (targetFloat) => {
      if (values.inputIdsToFloats && values.inputIdsToFloats.length > 0) {
        for (let i = 0; i < values.inputIdsToFloats.length; ++i) {
          if (
            values.inputIdsToFloats[i].inputFieldId === fetchedInputField.id
          ) {
            let copiedValues = { ...values };
            let targetInputIdToFloat = {
              ...copiedValues.inputIdsToFloats[i],
            };

            targetInputIdToFloat.floatVal = targetFloat;

            copiedValues.inputIdsToFloats = [
              ...copiedValues.inputIdsToFloats,
              targetInputIdToFloat,
            ];
            copiedValues.inputIdsToFloats.splice(i, 1);

            setValues(copiedValues);
            setFloatVal(targetFloat);
            return;
          }
        }
        const copiedValues2 = {
          ...values,
          inputIdsToFloats: [
            ...values.inputIdsToFloats,
            {
              inputFieldId: fetchedInputField.id,
              floatVal: fetchedInputField.floatPlaceHolder,
            },
          ],
        };

        setValues(copiedValues2);
        setFloatVal(targetFloat);
        return;
      } else {
        const copiedValues = {
          ...values,
          inputIdsToFloats: [
            {
              inputFieldId: fetchedInputField.id,
              floatVal: targetFloat,
            },
          ],
        };

        // values["inputIdsToFloats"]=            {
        //   inputFieldId: fetchedInputField.id,
        //   floatVal: targetFloat,
        // }

        setValues(copiedValues);
      }

      setFloatVal(targetFloat);
    },
    [fetchedInputField, values, setValues]
  );

  const setDefaultFloatHereAndInValues = useCallback(() => {
    var foundInArray = false;
    if (values.inputIdsToFloats && values.inputIdsToFloats.length > 0) {
      for (let i = 0; i < values.inputIdsToFloats.length; ++i) {
        if (values.inputIdsToFloats[i].inputFieldId === fetchedInputField.id) {
          setFloatVal(values.inputIdsToFloats[i].floatVal);
          foundInArray = true;
          break;
        }
      }
    }

    if (
      !foundInArray &&
      (fetchedInputField.inputBoxType === "radio" ||
        fetchedInputField.inputBoxType === "checkbox" ||
        fetchedInputField.inputBoxType === "range")
    ) {
      setFloatVal(fetchedInputField.floatPlaceHolder);
    }
  }, [fetchedInputField, values.inputIdsToFloats]);

  const setDefaultIntHereAndInValues = useCallback(() => {
    var foundInArray = false;
    if (values.inputIdsToInts && values.inputIdsToInts.length > 0) {
      for (let i = 0; i < values.inputIdsToInts.length; ++i) {
        if (values.inputIdsToInts[i].inputFieldId === fetchedInputField.id) {
          setIntVal(values.inputIdsToInts[i].intVal);
          foundInArray = true;
          break;
        }
      }
    }
    if (
      !foundInArray &&
      (fetchedInputField.inputBoxType === "radio" ||
        fetchedInputField.inputBoxType === "checkbox" ||
        fetchedInputField.inputBoxType === "range")
    ) {
      setIntVal(fetchedInputField.intPlaceHolder);
    }
  }, [fetchedInputField, values.inputIdsToInts]);

  useEffect(() => {
    if (!checkedInitialVal) {
      if (fetchedInputField.actualType === "int") {
        setDefaultIntHereAndInValues();
      } else {
        setDefaultFloatHereAndInValues();
      }

      setCheckedInitialVal(true);
    }
  }, [
    fetchedInputField,
    checkedInitialVal,
    setCheckedInitialVal,
    setDefaultIntHereAndInValues,
    setDefaultFloatHereAndInValues,
  ]);

  return (
    <InputField
      fieldTitle={
        disabled ? fetchedInputField.title : fetchedInputField.title + "*"
      }
      errorMsg={errors && errors[fetchedInputField.id]}
      hint={fetchedInputField.description}
      name={fetchedInputField.id}
      onChangeFunction={onChange}
      value={fetchedInputField.actualType === "int" ? intVal : floatVal}
      type={fetchedInputField.inputBoxType}
      placeholder={
        fetchedInputField.actualType === "int"
          ? fetchedInputField.intPlaceHolder
          : fetchedInputField.floatPlaceHolder
      }
      rangeMin={fetchedInputField.rangeMin}
      rangeMax={fetchedInputField.rangeMax}
      rangeStep={fetchedInputField.rangeStep}
      isMoney={fetchedInputField.isMoney}
      disabled={disabled} // setDocumentCallback={setDocumentCallback}
    />
  );
}
