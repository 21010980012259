import React from "react";
import { useWindowSize } from "../../util/hooks";

export default function NextButton({
  loading,
  goToNextStep,
  type,
  title,
  hideArrow,
  invertColours,
}) {
  const size = useWindowSize();

  return (
    <button
      onClick={(e) => {
        if (type !== "submit") {
          goToNextStep(e);
        }
      }}
      disabled={loading}
      type={type === "submit" ? "submit" : "button"}
      className={`${
        invertColours
          ? `px-4 py-2 md:hover:bg-gray-300 bg-blue-700 hover:bg-blue-700 md:bg-white border-transparent border-2 text-white md:text-black`
          : title
          ? `md:border-2 px-4 py-2 md:border-white text-white md:text-white hover:bg-blue-700 md:hover:bg-white ${
              loading ? "" : "hover:text-white"
            } md:hover:text-black bg-blue-600 md:bg-black`
          : "w-min p-3 hover:bg-gray-600 md:hover:bg-gray-300 bg-white"
      } shadow-lg text-xl sm:text-2xl md:text-xl rounded-full transition-colors focus:outline-none focus:ring-1 flex items-center justify-center space-x-2 leading-none font-semibold`}
    >
      {loading ? (
        <svg
          className="h-6 animate-spin"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
            stroke={
              invertColours
                ? size.width >= 768
                  ? "black"
                  : "white"
                : title
                ? "white"
                : "black"
            }
            strokeWidth="2"
          />
          <path
            d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
            stroke={
              invertColours
                ? size.width >= 768
                  ? "black"
                  : "white"
                : title
                ? "white"
                : "black"
            }
            strokeWidth="2"
          />
        </svg>
      ) : (
        <>
          {title && <p>{title}</p>}
          {!hideArrow && (
            <svg
              className="fill-current text-white h-6"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15 5L13.2375 6.7625L20.2125 13.75H5V16.25H20.2125L13.2375 23.2375L15 25L25 15L15 5Z" />
            </svg>
          )}
        </>
      )}
    </button>
  );
}
