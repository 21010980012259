import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY } from "../../pages/customer/FindJobs";
import {
  FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
  FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
} from "../../pages/customer/Jobs";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import { REJECT_QUOTE_REQUEST_MUTATION } from "./JobRequestBox";
// import NextButton from "./NextButton";

export default function PendingQuoteCard({
  pendingQuote,
  props,
  isFirst,
  isLast,
}) {
  const [errors, setErrors] = useState({});
  const { data: { getProjectById: project } = {}, loading } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: pendingQuote.projectId },
    }
  );
  if (errors) {
    console.log(errors);
  }
  const [rejectQuoteRequest, { loading: loadingQuoteCancellation }] =
    useMutation(REJECT_QUOTE_REQUEST_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
        },
        {
          query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
      ],

      update(_, { data: { rejectQuoteRequest: projectData } }) {
        // refetch projects
        // props.history.push("/projects");
        props.history.push("/jobs");
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: { quoteRequestId: pendingQuote.quoteRequestId },
    });

  var mainImageUrl = "";
  if (project) {
    for (let i = 0; i < project.inputIdsToFileUrlArrays.length; ++i) {
      if (project.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8")
        //Images{
        mainImageUrl = project.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }
  return project ? (
    <div
      className={`bg-gray-100 flex-shrink-0 shadow-xl rounded-xl overflow-hidden flex flex-col items-center justify-center w-full md:w-56 ${
        isLast ? "mb-16 md:mb-0" : ""
      } ${isFirst ? "" : "mt-8 md:mt-0"}`}
    >
      <div className="bg-white p-3 flex flex-col items-start justify-start w-full h-full space-y-1 rounded-b-xl text-center">
        {mainImageUrl && (
          <button
            onClick={(e) => {
              e.preventDefault();
              props.history.push(
                "/quoteRequestDetails/" +
                  pendingQuote.quoteRequestId +
                  "/" +
                  pendingQuote.projectId
              );
            }}
            className="focus:outline-none focus:ring-1 rounded-xl"
          >
            <img
              src={mainImageUrl}
              alt={project.title}
              className="rounded-xl shadow-xl w-full h-32 object-cover hover:opacity-90"
            />
          </button>
        )}
        <button
          className="font-semibold focus:outline-none focus:text-blue-800 text-3xl truncate w-full px-2 text-black hover:text-blue-700 transition-colors"
          onClick={(e) => {
            e.preventDefault();
            props.history.push(
              "/quoteRequestDetails/" +
                pendingQuote.quoteRequestId +
                "/" +
                pendingQuote.projectId
            );
          }}
        >
          {project.title}
        </button>
        <p className="w-full truncate font-medium text-gray-400 px-2">
          {project.shortDescription}
        </p>
      </div>

      <div className="bg-gray-100 p-4 w-full h-full flex items-center justify-between">
        <div className="flex items-center justify-start space-x-2">
          <button
            className="font-semibold text-red-500  focus:outline-none focus:text-red-700"
            disabled={loading || loadingQuoteCancellation}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              rejectQuoteRequest();
            }}
          >
            Reject
          </button>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.history.push("/writeQuote/" + pendingQuote.quoteRequestId);
          }}
          className="font-semibold focus:outline-none focus:ring-4 text-white bg-blue-700 hover:bg-blue-500 rounded-full px-4 py-2 transition-colors"
          disabled={loading || loadingQuoteCancellation}
        >
          Write quote
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}
