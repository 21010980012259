import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import InputFieldsForSelectedTopLevelField from "../../components/customer/InputFieldsForSelectedTopLevelField";
import InputTitle from "../../components/customer/InputTitle";
import MiniProjectCard from "../../components/customer/MiniProjectCard";
import NextButton from "../../components/customer/NextButton";
import PageHeader from "../../components/customer/PageHeader";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import VersionsButton from "../../components/customer/VersionsButton";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";
import { FETCH_CUSTOMER_QUERY } from "./Settings";
function ProjectDetails(props) {
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Project Details | Softwair";
  }, []);
  const [errors] = useState({});
  const projectId = props.match.params.projectId;

  const {
    data: { getProjectById: fetchedProject } = {},
    loading,
    // refetch: refetchProjects,
  } = useQuery(FETCH_PROJECT_BY_ID_QUERY, {
    variables: { projectId },
  });

  const { values, onArrayChange, setValues } = useForm(emptyCallback, {
    editProjectId: projectId,
    // parentProjectId: project ? project : null,
    title: "",
    shortDescription: "",
    selectedTopLvlInputFieldIds: [],

    subParts: [],
  });
  function emptyCallback() {}

  ///TODO: edit this page so that staff can see different stuff
  const [searchTerm, setSearchTerm] = useState("");

  const onChangeText = (name, text) => {
    setSearchTerm(text);
  };
  const clearTextFunction = () => {
    setSearchTerm("");
  };

  const {
    data: { getProjectSubPartsByCustomerId: fetchedSubProjects } = {},
    // refetch,
  } = useQuery(FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY, {
    variables: { searchTerm, parentProjectId: projectId },
  });

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  if (!loading && !loadingCustomer && !fetchedProject) {
    props.history.push("/projects");
  }

  const {
    data: { getInputFieldsByProjectId: projectInputFields } = {},
    // error,
    loading: loadingProjectInputFields,
  } = useQuery(FETCH_INPUT_FIELDS_BY_PROJECT_ID_QUERY, {
    variables: {
      isTopLevel: true,
      projectId,
    },
  });

  const [lastProjectId, setLastProjectId] = useState(projectId);
  const [finishedSettingInitialVals, setFinishedSettingInitialVals] =
    useState(false);
  useEffect(() => {
    if (!finishedSettingInitialVals && fetchedProject) {
      setValues({
        ...values,

        ...fetchedProject,
        selectedTopLvlInputFieldIds: [],
      });
      setFinishedSettingInitialVals(true);
    } else if (lastProjectId !== projectId && fetchedProject) {
      setValues({
        ...values,

        ...fetchedProject,
        selectedTopLvlInputFieldIds: [],
      });

      setLastProjectId(projectId);
    }
  }, [
    fetchedProject,
    setValues,
    values,
    finishedSettingInitialVals,
    setFinishedSettingInitialVals,
    lastProjectId,
    setLastProjectId,
    projectId,
  ]);

  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 py-6 md:mb-2 md:p-0 w-full">
          <PageHeader
            props={props}
            titleBold={fetchedProject ? fetchedProject.title : "Project"}
            caption={
              fetchedProject && fetchedProject.shortDescription //  `Version ${fetchedProject.versionNumber}`
            }
            hideHomeButton={true}
            // padTop={true} // caption="Fill out this form to save your idea. Later, you can get quotations for it within two days."
          />
        </div>

        {fetchedProject && (
          <div className="flex flex-col space-y-4 items-start justify-start w-full overflow-y-auto px-6 md:p-0 flex-1 overflow-x-hidden">
            {mainImageUrl && (
              <img
                className="object-cover w-full h-36 sm:h-52 md:h-36 rounded-lg shadow-lg"
                src={mainImageUrl}
                alt={fetchedProject.title}
              />
            )}

            <div className="flex items-center justify-evenly w-full">
              <VersionsButton project={fetchedProject} props={props} />

              <button
                className="bg-blue-600 rounded-full w-12 h-12 sm:h-16 sm:w-16 md:w-12 md:h-12 p-3 shadow-lg focus:outline-none focus:ring-1"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/editProject/" + projectId);
                }}
              >
                <svg
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.5449 4C23.2116 4 22.8649 4.13333 22.6116 4.38667L20.1716 6.82667L25.1716 11.8267L27.6116 9.38667C28.1316 8.86667 28.1316 8.02667 27.6116 7.50667L24.4916 4.38667C24.2249 4.12 23.8916 4 23.5449 4ZM18.745 12.0267L19.9716 13.2533L7.89162 25.3333H6.66496V24.1067L18.745 12.0267ZM3.99829 23L18.745 8.25333L23.745 13.2533L8.99829 28H3.99829V23Z"
                    fill="white"
                  />
                </svg>
              </button>

              <button
                className="bg-white rounded-full w-11 h-11 sm:h-14 sm:w-14 md:w-11 md:h-11 p-3 shadow-lg focus:outline-none focus:ring-1"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/trackProject/" + projectId);
                }}
              >
                <svg
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.898 5.102L22.924 7.076C24.94 9.106 26.2 11.906 26.2 15C26.2 21.188 21.188 26.2 15 26.2C8.812 26.2 3.8 21.188 3.8 15C3.8 9.288 8.07 4.584 13.6 3.898V6.726C9.624 7.398 6.6 10.842 6.6 15C6.6 19.634 10.366 23.4 15 23.4C19.634 23.4 23.4 19.634 23.4 15C23.4 12.676 22.462 10.576 20.936 9.064L18.962 11.038C19.97 12.06 20.6 13.46 20.6 15C20.6 18.094 18.094 20.6 15 20.6C11.906 20.6 9.4 18.094 9.4 15C9.4 12.396 11.192 10.226 13.6 9.596V12.592C12.76 13.082 12.2 13.964 12.2 15C12.2 16.54 13.46 17.8 15 17.8C16.54 17.8 17.8 16.54 17.8 15C17.8 13.964 17.24 13.068 16.4 12.592V1H15C7.272 1 1 7.272 1 15C1 22.728 7.272 29 15 29C22.728 29 29 22.728 29 15C29 11.136 27.432 7.636 24.898 5.102Z"
                    fill="#2563EB"
                  />
                </svg>
              </button>
            </div>

            {projectInputFields && projectInputFields.length > 0 && (
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <InputTitle
                  fieldTitle={"Details (optional)"}
                  errorMsg={errors && errors.selectedTopLvlInputFieldIds}
                />

                <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                  {projectInputFields &&
                    projectInputFields.length > 0 &&
                    projectInputFields.map((topLvlSuggestedDetail, index) => (
                      <InputField
                        name={"selectedTopLvlInputFieldIds"}
                        onChangeFunction={onArrayChange}
                        checkboxValue={topLvlSuggestedDetail.id}
                        type="checkbox"
                        value={values.selectedTopLvlInputFieldIds}
                        checkboxTitle={topLvlSuggestedDetail.title}
                        key={index}
                      />
                    ))}
                </div>
              </div>
            )}

            {values.selectedTopLvlInputFieldIds &&
              values.selectedTopLvlInputFieldIds.length > 0 &&
              values.selectedTopLvlInputFieldIds.map(
                (topLvlInputFieldId, index) => (
                  <InputFieldsForSelectedTopLevelField
                    key={index}
                    topLvlInputFieldId={topLvlInputFieldId}
                    errors={errors}
                    disabled={true}
                    values={values}
                    setValues={setValues}
                  />
                )
              )}

            {((fetchedSubProjects && fetchedSubProjects.length > 0) ||
              searchTerm) && (
              <InputField
                fieldTitle="Search"
                errorMsg={errors && errors.searchTerm}
                placeholder="Sub parts"
                name="searchTerm"
                onChangeFunction={onChangeText}
                value={searchTerm}
                type="text"
                clearBtnText={"Clear"}
                clearTextFunction={clearTextFunction}
              />
            )}
            <div className="flex-shrink-0 flex items-center justify-start space-x-4 w-full overflow-x-auto">
              {fetchedSubProjects &&
                fetchedSubProjects.length > 0 &&
                fetchedSubProjects.map((subProject, index) => (
                  <MiniProjectCard props={props} project={subProject} />
                ))}
              <div className="flex flex-col items-start justify-center space-y-2">
                {!fetchedSubProjects ||
                  (fetchedSubProjects.length === 0 && (
                    <InputTitle fieldTitle="Sub parts" />
                  ))}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/createProject/" + projectId);
                  }}
                  className={`text-blue-600 hover:bg-gray-200 border-blue-600 bg-white md:bg-black md:hover:bg-gray-700 md:text-white focus:outline-none focus:ring-1 flex-grow-0 text-center cursor-pointer border-2 md:border-0 font-normal rounded-full p-2`}
                >
                  <svg
                    className="fill-current"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.1376 15.831H15.831V23.1375H13.3955V15.831H6.08887V13.3954H13.3955V6.08884H15.831V13.3954H23.1376V15.831Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <NextButton
            goToNextStep={(e) => props.history.push("/getQuotes/" + projectId)}
            hideArrow={true}
            loading={loading || loadingProjectInputFields}
            title="Get quotes"
            type="button"
          />
        </div>
      </div>
    </div>
  ); //April 22 Earth Day
}

export const FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY = gql`
  query getProjectSubPartsByCustomerId(
    $customerId: String
    $searchTerm: String
    $parentProjectId: String!
  ) {
    getProjectSubPartsByCustomerId(
      customerId: $customerId
      searchTerm: $searchTerm
      parentProjectId: $parentProjectId
    ) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export const FETCH_INPUT_FIELDS_BY_PROJECT_ID_QUERY = gql`
  query getInputFieldsByProjectId($projectId: String!, $isTopLevel: Boolean!) {
    getInputFieldsByProjectId(projectId: $projectId, isTopLevel: $isTopLevel) {
      id
      title
      description
      stringPlaceHolder
      intPlaceHolder
      floatPlaceHolder
      tags
      parentInputFieldId
      inputBoxType
      actualType
      rows
      maxLength
      isMoney
      rangeMin
      rangeMax
      rangeStep
      acceptedFileFormats
      stringOptions
      intOptions
      floatOptions
      prereqInputFieldIdsToRequiredStringVal {
        inputFieldId
        stringVal
      }
      prereqInputFieldIdsToRequiredIntVal {
        inputFieldId
        intVal
      }
      prereqInputFieldIdsToRequiredFloatVal {
        inputFieldId
        floatVal
      }
      inputFieldIdsToDisabledStrings {
        inputFieldId
        disabledStrings
      }
      inputFieldIdsToDisabledInts {
        inputFieldId
        disabledInts
      }
      inputFieldIdsToDisabledFloats {
        inputFieldId
        disabledFloats
      }
      inputFieldIdsToDefaultStringVal {
        inputFieldId
        defaultString
      }
      inputFieldIdsToDefaultStringArrayVal {
        inputFieldId
        defaultStringArray
      }
      inputFieldIdsToDefaultIntVal {
        inputFieldId
        defaultInt
      }
      inputFieldIdsToDefaultIntArrayVal {
        inputFieldId
        defaultIntArray
      }
      inputFieldIdsToDefaultFloatVal {
        inputFieldId
        defaultFloat
      }
      inputFieldIdsToDefaultFloatArrayVal {
        inputFieldId
        defaultFloat
      }
      timesUsed
      active
      createdAt
    }
  }
`;

export const FETCH_PROJECT_BY_ID_QUERY = gql`
  query getProjectById($projectId: String!) {
    getProjectById(projectId: $projectId) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;
export default ProjectDetails;
