import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import MilestoneDetailsDropdown from "../../components/customer/MilestoneDetailsDropdown";
import MiniProjectCard from "../../components/customer/MiniProjectCard";
import NextButton from "../../components/customer/NextButton";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "../../components/customer/OrderMilestoneMessageBox";
import PageHeader from "../../components/customer/PageHeader";
import { FETCH_QUOTE_BY_ID_QUERY } from "../../components/customer/QuoteMessageBox";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
// import { FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY } from "./FindJobs";
// import {
//   FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
//   FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
// } from "./Jobs";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function JobDetails(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    document.title = "Job Details | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }

  const [errors] = useState({});

  const quoteId = props.match.params.quoteId;
  var projectId = props.match.params.projectId;

  const {
    data: { getQuoteById: fetchedQuote } = {},
    loading: { loadingQuote },
  } = useQuery(FETCH_QUOTE_BY_ID_QUERY, {
    variables: { quoteId },
  });

  var quoteRequestId;

  if (fetchedQuote) {
    quoteRequestId = fetchedQuote.quoteRequestId;
    projectId = fetchedQuote.projectId;
  }

  const {
    data: { getQuoteRequestById: fetchedQuoteRequest } = {},
    // loading: { loadingQuoteRequest },
  } = useQuery(FETCH_QUOTE_REQUEST_BY_ID_QUERY, {
    variables: { quoteRequestId },
  });

  const {
    data: { getProjectById: fetchedProject } = {},
    loading,
    // refetch: refetchProjects,
  } = useQuery(FETCH_PROJECT_BY_ID_QUERY, {
    variables: { projectId },
  });

  // if (fetchedProject) {
  //   console.log(fetchedProject);
  // }
  const [searchTerm, setSearchTerm] = useState("");

  const onChangeText = (name, text) => {
    setSearchTerm(text);
  };
  const clearTextFunction = () => {
    setSearchTerm("");
  };

  const {
    data: { getProjectSubPartsByCustomerId: fetchedSubProjects } = {},
    // refetch,
  } = useQuery(FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY, {
    variables: { searchTerm, parentProjectId: projectId },
  });

  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }
  if (fetchedCustomer && !fetchedCustomer.isAPartnerStaffMember) {
    props.history.push("/jobs");
  }

  // const [
  //   rejectQuoteRequest,
  //   { loading: loadingQuoteRequestRejection },
  // ] = useMutation(REJECT_QUOTE_REQUEST_MUTATION, {
  //   refetchQueries: [
  //     {
  //       query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
  //       variables: { searchTerm: "" },
  //     },
  //     {
  //       query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
  //     },
  //     {
  //       query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
  //       variables: { searchTerm: "" },
  //     },
  //   ],

  //   update(_, { data: { rejectQuoteRequest: projectData } }) {
  //     // refetch projects
  //     props.history.push("/addJobs");
  //   },
  //   onError(err) {
  //     console.log(err);
  //     console.log(err.graphQLErrors[0].extensions.exception.errors);
  //     setErrors(err.graphQLErrors[0].extensions.exception.errors);
  //   },
  //   variables: { quoteRequestId },
  // });

  const {
    data: { getOrderMilestonesByQuote: orderMilestones } = {},
    loading: { loadingOrderMilestones },
  } = useQuery(FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY, {
    variables: { quoteId },
  });
  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  if (
    !loading &&
    !loadingCustomer &&
    !loadingOrderMilestones &&
    !loadingOrderMilestones &&
    !loadingQuote &&
    !fetchedQuote
  ) {
    props.history.push("/jobs");
  }

  var detailsCheckboxTitleToValue = {};
  if (
    fetchedProject &&
    fetchedProject.details &&
    fetchedProject.details.length > 0
  ) {
    detailsCheckboxTitleToValue = {};
    for (let i = 0; i < fetchedProject.details.length; ++i) {
      detailsCheckboxTitleToValue[fetchedProject.details[i]] =
        fetchedProject.details[i];
    }
    console.log(detailsCheckboxTitleToValue);
  }

  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />

        {/* <div className="flex flex-col space-y-8">
          <p className="font-thin text-4xl">
            Our app is coming on <span className="font-bold">April 12th</span>
          </p>
          <div className="flex flex-col space-y-2">
            <p className="text-4xl font-thin">1. Better idea iteration</p>
            <p className="text-4xl font-thin">2. In-app quotations</p>
            <p className="text-4xl font-thin">3. End-to-end payments</p>
            <p className="text-4xl font-thin">4. In-app project tracking</p>
          </div>
          <p className="text-4xl font-semibold">And more...</p>
        </div>
        <div className="flex flex-col items-start justify-start">
          <div className="flex flex-col items-start space-y-2 justify-start w-full text-xl">
            {loadingCustomer ? (
              <svg
                className="h-6 animate-spin"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                  stroke="black"
                  strokeWidth="2"
                />
                <path
                  d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                  stroke="black"
                  strokeWidth="2"
                />
              </svg>
            ) : (
              fetchedCustomer &&
              !fetchedCustomer.isAPartner && (
                <button
                  className="font-semibold text-blue-600 hover:text-blue-700 italic"
                  onClick={(e) => {
                    props.history.push("/registerPartner");
                  }}
                  disabled={loading || loadingCustomer || loadingQuote}
                >
                  Become a Softwair partner
                </button>
              )
            )}
          </div>
        </div>
      
      */}
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>
      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 py-6 md:mb-2 md:p-0 w-full">
          <PageHeader
            props={props}
            titleBold={fetchedProject ? fetchedProject.title : "Job"}
            caption={fetchedProject && fetchedProject.shortDescription}
            hideHomeButton={true}
          />
        </div>

        {fetchedProject && (
          <div className="flex flex-col space-y-4 items-start justify-start w-full overflow-y-auto px-6 md:p-0 flex-1 overflow-x-hidden">
            {mainImageUrl && (
              <img
                className="object-cover w-full h-36 sm:h-52 md:h-36 rounded-lg shadow-lg"
                src={mainImageUrl}
                alt={fetchedProject.title}
              />
            )}
            {/* {fetchedProject.shortDescription && (
              <p className="w-full font-medium text-gray-400 md:text-gray-300 px-2 text-center">
                {fetchedProject.shortDescription}
              </p>
            )} */}

            {fetchedQuoteRequest && (
              <>
                <InputField
                  fieldTitle="Request type"
                  value={fetchedQuoteRequest.type}
                  disabled={true}
                />
                {fetchedQuoteRequest.type === "Production" && (
                  <InputField
                    fieldTitle="Quantity"
                    value={fetchedQuoteRequest.unitsNeeded}
                    disabled={true}
                  />
                )}

                {fetchedQuoteRequest.type === "message" && (
                  <InputField
                    fieldTitle="Message"
                    value={fetchedQuoteRequest.message}
                    disabled={true}
                  />
                )}
              </>
            )}
            {/* {((topLvlSuggestedDetails && topLvlSuggestedDetails.length > 0) ||
              (prevTopLvlSuggestedDetails &&
                prevTopLvlSuggestedDetails.length > 0)) && (
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <InputTitle
                  fieldTitle={"Details (optional)"}
                  errorMsg={errors && errors.selectedTopLvlInputFieldIds}
                  hint={
                    "Add details by clicking one of the options below. Click again to remove that detail."
                  }
                />

                <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                  {topLvlSuggestedDetails &&
                    topLvlSuggestedDetails.length > 0 &&
                    topLvlSuggestedDetails.map(
                      (topLvlSuggestedDetail, index) => (
                        <InputField
                          name={"selectedTopLvlInputFieldIds"}
                          onChangeFunction={onArrayChange}
                          checkboxValue={topLvlSuggestedDetail.id}
                          type="checkbox"
                          value={values.selectedTopLvlInputFieldIds}
                          checkboxTitle={topLvlSuggestedDetail.title}
                          key={index}
                        />
                      )
                    )}
                  {(!topLvlSuggestedDetails ||
                    topLvlSuggestedDetails.length === 0) &&
                    prevTopLvlSuggestedDetails &&
                    prevTopLvlSuggestedDetails.length > 0 &&
                    prevTopLvlSuggestedDetails.map(
                      (prevTopLvlSuggestedDetail, index) => (
                        <InputField
                          name={"selectedTopLvlInputFieldIds"}
                          onChangeFunction={onArrayChange}
                          checkboxValue={prevTopLvlSuggestedDetail.id}
                          type="checkbox"
                          value={values.selectedTopLvlInputFieldIds}
                          checkboxTitle={prevTopLvlSuggestedDetail.title}
                          key={index}
                          disabled={true}
                        />
                      )
                    )}
                </div>
              </div>
            )}

            {values.selectedTopLvlInputFieldIds &&
              values.selectedTopLvlInputFieldIds.length > 0 &&
              values.selectedTopLvlInputFieldIds.map(
                (topLvlInputFieldId, index) => (
                  <InputFieldsForSelectedTopLevelField
                    key={index}
                    onChange={onChange}
                    topLvlInputFieldId={topLvlInputFieldId}
                    errors={errors}
                    values={values}
                    setValues={setValues}
                  />
                )
              )} */}

            {((fetchedSubProjects && fetchedSubProjects.length > 0) ||
              searchTerm) && (
              <InputField
                fieldTitle="Search"
                errorMsg={errors && errors.searchTerm}
                placeholder="Sub parts"
                name="searchTerm"
                onChangeFunction={onChangeText}
                value={searchTerm}
                type="text"
                clearBtnText={"Clear"}
                clearTextFunction={clearTextFunction}
              />
            )}
            <div className="flex-shrink-0 flex items-center justify-start space-x-4 w-full overflow-x-auto">
              {fetchedSubProjects &&
                fetchedSubProjects.length > 0 &&
                fetchedSubProjects.map((subProject, index) => (
                  <MiniProjectCard props={props} project={subProject} />
                ))}
            </div>
          </div>
        )}
        <div className="flex items-center justify-center space-x-4 my-6 md:mb-0 w-full">
          {fetchedQuoteRequest &&
          fetchedQuoteRequest.projectId !== projectId ? (
            <NextButton
              goToNextStep={(e) => props.history.push("/jobDetails/" + quoteId)}
              hideArrow={true}
              loading={
                loading ||
                loadingQuote ||
                loadingCustomer ||
                loadingOrderMilestones
                // ||
                // loadingQuoteRequestRejection
              }
              title="Back to main job"
              type="button"
            />
          ) : (
            <div className="flex flex-col items-center justify-center space-y-2 w-full md:px-0 px-6">
              <div className="flex flex-col items-center justify-center space-y-1 w-full overflow-y-auto ">
                {/* Add max height */}
                {orderMilestones &&
                  orderMilestones.map((orderMilestone, index) => (
                    <MilestoneDetailsDropdown
                      orderMilestone={orderMilestone}
                      index={index}
                      getNatural={getNatural}
                      getDecimal={getDecimal}
                      quoteId={quoteId}
                      allowEdit={true}
                      props={props}
                    />
                  ))}
              </div>
              {fetchedQuote && (
                <div
                  className={`flex items-center justify-between w-full focus:outline-none text-black md:text-white border-t-2 border-black md:border-white`}
                >
                  <p className="font-semibold text-xl truncate">Total</p>

                  <div className="flex flex-col items-end justify-end text-right leading-tight">
                    <p className="font-semibold text-xl">
                      CA$
                      {getNatural(
                        (fetchedQuote.finalNetAmount / 100).toFixed(2)
                      )}
                      .
                      {getDecimal(
                        (fetchedQuote.finalNetAmount / 100).toFixed(2)
                      )}
                    </p>
                    <p className="">
                      By {moment(fetchedQuote.deadline).format("D MMM, YYYY")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const FETCH_PROJECT_SUBPARTS_BY_CUSTOMER_QUERY = gql`
  query getProjectSubPartsByCustomerId(
    $customerId: String
    $searchTerm: String
    $parentProjectId: String!
  ) {
    getProjectSubPartsByCustomerId(
      customerId: $customerId
      searchTerm: $searchTerm
      parentProjectId: $parentProjectId
    ) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;

export const FETCH_PROJECT_BY_ID_QUERY = gql`
  query getProjectById($projectId: String!) {
    getProjectById(projectId: $projectId) {
      id
      customerId
      allProjectVersionIds
      parentProjectIds
      versionNumber
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      editorIds
      partnerServicesWithAccessIds
      viewerIds
      suggestedSubPartsClicked
      createdAt
      active
      isTopLevel
    }
  }
`;
export const FETCH_QUOTE_REQUEST_BY_ID_QUERY = gql`
  query getQuoteRequestById($quoteRequestId: String!) {
    getQuoteRequestById(quoteRequestId: $quoteRequestId) {
      id
      projectId
      message
      type
      unitsNeeded
      expiredAt
      quotedByIds
      rejectedByIds
      status
    }
  }
`;
export default JobDetails;
