import { gql, useQuery } from "@apollo/client";
import React from "react";
import DeleteOrderMilestoneMedia from "./DeleteOrderMilestoneMedia";

function EditMilestoneImages({ editOrderMilestoneId, disabled }) {
  const {
    data: { getOrderMilestoneById: fetchedOrderMilestone } = {},
    // loading,
  } = useQuery(FETCH_ORDER_MILESTONE_BY_ID_QUERY, {
    variables: { orderMilestoneId: editOrderMilestoneId },
  });

  console.log("yoyoy");
  if (fetchedOrderMilestone) {
    console.log(fetchedOrderMilestone);
  }

  return (
    <div className="flex items-center space-x-4 justify-start mt-2">
      {fetchedOrderMilestone &&
        fetchedOrderMilestone.images &&
        fetchedOrderMilestone.images.length > 0 &&
        fetchedOrderMilestone.images.map((imageUrl, index) => (
          <div
            className={`flex-shrink-0 relative w-36 h-28 rounded-lg overflow-hidden shadow-lg`}
            key={index}
          >
            <img
              className="object-cover w-full h-full"
              src={imageUrl}
              alt={"Picture " + index}
            />
            {!disabled && (
              <DeleteOrderMilestoneMedia
                url={imageUrl}
                orderMilestone={fetchedOrderMilestone}
                isAbsolute={true}
              />
            )}
          </div>
        ))}
    </div>
  );
}

export const FETCH_ORDER_MILESTONE_BY_ID_QUERY = gql`
  query getOrderMilestoneById($orderMilestoneId: String!) {
    getOrderMilestoneById(
      # customerId: $customerId
      orderMilestoneId: $orderMilestoneId
    ) {
      id
      quoteRequestId
      quoteId
      staffCustomerId
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      finalSelectedTopLvlInputFieldIds
      finalInputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      finalInputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      finalInputIdsToDates {
        inputFieldId
        dateVal
      }
      finalInputIdsToStrings {
        inputFieldId
        stringVal
      }
      finalInputIdsToInts {
        inputFieldId
        intVal
      }
      finalInputIdsToFloats {
        inputFieldId
        floatVal
      }
      finalInputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      finalInputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      finalInputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      responsiblePartnerServiceId
      doneByPartnerServiceId
      customerDiscountUsed
      finalSubTotal
      finalDiscount
      finalRefundAmount
      finalNetAmount
      startedWorkingAtDate
      deadline
      completedAtDate
      projectId
      customerId
      partnerStaffId
      allMilestoneVersionIds
      versionNumber
      notificationReceiverIds
      transferId
      currency
      status
      createdAt
      verificationSentAt
      paymentSuccessAt
      expiredAt
      refundedAt
    }
  }
`;
export default EditMilestoneImages;
