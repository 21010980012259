import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FETCH_PROJECT_BY_ID_QUERY } from "../../pages/customer/ProjectDetails";
import { FETCH_PROJECTS_BY_CUSTOMER_QUERY } from "../../pages/customer/Projects";
import InputField from "./InputField";
function DocumentArrayInputField({
  values,
  errors,
  setValues,
  topLvlInputFieldId,
  fetchedInputField,
  disabled,
}) {
  const [uploadArrayVal, setUploadArrayVal] = useState([]);
  const [urlArrayVal, setUrlArrayVal] = useState([]);

  const [checkedInitialVal, setCheckedInitialVal] = useState(false);

  useEffect(() => {
    if (!checkedInitialVal) {
      if (
        values &&
        values.inputIdsToUploadArrays &&
        values.inputIdsToUploadArrays.length > 0
      ) {
        for (let i = 0; i < values.inputIdsToUploadArrays.length; ++i) {
          if (
            values.inputIdsToUploadArrays[i].inputFieldId ===
            fetchedInputField.id
          ) {
            setUploadArrayVal(values.inputIdsToUploadArrays[i].uploadArrayVal);
            break;
          }
        }
      }

      if (
        values &&
        values.inputIdsToFileUrlArrays &&
        values.inputIdsToFileUrlArrays.length > 0
      ) {
        console.log("hihih");
        for (let i = 0; i < values.inputIdsToFileUrlArrays.length; ++i) {
          if (
            values.inputIdsToFileUrlArrays[i].inputFieldId ===
            fetchedInputField.id
          ) {
            setUrlArrayVal(values.inputIdsToFileUrlArrays[i].urlArrayVal);
            break;
          }
        }
      }
      setCheckedInitialVal(true);
    }
  }, [
    checkedInitialVal,
    setCheckedInitialVal,
    values,
    setUploadArrayVal,
    fetchedInputField,
    urlArrayVal,
    setUrlArrayVal,
  ]);

  const [deleteDocumentUrl, { loading }] = useMutation(DELETE_DOCUMENT_URL, {
    refetchQueries: values.editProjectId && [
      {
        query: FETCH_PROJECTS_BY_CUSTOMER_QUERY,
        variables: { searchTerm: "" },
      },
      {
        query: FETCH_PROJECT_BY_ID_QUERY,
        variables: { projectId: values.editProjectId },
      },
    ],

    update(_, { data: { deleteProjectMedia: projectData } }) {},
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      // setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  const deleteDocumentCallback = (index, isAlreadyUploaded) => {
    if (isAlreadyUploaded) {
      if (
        values.inputIdsToFileUrlArrays &&
        values.inputIdsToFileUrlArrays.length > 0
      ) {
        var newUrlArrayVal = [...urlArrayVal];
        newUrlArrayVal.splice(index, 1);
        setUrlArrayVal(newUrlArrayVal);

        deleteDocumentUrl({
          variables: {
            projectId: values.editProjectId,
            orderMilestoneId: values.editOrderMilestoneId,
            url: urlArrayVal[index],
          },
        });
      }
    } else {
      if (
        values.inputIdsToUploadArrays &&
        values.inputIdsToUploadArrays.length > 0
      ) {
        const copiedValues = {
          ...values,
        };

        for (let i = 0; i < copiedValues.inputIdsToUploadArrays.length; ++i) {
          if (
            copiedValues.inputIdsToUploadArrays[i].inputFieldId ===
            fetchedInputField.id
          ) {
            var copiedUploadArray = [...uploadArrayVal];
            copiedUploadArray.splice(index, 1);
            copiedValues.inputIdsToUploadArrays[i].uploadArrayVal.splice(
              index,
              1
            );

            setValues(copiedValues);
            setUploadArrayVal(copiedUploadArray);
            return;
          }
        }
      }
    }
  };

  const setDocumentsCallback = (document) => {
    console.log("entered set doucments");
    if (Array.isArray(document)) {
      if (
        values.inputIdsToUploadArrays &&
        values.inputIdsToUploadArrays.length > 0
      ) {
        for (let i = 0; i < values.inputIdsToUploadArrays.length; ++i) {
          if (
            values.inputIdsToUploadArrays[i].inputFieldId ===
            fetchedInputField.id
          ) {
            var copiedValues = { ...values };

            copiedValues.inputIdsToUploadArrays[i].uploadArrayVal = [
              ...document,
              ...copiedValues.inputIdsToUploadArrays[i].uploadArrayVal,
            ];
            setValues(copiedValues);
            setUploadArrayVal(
              copiedValues.inputIdsToUploadArrays[i].uploadArrayVal
            );
          }
        }
      } else {
        const copiedValues = {
          ...values,
          inputIdsToUploadArrays: [
            {
              inputFieldId: fetchedInputField.id,
              uploadArrayVal: document,
            },
          ],
        };
        setValues(copiedValues);
        setUploadArrayVal(document);
      }
    } else {
      if (
        values.inputIdsToUploadArrays &&
        values.inputIdsToUploadArrays.length > 0
      ) {
        for (let i = 0; i < values.inputIdsToUploadArrays.length; ++i) {
          if (
            values.inputIdsToUploadArrays[i].inputFieldId ===
            fetchedInputField.id
          ) {
            var copiedValues2 = { ...values };

            copiedValues2.inputIdsToUploadArrays[i].uploadArrayVal = [
              document,
              ...copiedValues2.inputIdsToUploadArrays[i].uploadArrayVal,
            ];
            setValues(copiedValues2);
            setUploadArrayVal(
              copiedValues2.inputIdsToUploadArrays[i].uploadArrayVal
            );
            break;
          }
        }
      } else {
        const copiedValues = {
          ...values,
          inputIdsToUploadArrays: [
            {
              inputFieldId: fetchedInputField.id,
              uploadArrayVal: [document],
            },
          ],
        };
        setValues(copiedValues);
        setUploadArrayVal([document]);
      }
    }
  };

  return (
    <InputField
      fieldTitle={
        disabled ? fetchedInputField.title : fetchedInputField.title + "*"
      }
      errorMsg={errors && errors[fetchedInputField.id]}
      hint={fetchedInputField.description}
      name={fetchedInputField.id}
      value={uploadArrayVal}
      type={fetchedInputField.inputBoxType}
      setDocumentsCallback={setDocumentsCallback}
      acceptedFileFormats={fetchedInputField.acceptedFileFormats}
      deleteDocumentCallback={deleteDocumentCallback}
      urlArrayVal={urlArrayVal}
      disabled={disabled}
      loading={loading}
    />
  );
}

const DELETE_DOCUMENT_URL = gql`
  mutation deleteDocumentUrl(
    $url: String!
    $projectId: String
    $orderMilestoneId: String
  ) {
    deleteDocumentUrl(
      url: $url
      projectId: $projectId
      orderMilestoneId: $orderMilestoneId
    )
  }
`;

export default DocumentArrayInputField;
