import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { FETCH_PARTNER_SERVICE_BY_ID_QUERY } from "./QuoteQuickDisplayBox";

export default function PartnerServiceMessageBox({
  message,
  partnerServiceId,
  staffCustomerId,
  props,
  isLast,
}) {
  const { data: { getPartnerServiceById: fetchedPartnerService } = {} } =
    useQuery(FETCH_PARTNER_SERVICE_BY_ID_QUERY, {
      variables: { partnerServiceId },
    });

  const [showDetails, setShowDetails] = useState(false);

  return (
    <div
      className={`flex items-center flex-col justify-center space-y-1 w-full ${
        isLast ? "mb-16 md:mb-0" : ""
      }`}
      type="button"
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setShowDetails(!showDetails);
        }}
        className="bg-white focus:outline-none focus:ring-1 rounded-xl w-full p-4 flex flex-col justify-center items-center space-y-1"
      >
        {fetchedPartnerService && (
          <p className="font-semibold text-2xl text-black text-left w-full">
            {fetchedPartnerService.businessName}
          </p>
        )}
        {showDetails && fetchedPartnerService && (
          <>
            <img
              src={fetchedPartnerService.images[0]}
              alt={fetchedPartnerService.businessName}
              className="w-full h-16 md:h-12 object-cover rounded-lg overflow-hidden"
            />
            <p className="w-full text-left">
              <span className="font-semibold">Location:</span>{" "}
              {fetchedPartnerService.city}, {fetchedPartnerService.country}
            </p>
            <p className="w-full text-left">
              <span className="font-semibold">Services provided:</span>{" "}
              {fetchedPartnerService.servicesProvided}
            </p>
          </>
        )}
      </button>
      <div className="flex items-baseline justify-between w-full px-2 pb-2 space-x-2">
        {/* <button
          className="uppercase text-blue-600 font-semibold text-sm md:text-base"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setShowDetails(!showDetails);
          }}
        >
          Expand
        </button> */}
        <p className="uppercase text-green-600 font-semibold text-sm md:text-base">
          Partner
        </p>

        <p className="text-xs">{moment(message.createdAt).format("h:mm a")}</p>
      </div>
    </div>
  );
}
