import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import CheckButton from "../../components/customer/CheckButton";
import { FETCH_PROJECT_BY_ID_QUERY } from "../../components/customer/DeleteProjectMedia";
import { FETCH_ORDER_MILESTONE_BY_ID_QUERY } from "../../components/customer/EditMilestoneImages";
import HomeButton from "../../components/customer/HomeButton";
import InputField from "../../components/customer/InputField";
import InputFieldsForSelectedTopLevelField from "../../components/customer/InputFieldsForSelectedTopLevelField";
import InputTitle from "../../components/customer/InputTitle";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "../../components/customer/OrderMilestoneMessageBox";
import PageHeader from "../../components/customer/PageHeader";
import ShareSoftwair from "../../components/customer/ShareSoftwair";
import SocialsFooter from "../../components/customer/SocialsFooter";
import { AuthContext } from "../../context/auth";
import { customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";
import { FETCH_SUGGEST_DETAILS_BY_DESC_QUERY } from "./CreateProject";
import { FETCH_CUSTOMER_QUERY } from "./Settings";

function UpdateOrderMilestone(props) {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Update Milestone | Softwair";
  }, []);
  const { customer, logout } = useContext(AuthContext);

  if (!customer) {
    props.history.push("/login");
  }
  const orderMilestoneId = props.match.params.orderMilestoneId;
  const {
    data: { getOrderMilestoneById: fetchedOrderMilestone } = {},
    loading,
  } = useQuery(FETCH_ORDER_MILESTONE_BY_ID_QUERY, {
    variables: { orderMilestoneId },
  });

  var projectId;
  if (fetchedOrderMilestone) {
    projectId = fetchedOrderMilestone.projectId;
  }

  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId },
    }
  );

  const [errors, setErrors] = useState({});

  const { onChange, values, setValues, onArrayChange } = useForm(
    completeOrderMilestoneCallback,
    {
      orderMilestoneId,
      selectedTopLvlInputFieldIds: [],
    }
  );

  const [
    completedWorkingOnOrderMilestone,
    { loading: loadingUpdateOrderMilestone },
  ] = useMutation(COMPLETE_WORKING_ON_ORDER_MILESTONE, {
    // refetchQueries: [
    //   {
    //     query: FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
    //     variables: { quoteRequestId: quoteRequest.id },
    //   },

    // ],
    refetchQueries: [
      {
        query: FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY,
        variables: {
          quoteId: fetchedOrderMilestone && fetchedOrderMilestone.quoteId,
        },
      },

      // {
      //   query: FETCH_CHAT_BY_ID_QUERY,
      //   variables: {
      //     quoteId: fetchedOrderMilestone && fetchedOrderMilestone.quoteId,
      //   },
      // },
    ],
    update(
      _,
      { data: { completedWorkingOnOrderMilestone: orderMilestoneData } }
    ) {
      // refetch projects

      props.history.push("/jobDetails/" + fetchedOrderMilestone.quoteId);
      setErrors({});
    },
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
  });

  function completeOrderMilestoneCallback() {
    completedWorkingOnOrderMilestone();
  }
  const {
    data: { getCustomer: fetchedCustomer } = {},
    // error,
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER_QUERY);

  if (!loadingCustomer && !fetchedCustomer) {
    logout();
    customerClient.cache.reset();
  }

  const {
    data: { getSuggestedInputFieldsByDesc: topLvlSuggestedDetails } = {},
    // error,
    loading: loadingSuggestedDetails,
  } = useQuery(FETCH_SUGGEST_DETAILS_BY_DESC_QUERY, {
    variables: {
      description:
        fetchedOrderMilestone &&
        fetchedProject &&
        fetchedOrderMilestone.title +
          " " +
          fetchedOrderMilestone.shortDescription +
          " " +
          fetchedProject.title +
          " " +
          fetchedProject.shortDescription,
      isTopLevel: true,
      selectedTopLvlInputFieldIds: values.selectedTopLvlInputFieldIds,
    },
  });
  const [prevTopLvlSuggestedDetails, setPrevTopLvlSuggestedDetails] = useState(
    []
  );

  useEffect(() => {
    if (
      topLvlSuggestedDetails &&
      topLvlSuggestedDetails !== prevTopLvlSuggestedDetails
    ) {
      setPrevTopLvlSuggestedDetails(topLvlSuggestedDetails);
    }
  }, [topLvlSuggestedDetails, prevTopLvlSuggestedDetails]);

  return (
    <div className="flex flex-col md:flex-row h-full w-full min-h-screen max-h-screen overflow-hidden leading-tight">
      <div className="hidden w-full md:w-2/3 h-full min-h-screen max-h-screen md:flex flex-col justify-between p-8">
        <HomeButton props={props} />
        <ShareSoftwair
          title="Share Softwair"
          loadingCustomer={loadingCustomer}
          customer={fetchedCustomer}
          showPartnerIncentive={fetchedCustomer && fetchedCustomer.isAPartner}
        />
        <SocialsFooter />
      </div>

      <div className="w-full bg-gradient-to-b from-transparent to-gray-100 md:bg-black md:to-black md:w-1/2 lg:w-1/3 h-full min-h-screen max-h-screen flex flex-col justify-between items-start md:p-6 overflow-hidden">
        <div className="px-6 pt-6 md:mb-2 md:p-0">
          <PageHeader
            props={props}
            titleBold={"Send update"}
            linkMsg={
              fetchedProject &&
              `For ${fetchedProject.title} v${fetchedProject.versionNumber}`
            }
            link={
              fetchedOrderMilestone &&
              fetchedProject &&
              `/quoteRequestDetails/${fetchedOrderMilestone.quoteRequestId}/${fetchedOrderMilestone.projectId}`
            }
            hideHomeButton={true}
            padTop={true}
          />
        </div>

        <div className="flex flex-col items-center justify-start w-full h-full mt-6 pb-6 px-6 md:p-0 md:mt-0 flex-1 overflow-y-auto space-y-4">
          {((topLvlSuggestedDetails && topLvlSuggestedDetails.length > 0) ||
            (prevTopLvlSuggestedDetails &&
              prevTopLvlSuggestedDetails.length > 0)) && (
            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <InputTitle
                fieldTitle={"Details (optional)"}
                errorMsg={errors && errors.selectedTopLvlInputFieldIds}
                hint={
                  "Add details by clicking one of the options below. Click again to remove that detail."
                }
              />

              <div className="flex w-full overflow-x-auto space-x-2 md:space-x-1 justify-start items-center">
                {topLvlSuggestedDetails &&
                  topLvlSuggestedDetails.length > 0 &&
                  topLvlSuggestedDetails.map((topLvlSuggestedDetail, index) => (
                    <InputField
                      name={"selectedTopLvlInputFieldIds"}
                      onChangeFunction={onArrayChange}
                      checkboxValue={topLvlSuggestedDetail.id}
                      type="checkbox"
                      value={values.selectedTopLvlInputFieldIds}
                      checkboxTitle={topLvlSuggestedDetail.title}
                      key={index}
                    />
                  ))}
                {(!topLvlSuggestedDetails ||
                  topLvlSuggestedDetails.length === 0) &&
                  prevTopLvlSuggestedDetails &&
                  prevTopLvlSuggestedDetails.length > 0 &&
                  prevTopLvlSuggestedDetails.map(
                    (prevTopLvlSuggestedDetail, index) => (
                      <InputField
                        name={"selectedTopLvlInputFieldIds"}
                        onChangeFunction={onArrayChange}
                        checkboxValue={prevTopLvlSuggestedDetail.id}
                        type="checkbox"
                        value={values.selectedTopLvlInputFieldIds}
                        checkboxTitle={prevTopLvlSuggestedDetail.title}
                        key={index}
                        disabled={true}
                      />
                    )
                  )}
              </div>
            </div>
          )}

          {values.selectedTopLvlInputFieldIds &&
            values.selectedTopLvlInputFieldIds.length > 0 &&
            values.selectedTopLvlInputFieldIds.map(
              (topLvlInputFieldId, index) => (
                <InputFieldsForSelectedTopLevelField
                  key={index}
                  onChange={onChange}
                  topLvlInputFieldId={topLvlInputFieldId}
                  errors={errors}
                  values={values}
                  setValues={setValues}
                />
              )
            )}
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6 mb-6 md:mb-0 w-full">
          <CheckButton
            loading={
              loading || loadingUpdateOrderMilestone || loadingSuggestedDetails
            }
            btnFunction={completeOrderMilestoneCallback}
            type="button"
          />
        </div>
      </div>
    </div>
  );
}

export const FETCH_SENT_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY = gql`
  query getSentStaffQuoteByQuoteRequest($quoteRequestId: String!) {
    getSentStaffQuoteByQuoteRequest(
      # customerId: $customerId
      quoteRequestId: $quoteRequestId
    ) {
      id
      quoteRequestId
      projectId
      createdAt
      versionNumber
      finalNetAmount
      deadline
      status
    }
  }
`;

export const COMPLETE_WORKING_ON_ORDER_MILESTONE = gql`
  mutation completedWorkingOnOrderMilestone(
    $orderMilestoneId: String!
    $selectedTopLvlInputFieldIds: [String]
    $inputIdsToUploads: [InputIdToUploadValInput]
    $inputIdsToDates: [InputIdToDateValInput]
    $inputIdsToStrings: [InputIdToStringValInput]
    $inputIdsToInts: [InputIdToIntValInput]
    $inputIdsToFloats: [InputIdToFloatValInput]
    $inputIdsToUploadArrays: [InputIdToUploadArrayValInput]
    $inputIdsToStringArrays: [InputIdToStringArrayValInput]
    $inputIdsToIntArrays: [InputIdToIntArrayValInput]
    $inputIdsToFloatArrays: [InputIdToFloatArrayValInput]
  ) {
    completedWorkingOnOrderMilestone(
      orderMilestoneId: $orderMilestoneId
      selectedTopLvlInputFieldIds: $selectedTopLvlInputFieldIds
      inputIdsToUploads: $inputIdsToUploads
      inputIdsToDates: $inputIdsToDates
      inputIdsToStrings: $inputIdsToStrings
      inputIdsToInts: $inputIdsToInts
      inputIdsToFloats: $inputIdsToFloats
      inputIdsToUploadArrays: $inputIdsToUploadArrays
      inputIdsToStringArrays: $inputIdsToStringArrays
      inputIdsToIntArrays: $inputIdsToIntArrays
      inputIdsToFloatArrays: $inputIdsToFloatArrays
    ) {
      id
    }
  }
`;

export const FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY = gql`
  query getOrderMilestonesByQuoteRequestId(
    $customerId: String
    $quoteRequestId: String!
  ) {
    getOrderMilestonesByQuoteRequestId(
      customerId: $customerId
      quoteRequestId: $quoteRequestId
    ) {
      id
      quoteRequestId
      quoteId
      staffCustomerId
      title
      shortDescription
      selectedTopLvlInputFieldIds
      inputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      inputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      inputIdsToDates {
        inputFieldId
        dateVal
      }
      inputIdsToStrings {
        inputFieldId
        stringVal
      }
      inputIdsToInts {
        inputFieldId
        intVal
      }
      inputIdsToFloats {
        inputFieldId
        floatVal
      }
      inputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      inputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      inputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      finalSelectedTopLvlInputFieldIds
      finalInputIdsToFileUrls {
        inputFieldId
        urlVal
      }
      finalInputIdsToFileUrlArrays {
        inputFieldId
        urlArrayVal
      }
      finalInputIdsToDates {
        inputFieldId
        dateVal
      }
      finalInputIdsToStrings {
        inputFieldId
        stringVal
      }
      finalInputIdsToInts {
        inputFieldId
        intVal
      }
      finalInputIdsToFloats {
        inputFieldId
        floatVal
      }
      finalInputIdsToStringArrays {
        inputFieldId
        stringArrayVal
      }
      finalInputIdsToIntArrays {
        inputFieldId
        intArrayVal
      }
      finalInputIdsToFloatArrays {
        inputFieldId
        floatArrayVal
      }
      responsiblePartnerServiceId
      doneByPartnerServiceId
      customerDiscountUsed
      finalSubTotal
      finalDiscount
      finalRefundAmount
      finalNetAmount
      startedWorkingAtDate
      deadline
      completedAtDate
      projectId
      customerId
      partnerStaffId
      allMilestoneVersionIds
      versionNumber
      notificationReceiverIds
      transferId
      currency
      status
      createdAt
      verificationSentAt
      paymentSuccessAt
      expiredAt
      refundedAt
    }
  }
`;

export default UpdateOrderMilestone;
