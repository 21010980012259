import { gql, useMutation } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "./OrderMilestoneMessageBox";

function MilestoneDetailsDropdown({
  index,
  orderMilestone,
  getNatural,
  getDecimal,
  expandMilestone,
  setValues,
  quoteId,
  values,
  allowEdit,
  props,
}) {
  const [isDescriptionBoxOpen, setDescriptionBox] = useState(false);
  // const [errors, setErrors] = useState({});
  // if (errors) {
  //   console.log(errors);
  // }
  const [
    startWorkingOnOrderMilestone,
    {
      loading: { loadingOrderMilestoneStart },
    },
  ] = useMutation(START_ORDER_MILESTONE_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY,
        variables: { quoteId },
      },
    ],

    update(_, { data: { submitQuote: quoteMessageData } }) {},
    onError(err) {
      console.log(err);
      console.log(err.graphQLErrors[0].extensions.exception.errors);
      // setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: { orderMilestoneId: orderMilestone.id },
  });

  const openDetails = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (expandMilestone || !values) {
      setDescriptionBox(!isDescriptionBoxOpen);
    } else if (
      values &&
      values.editOrderMilestoneId &&
      values.editOrderMilestoneId === orderMilestone.id
    ) {
      setValues({
        ...values,
        editOrderMilestoneId: null,
        quoteId: quoteId, //

        title: "", //
        shortDescription: "", //
        finalSubTotal: 2000, //
        deadline: "", //

        selectedTopLvlInputFieldIds: [],
      });
    } else if (values) {
      setValues({
        editOrderMilestoneId: orderMilestone.id,
        quoteId,
        title: orderMilestone.title, //
        shortDescription: orderMilestone.shortDescription, //
        responsiblePartnerServiceId: orderMilestone.responsiblePartnerServiceId, //
        doneByPartnerServiceId: orderMilestone.doneByPartnerServiceId, //
        partnerStaffId: orderMilestone.partnerStaffId, //
        finalSubTotal: orderMilestone.finalSubTotal, //
        deadline: moment(orderMilestone.deadline).format("D MMM, YYYY"), //

        selectedTopLvlInputFieldIds: orderMilestone.selectedTopLvlInputFieldIds,
        inputIdsToFileUrls: orderMilestone.inputIdsToFileUrls,
        inputIdsToDates: orderMilestone.inputIdsToDates,
        inputIdsToStrings: orderMilestone.inputIdsToStrings,
        inputIdsToInts: orderMilestone.inputIdsToInts,
        inputIdsToFloats: orderMilestone.inputIdsToFloats,
        inputIdsToFileUrlArrays: orderMilestone.inputIdsToFileUrlArrays,
        inputIdsToStringArrays: orderMilestone.inputIdsToStringArrays,
        inputIdsToIntArrays: orderMilestone.inputIdsToIntArrays,
        inputIdsToFloatArrays: orderMilestone.inputIdsToFloatArrays,
      });
    }
  };

  return (
    <div
      className="w-full flex flex-col items-center justify-center flex-shrink-0"
      key={index}
    >
      <button
        className={`flex items-center justify-between w-full focus:outline-none space-x-4 ${
          values &&
          values.editOrderMilestoneId &&
          values.editOrderMilestoneId === orderMilestone.id
            ? "hover:text-red-500 text-blue-400 focus:text-blue-400"
            : "text-gray-400 hover:text-blue-300 focus:text-gray-400"
        }`}
        onClick={openDetails}
      >
        <p className="font-semibold truncate">{orderMilestone.title}</p>

        <div className="flex flex-col items-end justify-end text-right flex-shrink-0">
          {allowEdit ? (
            orderMilestone.status === 0 ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  startWorkingOnOrderMilestone();
                }}
                disabled={loadingOrderMilestoneStart}
                className="rounded-full bg-blue-600 md:bg-transparent text-white px-2 py-1 md:text-white shadow-sm md:border-2 uppercase font-semibold text-xs focus:outline-none focus:ring-1 hover:bg-blue-700 md:hover:bg-blue-600"
              >
                {loadingOrderMilestoneStart ? (
                  <svg
                    className="h-3 animate-spin"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                      stroke="white"
                      strokeWidth="2"
                    />
                    <path
                      d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </svg>
                ) : (
                  <p>Start</p>
                )}
              </button>
            ) : orderMilestone.status === 1 ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.history.push(
                    "/updateOrderMilestone/" + orderMilestone.id
                  );
                }}
                className="rounded-full bg-green-600 md:bg-transparent text-white px-2 py-1 md:text-white shadow-sm md:border-2 uppercase font-semibold text-xs focus:outline-none focus:ring-1 hover:bg-green-700 md:hover:bg-green-600"
              >
                SEND AN UPDATE
              </button>
            ) : orderMilestone.status === 2 ? (
              <p className="text-blue-700 md:text-blue-200 uppercase font-semibold text-xs">
                In review
              </p>
            ) : orderMilestone.status === 3 ? (
              <p className="text-green-700 md:text-green-200 uppercase font-semibold text-xs">
                Paid
              </p>
            ) : orderMilestone.status === 4 ? (
              <p className="text-blue-700 md:text-blue-200 uppercase font-semibold text-xs">
                Refunded
              </p>
            ) : (
              <p className="text-red-700 md:text-red-200 uppercase font-semibold text-xs">
                Cancelled
              </p>
            )
          ) : (
            <p className="font-semibold">
              CA$
              {getNatural((orderMilestone.finalNetAmount / 100).toFixed(2))}.
              {getDecimal((orderMilestone.finalNetAmount / 100).toFixed(2))}
            </p>
          )}
        </div>
      </button>
      {(expandMilestone || !values) && isDescriptionBoxOpen && (
        <button
          onClick={openDetails}
          className="focus:outline-none focus:ring text-left w-full bg-blue-600 hover:bg-blue-700 text-white rounded-lg p-2 mt-2"
        >
          <p>
            <span className="font-semibold">Description:</span>{" "}
            {orderMilestone.shortDescription}
          </p>
          <p>
            <span className="font-semibold">Deadline:</span>{" "}
            {moment(orderMilestone.deadline).format("D MMM, YYYY")}
          </p>
          {allowEdit && (
            <p>
              <span className="font-semibold">Amount:</span> CA$
              {getNatural((orderMilestone.finalNetAmount / 100).toFixed(2))}.
              {getDecimal((orderMilestone.finalNetAmount / 100).toFixed(2))}
            </p>
          )}
        </button>
      )}
    </div>
  );
}

const START_ORDER_MILESTONE_MUTATION = gql`
  mutation startWorkingOnOrderMilestone($orderMilestoneId: String!) {
    startWorkingOnOrderMilestone(orderMilestoneId: $orderMilestoneId) {
      id
    }
  }
`;

export default MilestoneDetailsDropdown;
