import React, { useState } from "react";

export default function InputTitle({
  fieldTitle,
  errorMsg,
  clearBtnText,
  value,
  additionalBtnText,
  hint,
  blueBack,
  additionalBtnFunction,
  clearTextFunction,
  whiteBack,
}) {
  const [showHint, setShowHint] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between w-full space-x-2">
        <h5
          className={`${
            blueBack
              ? "text-white"
              : whiteBack
              ? "text-gray-500"
              : "text-gray-500 md:text-gray-300"
          } flex-1`}
        >
          {fieldTitle}
        </h5>
        {errorMsg && (
          <h5 className="text-red-500 font-medium text-right">{errorMsg}</h5>
        )}
        {clearBtnText && value && (
          <button
            onClick={clearTextFunction}
            className="text-red-500 font-medium text-right"
          >
            {clearBtnText}
          </button>
        )}

        {additionalBtnText && (
          <button
            onClick={additionalBtnFunction}
            className="text-blue-500 font-medium text-right focus:outline-none focus:text-blue-300"
          >
            {additionalBtnText}
          </button>
        )}

        {hint && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setShowHint(!showHint);
            }}
            className="md:text-gray-400 md:hover:text-gray-500 text-gray-400 hover:text-gray-400 focus:outline-none focus:ring-1 rounded-full"
          >
            <svg
              className="fill-current"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 0C5.6 0 0 5.6 0 12.5C0 19.4 5.6 25 12.5 25C19.4 25 25 19.4 25 12.5C25 5.6 19.4 0 12.5 0ZM11.25 21.25V18.75H13.75V21.25H11.25ZM15.2125 12.7125L16.3375 11.5625C17.05 10.85 17.5 9.85 17.5 8.75C17.5 5.9875 15.2625 3.75 12.5 3.75C9.7375 3.75 7.5 5.9875 7.5 8.75H10C10 7.375 11.125 6.25 12.5 6.25C13.875 6.25 15 7.375 15 8.75C15 9.4375 14.725 10.0625 14.2625 10.5125L12.7125 12.0875C11.8125 13 11.25 14.25 11.25 15.625V16.25H13.75C13.75 14.375 14.3125 13.625 15.2125 12.7125Z"
              />
            </svg>
          </button>
        )}
      </div>

      {hint && showHint && (
        <button
          className="rounded-lg p-2 shadow text-white bg-blue-700 hover:bg-blue-600 text-left mt-1 w-full focus:outline-none focus:ring-1"
          onClick={(e) => {
            setShowHint(!showHint);
          }}
        >
          <p className="w-full">{hint}</p>
        </button>
      )}
    </>
  );
}
