import React from "react";
// import { LastLocationProvider } from "react-router-last-location";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import AcceptQuote from "./pages/customer/AcceptQuote";
import Account from "./pages/customer/Account";
import Chat from "./pages/customer/Chat";
import CreateProject from "./pages/customer/CreateProject";
import EditProject from "./pages/customer/EditProject";
import Explore from "./pages/customer/Explore";
import FindJobs from "./pages/customer/FindJobs";
import GetQuotes from "./pages/customer/GetQuotes";
import JobDetails from "./pages/customer/JobDetails";
import Jobs from "./pages/customer/Jobs";
import Landing from "./pages/customer/Landing";
import LoginCustomer from "./pages/customer/LoginCustomer";
import Messages from "./pages/customer/Messages";
import MilestoneDetails from "./pages/customer/MilestoneDetails";
import PartnerServiceRegistered from "./pages/customer/PartnerServiceRegistered";
import ProjectDetails from "./pages/customer/ProjectDetails";
import Projects from "./pages/customer/Projects";
import QuoteRequestDetails from "./pages/customer/QuoteRequestDetails";
import RegisterCustomer from "./pages/customer/RegisterCustomer";
import RegisterPartnerService from "./pages/customer/RegisterPartnerService";
import Settings from "./pages/customer/Settings";
import TrackProject from "./pages/customer/TrackProject";
import UpdateOrderMilestone from "./pages/customer/UpdateOrderMilestone";
import WriteQuote from "./pages/customer/WriteQuote";
import AuthRoute from "./util/AuthRoute";

function App() {
  return (
    <>
      <AuthProvider>
        <Router>
          <Route exact path="/" component={Landing} />
          <Route exact path="/explore" component={Explore} />
          <Route
            exact
            path="/registerPartner"
            component={RegisterPartnerService}
          />
          <Route
            exact
            path="/partnerServiceRegistered/:partnerServiceId"
            component={PartnerServiceRegistered}
          />

          <Route exact path="/projects" component={Projects} />
          <Route exact path="/jobs" component={Jobs} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/settings" component={Settings} />

          <Route
            exact
            path="/createProject/:parentProjectId?"
            component={CreateProject}
          />
          <Route
            exact
            path="/projectDetails/:projectId"
            component={ProjectDetails}
          />
          <Route
            exact
            path="/quoteRequestDetails/:quoteRequestId/:projectId"
            component={QuoteRequestDetails}
          />
          <Route
            exact
            path="/jobDetails/:quoteId/:projectId?"
            component={JobDetails}
          />
          <Route
            exact
            path="/writeQuote/:quoteRequestId"
            component={WriteQuote}
          />
          <Route
            exact
            path="/updateOrderMilestone/:orderMilestoneId"
            component={UpdateOrderMilestone}
          />
          <Route exact path="/acceptQuote/:quoteId" component={AcceptQuote} />
          {/* <Route exact path="/editQuote/:quoteId" component={EditQuote} /> */}
          <Route exact path="/editProject/:projectId" component={EditProject} />
          <Route
            exact
            path="/trackProject/:projectId/:quoteId?"
            component={TrackProject}
          />
          <Route
            exact
            path="/milestoneDetails/:orderMilestoneId"
            component={MilestoneDetails}
          />
          <Route exact path="/addJobs" component={FindJobs} />
          <Route exact path="/messages" component={Messages} />

          <Route exact path="/chat/:chatId/:messageId?" component={Chat} />

          <Route exact path="/getQuotes/:projectId" component={GetQuotes} />
          {/* <Route
            exact
            path="/quoteRequestReceived/:quoteRequestId"
            component={QuoteRequestReceived}
          /> */}

          <AuthRoute
            exact
            path="/signup/:referralCode?"
            component={RegisterCustomer}
          />
          <AuthRoute exact path="/login" component={LoginCustomer} />
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
