import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY } from "../../pages/customer/FindJobs";
import { FETCH_QUOTE_REQUEST_BY_ID_QUERY } from "../../pages/customer/JobDetails";
import {
  FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
  FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
} from "../../pages/customer/Jobs";
import {
  FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
  FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
} from "../../pages/customer/WriteQuote";
import { FETCH_PROJECT_BY_ID_QUERY } from "./DeleteProjectMedia";
import { CREATE_QUOTE_MUTATION } from "./JobRequestBox";
import MilestoneDetailsDropdown from "./MilestoneDetailsDropdown";
import { FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY } from "./OrderMilestoneMessageBox";

function QuoteMessageBox({
  quoteId,
  isLast,
  isFirst,
  message,
  staffCustomerId,
  props,
}) {
  // const [errors, setErrors] = useState({});
  // const [isDescriptionBoxOpen, setDescriptionBox] = useState(false);
  const { data: { getQuoteById: fetchedQuote } = {}, refetch } = useQuery(
    FETCH_QUOTE_BY_ID_QUERY,
    {
      variables: { quoteId },
    }
  );
  useEffect(() => {
    refetch();
  }, [refetch]);

  const {
    data: { getOrderMilestonesByQuote: orderMilestones } = {},
    // loading: { loadingOrderMilestones },
  } = useQuery(FETCH_ORDER_MILESTONES_BY_QUOTE_QUERY, {
    variables: { quoteId },
  });

  function getNatural(num) {
    return num
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getDecimal(num) {
    return num.toString().split(".")[1];
  }

  var projectId = "";
  var quoteRequestId = "";
  if (fetchedQuote) {
    projectId = fetchedQuote.projectId;
    quoteRequestId = fetchedQuote.quoteRequestId;
  }

  const { data: { getProjectById: fetchedProject } = {} } = useQuery(
    FETCH_PROJECT_BY_ID_QUERY,
    {
      variables: { projectId: projectId },
    }
  );

  const [createQuote, { loading: loadingQuote }] = useMutation(
    CREATE_QUOTE_MUTATION,
    {
      refetchQueries: [
        {
          query: FETCH_IN_PROGRESS_JOB_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUESTS_BY_STAFF_QUERY,
        },
        {
          query: FETCH_PENDING_QUOTES_BY_CUSTOMER_QUERY,
          variables: { searchTerm: "" },
        },
        {
          query: FETCH_QUOTE_REQUEST_BY_ID_QUERY,
          variables: { quoteRequestId },
        },
        {
          query: FETCH_PENDING_STAFF_QUOTE_BY_QUOTE_REQUEST_QUERY,
          variables: { quoteRequestId },
        },
        {
          query: FETCH_ORDER_MILESTONES_BY_QUOTE_REQUEST_ID_QUERY,
          variables: { quoteRequestId },
        },
      ],

      update(_, { data: { createQuote: quoteData } }) {
        props.history.push("/writeQuote/" + fetchedQuote.quoteRequestId);
      },
      onError(err) {
        console.log(err);
        console.log(err.graphQLErrors[0].extensions.exception.errors);
        // setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
      variables: { quoteRequestId },
    }
  );
  // if (errors) {
  //   console.log(errors);
  // }
  const [showDetails, setShowDetails] = useState(false);
  // console.log(message.status);

  var mainImageUrl = "";
  if (fetchedProject) {
    for (let i = 0; i < fetchedProject.inputIdsToFileUrlArrays.length; ++i) {
      if (
        fetchedProject.inputIdsToFileUrlArrays[i].inputFieldId === "V8LHJ3AWA8"
      )
        //Images{
        mainImageUrl = fetchedProject.inputIdsToFileUrlArrays[i].urlArrayVal[0];
    }
  }

  return (
    <div
      className={`flex items-center flex-col justify-center space-y-1 w-full ${
        isLast ? "mb-16 md:mb-0" : ""
      }`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setShowDetails(!showDetails);
        }}
        type="button"
        className="bg-white rounded-xl w-full p-4 flex flex-col justify-center items-center space-y-2 focus:outline-none focus:ring-1"
      >
        {fetchedProject && fetchedQuote && (
          <div className="flex flex-col md:flex-row items-center justify-center md:justify-between space-y-2 md:space-y-0 md:space-x-4 w-full">
            <div className="flex items-start justify-start flex-col flex-shrink-0 w-full  md:w-auto text-left">
              {/* <p>{fetchedQuote.id}</p> */}
              <p className="font-light text-gray-500 w-full leading-snug">
                {fetchedProject.title} v{fetchedProject.versionNumber}
              </p>
              <p className="text-2xl font-semibold w-full leading-snug">
                Quote v{fetchedQuote.versionNumber}
              </p>
            </div>
            {fetchedProject.images && fetchedProject.images.length > 0 && (
              <img
                src={mainImageUrl}
                alt={fetchedProject.title}
                className="w-full h-16 md:h-12 object-cover rounded-lg overflow-hidden"
              />
            )}
          </div>
        )}
        {showDetails && (
          <>
            <div className="w-full flex flex-col items-center justify-center">
              {orderMilestones &&
                orderMilestones.length > 0 &&
                orderMilestones
                  .filter((orderMilestone) => {
                    return orderMilestone.status <= 3;
                  })
                  .map((orderMilestone, index) => (
                    <MilestoneDetailsDropdown
                      key={index}
                      getDecimal={getDecimal}
                      getNatural={getNatural}
                      orderMilestone={orderMilestone}
                    />
                  ))}
            </div>
            {fetchedQuote && (
              <div
                className={`flex items-center justify-between w-full focus:outline-none text-black border-t-2 border-black`}
              >
                <p className="font-semibold truncate">Total</p>

                <div className="flex flex-col items-end justify-end text-right leading-tight">
                  <p className="font-semibold">
                    CA$
                    {getNatural((fetchedQuote.finalNetAmount / 100).toFixed(2))}
                    .
                    {getDecimal((fetchedQuote.finalNetAmount / 100).toFixed(2))}
                  </p>
                  <p className="">
                    By {moment(fetchedQuote.deadline).format("D MMM, YYYY")}
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </button>

      {fetchedQuote && (
        <div className="flex items-baseline justify-between w-full px-2 pb-2 space-x-2">
          {loadingQuote ? (
            <svg
              className="h-4 animate-spin"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 15C1.5 12.6303 2.12378 10.3023 3.30866 8.25C4.49353 6.19774 6.19774 4.49353 8.25 3.30866C10.3023 2.12378 12.6303 1.5 15 1.5C17.3697 1.5 19.6977 2.12378 21.75 3.30866"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M28.5 15C28.5 17.3697 27.8762 19.6977 26.6913 21.75C25.5065 23.8023 23.8023 25.5065 21.75 26.6913C19.6977 27.8762 17.3697 28.5 15 28.5C12.6303 28.5 10.3023 27.8762 8.25 26.6913"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          ) : message.status === 1 &&
            fetchedQuote.status === 1 &&
            staffCustomerId === fetchedQuote.staffCustomerId ? (
            <button
              className="uppercase text-green-600 font-semibold text-sm md:text-base"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                createQuote();
              }}
            >
              Edit quote
            </button>
          ) : message.status === 1 && fetchedQuote.status === 1 ? (
            <button
              className="uppercase text-blue-600 font-semibold text-sm md:text-base"
              type="button"
              onClick={(e) => {
                e.preventDefault();

                props.history.push("/acceptQuote/" + quoteId);
              }}
            >
              Review &amp; Accept
              {/* & Pay CA$
              {getNatural((fetchedQuote.finalNetAmount / 100).toFixed(2))}.
              {getDecimal((fetchedQuote.finalNetAmount / 100).toFixed(2))} */}
            </button>
          ) : message.status === 1 &&
            staffCustomerId === fetchedQuote.staffCustomerId ? (
            <p className="uppercase text-blue-600 font-semibold text-sm md:text-base">
              Offered
            </p>
          ) : message.status === 1 ? (
            <p className="uppercase text-blue-600 font-semibold text-sm md:text-base">
              Viewed
            </p>
          ) : fetchedQuote.status === 2 ? (
            <p className="uppercase text-blue-600 font-semibold text-sm md:text-base">
              Payment started
            </p>
          ) : fetchedQuote.status === 3 ? (
            <div className="uppercase text-green-600 font-semibold text-sm md:text-base">
              Committed CA$
              {getNatural((fetchedQuote.finalNetAmount / 100).toFixed(2))}.
              {getDecimal((fetchedQuote.finalNetAmount / 100).toFixed(2))}
            </div>
          ) : fetchedQuote.status === 4 ? (
            <p className="uppercase text-green-600 font-semibold text-sm md:text-base">
              Completed
            </p>
          ) : fetchedQuote.status === 5 ? (
            <p className="uppercase text-green-600 font-semibold text-sm md:text-base">
              Refunded CA$
              {getNatural((fetchedQuote.finalRefundAmount / 100).toFixed(2))}.
              {getDecimal((fetchedQuote.finalRefundAmount / 100).toFixed(2))}
            </p>
          ) : (
            <p className="uppercase text-red-600 font-semibold text-sm md:text-base">
              Cancelled
            </p>
          )}

          <p className="text-xs">
            {moment(message.createdAt).format("h:mm a")}
          </p>
        </div>
      )}
    </div>
  );
}

export const FETCH_QUOTE_BY_ID_QUERY = gql`
  query getQuoteById($quoteId: String!) {
    getQuoteById(quoteId: $quoteId) {
      id
      type
      projectId
      customerId
      quoteRequestId
      partnerServiceId
      partnerStaffId
      staffCustomerId
      allQuoteVersionIds
      versionNumber
      notificationReceiverIds
      customerDiscountUsed
      finalSubTotal
      finalDiscount
      finalRefundAmount
      finalNetAmount
      releasedSoFar
      leftToRelease
      currency
      paymentIntentId
      paymentIntentClientSecret
      status
      deadline
      createdAt
      verificationSentAt
      paymentSuccessAt
      quoteSentAt
      expiredAt
      refundedAt
    }
  }
`;

export default QuoteMessageBox;
